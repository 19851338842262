import * as APIUtil from "../../actions"
const moment = require("moment")

export const fetchLiveVideosJetsweat = (user) => async (dispatch) => {
  try {
    let response = await fetch("/api/liveclass/upcoming", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: user.authToken,
      },
    })
    let data = await response.json()
    data = data.content
    const action = APIUtil.getLiveJetsweat(data)
    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}
export const fetchLiveContentId = (user, id) => async (dispatch) => {
  try {
    let response = await fetch(`/api/livestream/channel/channelId/${id}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: user.authToken,
      },
    })
    let data = await response.json()
    data = data.content
    const action = APIUtil.getContentId(data)
    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}

export const scheduleLiveClass = (user, id, time) => async (dispatch) => {
  try {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    const dateObj = new Date(time)
    const date = moment(
      `${
        months[dateObj.getMonth()]
      }-${dateObj.getDate()}-${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}`,
      "MMM-DD-YYYY HH:mm"
    )
    let response = await fetch("/api/workout/add", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.authToken,
      },
      body: JSON.stringify({
        authUserInfo: user,
        videoId: null,
        liveClassId: id,
        status: "scheduled",
        when: date.format("MM-DD-YYYY HH:mm"),
      }),
    })
    let data = await response.json()
    data = data.content
    const action = APIUtil.getContentId(data)
    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}

export default function liveReducer(state = {}, action) {
  switch (action.type) {
    case "GET_ALL_LIVE_VIDEOS_JETSWEAT":
      return {
        ...state,
        videos: action.liveVideos,
      }
    case "GET_LIVE_CONTENT_ID":
      return {
        ...state,
        contentId: action.id.channelId,
      }
    case "RECEIVE_SCHEDULED_LIVE":
      return {
        ...state,
        liveClasses: action.liveClasses,
      }
    default:
      return state
  }
}
