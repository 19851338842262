import React, { Component } from "react"
import { Link, Redirect } from "react-router-dom"
import "../payment.css"
import Footer from "../footer"

import CheckoutForm from "./WhiteLabelCheckout"

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

class WhiteLabelPayment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      subscription: "",
      firstName: "",
      lastName: "",
      promo: "",
      stripeApiKey: "",
      monthlyPrice: {},
      yearlyPrice: {},
    }
    this.handleChange = this.handleChange.bind(this)
    this.redirectToAccount = this.redirectToAccount.bind(this)
  }

  componentDidMount() {
    fetch("/api/basic/stripe", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.status >= 400) {
          console.log("error!")
        } else {
          return res.json()
        }
      })
      .then((rslt) => {
        this.setState({
          stripeApiKey: (rslt.content || {}).publishable || "",
        })

        return rslt
      })

    fetch(`/api/studio/packages/${this.props.studio.shortName}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        return res.json()
      })
      .then((result) => {
        this.setState({
          monthlyPrice: result.content.monthly,
          annualPrice: result.content.annual,
        })
      })
  }

  handleChange(event) {
    const fieldName = event.target.name
    this.setState({ [fieldName]: event.target.value })
  }

  redirectToAccount() {
    return this.props.refreshLoggedInUser().then(() => {
      let route = `/account/${this.props.studio.shortName}`
      if (this.props.whiteLabelDomain) {
        route = "/account"
      }
      this.props.history.push(route)
    })
  }

  render() {
    console.log(this.props.currUser)
    let selectedBoxStyle = {}

    if (this.props.isWhiteLabelDomain) {
      selectedBoxStyle = {
        borderColor: `#${this.props.studio.colorCode}`,
      }
    }

    let stripePromise = null
    let { whiteLabelFontStyles, whiteLabelBackgroundStyles } = this.props
    if (!this.props.isLoggedIn) {
      return <Redirect to="/" />
    }

    if (this.state.stripeApiKey !== "") {
      stripePromise = loadStripe(this.state.stripeApiKey)
    }
    return (
      <div className="payment-container">
        <div className="Background-color-nav" />
        <div className="Center">
          <p style={whiteLabelFontStyles} className="Main-text">
            {" "}
            Billing Information{" "}
          </p>
          <p style={whiteLabelFontStyles} className="Main-text">
            {" "}
            Choose Your Subscription:
          </p>
        </div>

        <div className="clearfix Whitelabel-plans" style={{ width: "100%" }}>
          <div className="Responsive-plan">
            <section
              className="Gallery"
              onClick={() => {
                this.props.setSubscription("yearly")
              }}
            >
              <fieldset
                style={
                  this.props.subscription === "yearly" ? selectedBoxStyle : {}
                }
                className={`Monthly-annualbox ${
                  this.props.subscription === "yearly" ? "Active" : ""
                }`}
              >
                <legend className="Price-font">Annual Plan</legend>
                <div className="Payment-yearly">
                  <p>
                    <span className="Price-font">
                      ${this.state.annualPrice?.price}/year
                    </span>
                    <br />
                    billed annually
                    <br />
                    <span className="Price-font">Unlimited Workouts</span>
                    <br />
                    <br />
                    <span className="Price-font">
                      First {this.state.monthlyPrice?.trial} Days Free
                    </span>
                    <br />
                    Cancel at any time
                  </p>
                </div>
              </fieldset>
            </section>
          </div>
          <div className="Responsive-plan">
            <section
              className="Gallery"
              onClick={() => {
                this.props.setSubscription("monthly")
              }}
            >
              <fieldset
                style={
                  this.props.subscription !== "yearly" ? selectedBoxStyle : {}
                }
                className={`Monthly-annualbox ${
                  this.props.subscription !== "yearly" ? "Active" : ""
                }`}
              >
                <legend className="Price-font">Monthly Plan</legend>
                <div className="Payment-monthly">
                  <p>
                    <span className="Price-font">
                      ${this.state.monthlyPrice?.price}/month
                    </span>
                    <br />
                    billed monthly
                    <br />
                    <span className="Price-font">Unlimited Workouts</span>
                    <br />
                    <br />
                    <span className="Price-font">
                      First {this.state.monthlyPrice?.trial} Days Free
                    </span>
                    <br />
                    Cancel at any time
                  </p>
                </div>
              </fieldset>
            </section>
          </div>
        </div>
        {stripePromise && (
          <Elements stripe={stripePromise} s>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <CheckoutForm
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                  monthlyPrice={this.state.monthlyPrice}
                  yearlyPrice={this.state.yearlyPrice}
                  apiKey={this.state.stripeApiKey}
                  studio={this.props.studio.shortName}
                  redirectToAccount={this.redirectToAccount}
                  subscription={this.props.subscription}
                  authToken={this.props.currUser.authToken}
                  studioId={this.props.currUser.studioId}
                  user={this.props.currUser}
                  firstName={this.props.currUser.firstName}
                  lastName={this.props.currUser.firstName}
                  stripe={stripe}
                  elements={elements}
                />
              )}
            </ElementsConsumer>
          </Elements>
        )}
        <div className="Payment-description">
          <p>
            Your subscription includes unlimited access to all classes. Promo
            codes cannot be combined with standard 7-day trial offer. Your
            payment will be charged to your specified card at the expiration of
            any free trial period unless cancelled at least 24 hours in advance.
            The subscription automatically renews unless cancelled at least 24
            hours before the end of the current period. You can cancel your
            subscription at anytime by emailing support@jetsweatfitness.com{" "}
            <br />
            <br />
            By signing up, you agree to the{" "}
            <Link to="terms">Terms of Service</Link> and{" "}
            <Link to="privacypolicy">Privacy Policy.</Link>
          </p>
        </div>
        <div className="footer-payment">
          <Footer studio={this.props.studio} />
        </div>
      </div>
    )
  }
}

export default WhiteLabelPayment
