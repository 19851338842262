import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import './cardsection.css';

const CardSection = () => (
  <div className="Center">
    <div>
      <label className="center-thetext margin-top containerwidth">
        Credit or Debit Card
        <CardNumberElement
          className="margin input-card containerwidth"
          style={{
            base: {
              iconColor: '#9ac8b9',
              padding: '10px',
              opacity: '1',
              fontFamily: 'coco-gothic-regular, sans-serif',
              fontSize: '20px',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': { color: '#9ac8b9' },
              '::placeholder': { color: '#808080' },
              invalid: {
                conColor: '#FFC7EE',
                color: '#FFC7EE',
              },
            },
          }}
        />
      </label>
    </div>
    <div>
      <label className="center-thetext"> Expiration Date</label>
      <CardExpiryElement
        className="margin input-card input-width"
        style={{
          base: {
            iconColor: '#9ac8b9',
            padding: '10px',
            opacity: '1',
            fontFamily: 'coco-gothic-regular, sans-serif',
            fontSize: '20px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#9ac8b9' },
            '::placeholder': { color: '#808080' },
            invalid: {
              conColor: '#FFC7EE',
              color: '#FFC7EE',
            },
          },
        }}
      />
    </div>
    <label className="center-thetext"> CVC </label>
    <CardCvcElement
      className="margin  input-card input-width"
      style={{
        base: {
          iconColor: '#9ac8b9',
          padding: '10px',
          opacity: '1',
          fontFamily: 'coco-gothic-regular, sans-serif',
          fontSize: '20px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': { color: '#9ac8b9' },
          '::placeholder': { color: '#808080' },
          invalid: {
            conColor: '#FFC7EE',
            color: '#FFC7EE',
          },
        },
      }}
    />
    {/*<label className="center-thetext">Postal Code</label>*/}
    {/*<input*/}
      {/*className="margin input-card input-width"*/}
      {/*style={{*/}
        {/*base: {*/}
          {/*iconColor: '#9ac8b9',*/}
          {/*padding: '10px',*/}
          {/*opacity: '1',*/}
          {/*fontFamily: 'coco-gothic-regular, sans-serif',*/}
          {/*fontSize: '20px',*/}
          {/*fontSmoothing: 'antialiased',*/}
          {/*':-webkit-autofill': { color: '#9ac8b9' },*/}
          {/*'::placeholder': { color: '#808080' },*/}
          {/*invalid: {*/}
            {/*conColor: '#FFC7EE',*/}
            {/*color: '#FFC7EE',*/}
          {/*},*/}
        {/*},*/}
      {/*}}*/}
    {/*/>*/}
  </div>
);


export default CardSection;
