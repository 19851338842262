import React, { Component } from "react";
import Image1 from "./assets/image1.JPG";
import Image2 from "./assets/image2.jpg";
import Image3 from "./assets/image3.jpg";
import Image4 from "./assets/image4.jpg";
import Image5 from "./assets/image5.JPG";
import Image6 from "./assets/image6.png";
import Dots from "screens/home/components/dots";
import "./social.css";

export default class Social extends Component {
  render() {
    return (
      <div className="js-home-social-container d-flex flex-column align-items-center">
        <div className="js-text-align-center js-home-social-header">
          <div className="js-raleway js-font-40 js-font-30-mb js-font-semibold js-font-black">
            Let's get QuaranToned!
          </div>
          <div className="js-raleway js-font-40 js-font-30-mb js-font-semibold js-font-black">
            Sweat with us on social #jetsweatfitness
          </div>
        </div>
        <div className="d-flex flex-row js-home-social-gallery justify-content-between">
          <div className="d-flex flex-column js-home-social-gallery-column">
            <img
              className="js-home-social-image"
              src={Image2}
              style={{ height: "30%" }}
            />
            <img
              className="js-home-social-image"
              src={Image4}
              style={{ flexGrow: 1 }}
            />
          </div>
          <div className="d-flex flex-column js-home-social-gallery-column">
            <img
              className="js-home-social-image"
              src={Image1}
              style={{ flexGrow: 1, objectPosition: "right" }}
            />
            <img
              className="js-home-social-image"
              src={Image5}
              style={{ height: "40%" }}
            />
          </div>
          <div className="d-flex flex-column js-home-social-gallery-column">
            <img
              className="js-home-social-image"
              src={Image3}
              style={{ flexGrow: 1 }}
            />
            <img
              className="js-home-social-image"
              src={Image6}
              style={{ height: "50%" }}
            />
          </div>
        </div>
        {/*}<Dots
          items={["A", "B", "C"]}
          onClick={()=>{}}
          className="js-home-social-dots" />*/}
      </div>
    );
  }
}
