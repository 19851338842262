import React from 'react'
import './RescheduleModal.css'
// import DateTimePicker from 'react-datetime-picker';
import moment from 'moment'
import * as CalendarUtil from './CalendarUtil'

class RescheduleModal extends React.Component {
  constructor(props) {
    super(props)
    // let date = new Date(this.props.workout.when);
    this.selectedWorkout = this.props.workout.liveClass
      ? this.props.workout.liveClass
      : this.props.workout.video
    this.state = {
      showModal: true,
      time: moment().format('HH:mm'),
      date: CalendarUtil.formatDate(new Date(), true),
      status: 'scheduled',
      hour: moment(this.props.workout.when).format('hh'),
      minute: moment(this.props.workout.when).format('mm'),
      twelveHourClock: moment(this.props.workout.when).format('A'),
      month: moment(this.props.workout.when).format('MMM'),
      selectedDate: moment(this.props.workout.when).format('DD'),
      year: moment(this.props.workout.when).format('YYYY'),
      dropdownData: {
        hours: [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
        ],
        minutes: [
          '00',
          '05',
          '10',
          '15',
          '20',
          '25',
          '30',
          '35',
          '40',
          '45',
          '50',
          '55',
        ],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        years: [moment().format('YYYY'), moment().add(1, 'y').format('YYYY')],
        monthDays: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        monthDaysLeap: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      },
    }
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.closePoPup = this.closePoPup.bind(this)
  }

  handleOpenModal() {
    this.setState({ showModal: true })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
    this.props.closeModal()
  }

  closePoPup() {
    this.props.closeModal()
  }

  onSubmit() {
    const s = this.state
    const get24HourBase = s.hour === '12' ? 0 : s.hour
    const hour =
      s.twelveHourClock === 'AM' ? get24HourBase : Number(get24HourBase) + 12
    const date = moment(
      `${s.month}-${s.selectedDate}-${s.year} ${hour}:${s.minute}`,
      'MMM-DD-YYYY HH:mm'
    )
    this.props.closeModal()
    fetch('/api/workout/update', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: this.props.user.authToken,
      },
      body: JSON.stringify({
        workoutId: this.props.workout.id,
        when: date.format('MM-DD-YYYY HH:mm'),
      }),
    })
      .then((_) =>
        fetch('/api/workout/updateStatus', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Authorization: this.props.user.authToken,
          },
          body: JSON.stringify({
            workoutId: this.props.workout.id,
            status: this.state.status,
          }),
        })
      )
      .then((_) => this.props.onScheduleChange())

    this.props.setCalendarDefaultDate(moment(date))
  }

  onDelete() {
    if (this.props.deleteModal) {
      const dateSnapshot = moment(
        `${this.state.date} ${this.state.time}`
      ).startOf('month')
      this.props.closeModal()
      fetch('/api/workout/delete', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: this.props.user.authToken,
        },
        body: JSON.stringify({
          workoutId: this.props.workout.id,
        }),
      }).then((_) => this.props.onScheduleChange(dateSnapshot))
    }
  }

  onChange(event) {
    return (event) =>
      this.setState(
        {
          [event.target.name]: event.target.value,
        },
        () => {}
      )
  }
  render() {
    const { whiteLabelBackgroundStyles } = this.props
    const dates = []
    const monthIdx = this.state.dropdownData.months.indexOf(this.state.month)
    const leapYear = moment(this.state.selectedDate.year).isLeapYear()
    const months = leapYear
      ? this.state.dropdownData.monthDaysLeap
      : this.state.dropdownData.monthDays

    for (let i = 1; i <= months[monthIdx]; i++) {
      i < 10 ? dates.push('0' + i) : dates.push(i)
    }
    if (this.props.deleteModal) {
      return (
        <div className="reschedule-modal-content">
          <div className="delete-form">
            <div className="submit" onClick={this.onDelete}>
              Delete
            </div>
            <div className="submit" onClick={this.closePoPup}>
              Cancel
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={'reschedule-modal-content'}>
          <div className="schedule-form">
            <h3
              style={{
                textAlign: 'left',
                width: '100%',
                color: '#C4C4C4',
                fontSize: 18,
                fontWeight: 'bold',
                marginLeft: 8,
              }}
            >
              Date
            </h3>
            <div className="date-section">
              <select
                name="month"
                value={this.state.month}
                onChange={this.onChange()}
              >
                {this.state.dropdownData.months.map((ele, idx) => {
                  return <option value={ele}>{ele}</option>
                })}
              </select>
              <select
                name="selectedDate"
                value={this.state.selectedDate}
                onChange={this.onChange()}
              >
                {dates.map((ele, idx) => {
                  return <option value={ele}>{ele}</option>
                })}
              </select>
              <select
                name="year"
                value={this.state.year}
                onChange={this.onChange()}
              >
                {this.state.dropdownData.years.map((ele, idx) => {
                  return <option value={ele}>{ele}</option>
                })}
              </select>
            </div>
            <div className="date-section">
              <select
                name="hour"
                value={this.state.hour}
                onChange={this.onChange()}
              >
                {this.state.dropdownData.hours.map((ele, idx) => {
                  return <option value={ele}>{ele}</option>
                })}
              </select>
              <select
                name="minute"
                value={this.state.minute}
                onChange={this.onChange()}
              >
                {this.state.dropdownData.minutes.map((ele, idx) => {
                  return <option value={ele}>{ele}</option>
                })}
              </select>
              <select
                name="twelveHourClock"
                value={this.state.twelveHourClock}
                onChange={this.onChange()}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>

            <div className="date-section">
              <select
                onChange={this.onChange()}
                value={this.state.status}
                name="status"
                style={{ width: '100%' }}
              >
                <option value="completed">Completed</option>
                <option value="skipped">Skipped</option>
                <option value="scheduled">Scheduled</option>
              </select>
            </div>
            <div
              className="submit date-section"
              style={
                whiteLabelBackgroundStyles.backgroundColor
                  ? { ...whiteLabelBackgroundStyles }
                  : {}
              }
              onClick={this.onSubmit}
            >
              Submit changes
            </div>
          </div>
        </div>
      )
    }
  }
}

export default RescheduleModal
