import React, { Component } from 'react'
import Swiper from 'screens/home/components/swiper'
import TextItemCombo from './components/textitemcombo.js'
import Link from '../../components/link'
import Image1 from './assets/image1@3x.png'
import Image2 from './assets/image2@3x.png'
import Image3 from './assets/image3@3x.png'
import './why.css'

const OFFSET_FACTOR_DK = 88.6458332667
const OFFSET_FACTOR_MB = 94.20138

const VIEWPORT_WIDTH_DK = 1366
const VIEWPORT_WIDTH_MB = 414

const SECTIONS = [
  {
    alt:
      'Person doing a side plank pose, with the that is up holding a weight, and the hand that is down grabbing a weight pressed to the floor.',
    orientation: 'standard',
    header: 'Sweat with the best and nothing less.',
    description:
      'Our 30+ studio partners were handpicked for their excellence, proven class methodologies and motivating instructors across Yoga, HIIT, Barre, Cardio, Pilates, Dance, Martial Arts, Strength Training & more. With new classes added monthly, you’ll never get bored!',
    imgSrc: Image1,
    imgClassName: 'js-home-why-img-1',
    textContainerClassName: 'js-home-why-text-container-1',
    headerClassName: 'js-home-why-header js-home-why-header-1',
    textClassName: 'js-home-why-text',
    containerClassName: 'js-home-why-combo-container',
  },
  {
    alt: 'Person jumping up in the middle of an exercise.',
    orientation: 'reverse',
    header: 'Work out your way. On your schedule. At your level.',
    description:
      'Whether you want a daily dose of fitspo, a 10-min core workout w/ dumbbells, or a 1-mo fitness program tailored to your goals – JETSWEAT adapts to your style, tracks your progress, and keeps you motivated and accountable along the way.',
    imgSrc: Image2,
    imgClassName: 'js-home-why-img-2',
    textContainerClassName: 'js-home-why-text-container-2',
    headerClassName: 'js-home-why-header',
    textClassName: 'js-home-why-text',
    containerClassName: 'js-home-why-combo-container',
  },
  {
    alt:
      'Person doing yoga while watching a class on their tablet on the floor.',
    orientation: 'standard',
    header: 'Unlock unlimited studio classes streamable from any device.',
    description:
      'Your all-access JETSWEAT membership lets you stream more than 500 exclusive workout classes on-demand at home or on the go through our mobile app, website, or your TV via Chromecast, Roku or HDMI.',
    imgSrc: Image3,
    textContainerClassName: 'js-home-why-text-container-3',
    imgClassName: 'js-home-why-img-3',
    headerClassName: 'js-home-why-header',
    textClassName: 'js-home-why-text',
    containerClassName: 'js-home-why-combo-container',
  },
]

export default class Why extends Component {
  render() {
    return (
      <div className="js-home-why-container d-flex flex-column align-items-center">
        <div className="js-raleway js-font-40 js-font-30-mb js-font-semibold js-font-black js-home-why-title">
          Why join JETSWEAT?
        </div>
        <Swiper
          swiperDesktop={false}
          dotsClassName=""
          offsetDivider="10"
          containerClassName="js-home-why-swiper d-flex flex-md-column align-items-center"
          viewport_width_dk={VIEWPORT_WIDTH_DK}
          viewport_width_mb={VIEWPORT_WIDTH_MB}
          offset_factor_dk={OFFSET_FACTOR_DK}
          offset_factor_mb={OFFSET_FACTOR_MB}
          list={SECTIONS}
        >
          {SECTIONS.map((item) => {
            let {
              alt,
              header,
              description,
              imgSrc,
              imgClassName,
              textContainerClassName,
              headerClassName,
              textClassName,
              containerClassName,
              orientation,
            } = item
            return (
              <TextItemCombo
                alt={alt}
                header={header}
                description={description}
                imgSrc={imgSrc}
                imgClassName={imgClassName}
                textContainerClassName={textContainerClassName}
                headerClassName={headerClassName}
                textClassName={textClassName}
                orientation={orientation}
                containerClassName={containerClassName}
              />
            )
          })}
        </Swiper>
        {/*<div className="d-flex flex-md-column align-items-center">
          <TextItemCombo
            alt="Person doing a side plank pose, with the that is up holding a weight, and the hand that is down grabbing a weight pressed to the floor."
            header="Sweat with the best and nothing less."
            description="Our 30+ studio partners were handpicked for their excellence, proven class methodologies and motivating instructors across Yoga, HIIT, Barre, Cardio, Pilates, Dance, Martial Arts, Strength Training & more. With new classes added monthly, you’ll never get bored!"
            imgSrc={Image1}
            imgClassName="js-home-why-img-1"
            textContainerClassName="js-home-why-text-container-1"
            headerClassName="js-home-why-header js-home-why-header-1"
            textClassName="js-home-why-text" />
          <TextItemCombo
            alt="Person jumping up in the middle of an exercise."
            orientation="reverse"
            header="Work out your way. On your schedule. At your level."
            description="Whether you want a daily dose of fitspo, a 10-min core workout w/ dumbbells, or a 1-mo fitness program tailored to your goals – JETSWEAT adapts to your style, tracks your progress, and keeps you motivated and accountable along the way."
            imgSrc={Image2}
            imgClassName="js-home-why-img-2"
            textContainerClassName="js-home-why-text-container-2"
            headerClassName="js-home-why-header"
            textClassName="js-home-why-text" />
          <TextItemCombo
            alt="Person doing yoga while watching a class on their tablet on the floor."
<<<<<<< HEAD
            header="Unlock unlimited studio classes streamable from any device."
=======
            header="Unlock unlimited studio classesstreamable from any device."
>>>>>>> whiteLabelColorChanges
            description="Your all-access JETSWEAT membership lets you stream 100’s of exclusive workout classes on-demand at home or on the go through our mobile app, website, or your TV via Chromecast or HDMI."
            imgSrc={Image3}
            textContainerClassName="js-home-why-text-container-3"
            imgClassName="js-home-why-img-3"
            headerClassName="js-home-why-header"
            textClassName="js-home-why-text" />
        </div>*/}
        <div className="js-text-align-center js-font-40 js-font-25-mb js-font-semibold js-raleway js-font-000000 js-home-why-cta-description">
          Do it all each month for half the cost of a single class at one of our
          studios!
        </div>
        <Link
          label="Start your free trial"
          color="light-green"
          href="https://www.jetsweatfitness.com/login"
        />
      </div>
    )
  }
}
