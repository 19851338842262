import {
  getAllProgressData,
  getPreviousMonthData
} from '../../actions';
import { formatData } from '../../utilities';

const initialState = {
  dailyProgress: [],
  totals: {},
  previousMonth: [],
  previousMonthTotals: {},
};

export const fetchProgressData = function (
  userData,
  year = '2019',
  month = '01',
) {
  return async (dispatch) => {
    const response = await fetch('/api/history/myprogress', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: userData.authToken,
      },
      body: JSON.stringify({
        fromDate: `${year}-${month}-01`,
        toDate: `${year}-${month}-31`,
        beforeDate: Date.now(),
      }),
    });
    const json = await response.json();

    const progress = json.content;

    const dailyProgress = progress.data;
    const totals = progress.total;

    const action = getAllProgressData(formatData(dailyProgress), totals);
    dispatch(action);
  };
};

export const fetchLastMonthsProgress = function (
  userData,
  year = '2019',
  month = '01',
) {
  return async (dispatch) => {
    const response = await fetch('/api/history/myprogress', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: userData.authToken,
      },
      body: JSON.stringify({
        fromDate: `${year}-${month}-01`,
        toDate: `${year}-${month}-31`,
        beforeDate: Date.now(),
      }),
    });
    const json = await response.json();

    const progress = json.content;
    const previousMonth = progress.data;

    const totals = progress.total;
    const action = getPreviousMonthData(formatData(previousMonth), totals);
    dispatch(action);
  };
};

export default function progressReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_ALL_PROGRESS_DATA':
      return {
        ...state,
        dailyProgress: action.dailyProgress,
        totals: action.totals,
      };
    case 'GET_PREVIOUS_MONTH_DATA':
      return {
        ...state,
        previousMonth: action.previousMonth,
        previousMonthTotals: action.previousMonthTotals,
      };
    default:
      return state;
  }
}
