import React, { Component } from 'react';
import './textfield.css';

export default class TextField extends Component {
  render() {
    let {
        name,
        value,
        error,
        placeholder
    } = this.props;

    return (
      <input
        name={name}
        value={value}
        placeholder={placeholder}
        className="js-textfield js-font-18-mb js-font-8A8A8A js-open-sans js-background-ECECEC"/>
    )
  }
}
