import React from 'react'

const Ending = ({ compatibleDevices }) => (
  <div className="ending">
    <div className="backgroundimgcontainer">
      <h2 className="section-title">Access the web platform from anywhere</h2>
      <p className="ending-subtext">
        JETSWEAT currently has an iOS app and a web-based platform, so you
        can do JETSWEAT from your iPhone or laptop on-the-go, or connect
        your device to a TV screen to immerse yourself in the full studio
        experience.
      </p>
    </div>

    <div className="pure-u-md-1-1 pure-u-sm-1 box-1-1">
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.laptop}
          alt="Laptop Icon"
        />
        Web
      </div>
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.phone}
          alt="Mobile Icon"
        />
        Mobile
      </div>
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.chromecast}
          alt="Chromecast Icon"
        />
        Chromecast
      </div>
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.airPlay}
          alt="Airplay Icon"
        />
        Airplay
      </div>
      <div className="pure-u-1-5 box-1-2">
        <img
          className="pure-img"
          src={compatibleDevices.hdmi}
          alt="HDMI Icon"
        />
        HDMI
      </div>
    </div>
  </div>
)

export default Ending;
