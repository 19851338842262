import React, { Component, lazy, Suspense } from 'react'

import branch from 'branch-sdk'
import { withRouter, Switch, Route } from 'react-router-dom'
import axios from 'axios'
import './App.scss'
import 'aos/dist/aos.css'
import './views/New_Platform/Dashboard/DashboardBody.scss'
import './views/New_Platform/Progress/Progress.scss'

import { BeatLoader as Loader } from 'react-spinners'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import authFetch from 'authFetch/authFetch'
import AOS from 'aos'
import { Helmet } from 'react-helmet'
import jetsweatFavicon from 'images/favicon.ico'
import punchpedalhouseFavicon from 'images/punch-pedal-favicon.ico'
import boxandflowFavicon from 'images/boxandflow-favicon.png'
import bodyrokFavicon from 'images/bodyrok-favicon.png'
import PunchPedalFavicon from 'images/punch-pedal-favicon.ico'
import BoxAndFlowFavicon from 'images/boxandflow-favicon.png'
import BodyrokFavicon from 'images/bodyrok-favicon.png'
import SingleStudioContainer from './components/studios/SingleStudioView'
import Navbar from './components/navbar.js'
import Jumbotron from './components/jumbotron.js'
import PromoSignup from './components/account/promoSignup.js'
import NewPromoSignup from './components/account/NewPromoSignup.js'
import Signup from './components/account/signup.js'
import GymPassSignup from './components/gympass/GymPassSignup.js'
import WhiteLabelSignup from './components/whitelabel/WhiteLabelSignUp.js'
import WhiteLabelPayment from './components/whitelabel/WhiteLabelPayment.js'
import WhiteLabelLogin from './components/whitelabel/WhiteLabelLogin.js'
import WhiteLabelDaypass from './components/whitelabel/WhiteLabelDaypass'
import Payment from './components/payment.js'
import Subscription from './components/subscription.js'
import Cancel from './components/cancel.js'
import JetsweatLive from './components/jetsweatLive.js'
import Login from './components/account/login.js'
import ForgotPassword from './components/account/ForgotPassword'
import Contact from './components/contact.js'
import Account from './components/account/account.js'
import Logout from './components/account/logout.js'
import PrivacyPolicy from './components/privacypolicy.js'
import Terms from './components/terms.js'
import Howtoconnect from './components/howtoconnect'
// import Platform from "./views/Platform/index";
import ExploreStudio from './components/sliders/exploreStudio'
import CalendarContainer from './views/Calendar/CalendarContainer'
import AppleCertificate from './apple-certificate'
import FAQ from './components/faq.js'

import HubSpot from './utilities/hubSpot'
import studios from './utilities/studiolist'
import jetsweatLive from './components/jetsweatLive'
import Howitworks from './components/howitworks.js'
import Home from 'screens/home/home.screen'

const defaultLogo = require('./images/blacklogo.png')

const PlatformContainer = lazy(() =>
  import('./views/New_Platform/PlatformContainer')
)
const AllStudios = lazy(() => import('./components/studios/allStudios'))

window.isProduction = !!(
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
)
const favicons = { punchpedalhouseFavicon, boxandflowFavicon, bodyrokFavicon }

class App extends Component {
  // static defaultProps = {
  //   whiteLabelBackgroundStyles: {},

  //   whiteLabelFontStyles: {},
  // };

  constructor(props) {
    super(props)

    this.state = {
      isLoggedIn: !!localStorage.authToken,
      isLoading: true,
      subscription: 'monthly',
      currUser: null,
      whiteLabelURLSuffix: '',
      whiteLabelStudios: [],
      navBarLogo: '',
      whiteLabel: false,
      whiteLabelDomainStudio: {},
      whiteLabelDomain: false,
      whiteLabelBackgroundStyles: {},
      whiteLabelFontStyles: {},
      pageTitle:
        'JETSWEAT | Stream Unlimited Workout Classes from Leading Fitness Studios',
      faviconSrc: jetsweatFavicon,
    }

    this.setCurrUser = this.setCurrUser.bind(this)
    this.setLogo = this.setLogo.bind(this)
    this.setAuthToken = this.setAuthToken.bind(this)
    this.loginFromToken = this.loginFromToken.bind(this)
    this.logout = this.logout.bind(this)
    this.setSubscription = this.setSubscription.bind(this)
    this.getAuthToken = this.getAuthToken.bind(this)
    this.refreshLoggedInUser = this.refreshLoggedInUser.bind(this)
    this.initiateExternalServices()
    this.redirectToHomePage = this.redirectToHomePage.bind(this)
  }

  setSubscription(subscription) {
    this.setState({ subscription })
  }

  setAuthToken(authToken) {
    localStorage.setItem('authToken', authToken)
  }

  setLogo(studio = defaultLogo) {
    this.setState({ navBarLogo: studio })
  }

  setCurrUser(currUser) {
    localStorage.setItem('currUser', JSON.stringify(currUser))
    if (currUser.authToken) {
      this.setAuthToken(currUser.authToken)
    }
    this.setState({
      isLoggedIn: true,
      isLoading: false,
      currUser,
    })
  }

  logout() {
    localStorage.removeItem('currUser')
    localStorage.removeItem('randomDaily')
    localStorage.removeItem('authToken')
    this.setState({
      isLoggedIn: false,
      isLoading: false,
      currUser: null,
    })
  }

  getAuthToken() {
    return this.state.currUser.authToken
  }

  initiateExternalServices() {
    branch.init('key_live_birB93AdBoSzWQwgtkp9vmdlryn2vOgO')
  }

  loginFromToken(authToken) {
    // Fetch user and subscription
    if (!authToken) {
      return Promise.reject()
    }

    let user = null
    return authFetch('api/user/me', { headers: { Authorization: authToken } })
      .then((body) => {
        if (!body.status) {
          return Promise.reject(body.content)
        }
        user = body.content
        user.authToken = authToken
        return { statues: true, content: user }
      })
      .then(() =>
        authFetch('api/user/subscription', {
          headers: { Authorization: authToken },
        })
      )
      .then((body) => {
        if (!body.status) {
          return Promise.reject(body.content)
        }
        user.subscription = body.content
        this.loginUserSuccess(authToken, user)
        return { statues: true, content: user }
      })
      .catch((error) => {
        this.logout()
        console.error('Failed to fetch user information')
      })
  }

  loginUserSuccess(authToken, user) {
    this.setCurrUser({ ...user, authToken })
  }

  getToken() {
    return localStorage.getItem('authToken') || ''
  }

  refreshLoggedInUser() {
    const authToken = this.getToken()
    if (authToken) {
      return this.loginFromToken(authToken)
    }
    return Promise.resolve(null)
  }

  componentDidUpdate(prevProps, prevState) {
    let { currUser } = this.state
    HubSpot(window.location.href.includes('/newplatform'))
    // if (
    //   (prevState.currUser === null &&
    //     currUser &&
    //     currUser.subscription &&
    //     currUser.subscription.packageId) ||
    //   (prevState.currUser &&
    //     prevState.currUser.subscription &&
    //     !prevState.currUser.subscription.packageId &&
    //     currUser.subscription &&
    //     currUser.subscription.packageId)
    // ) {
    //   let subscription = this.state.currUser.subscription.packageId.split(
    //     '_'
    //   )[0]
    //   if (subscription !== 'com.jetsweat-test.monthly.7daystrial') {
    //     let pageTitle = subscription[0].toUpperCase() + subscription.slice(1)
    //     console.log(subscription)
    //     let faviconSrc = favicons[subscription + 'Favicon']
    //     const studio = this.getStudio(
    //       pageTitle.toLowerCase(),
    //       this.state.whiteLabelStudios
    //     )
    //     this.setState({
    //       whiteLabel: true,
    //       whiteLabelDomainStudio: studio,
    //       navBarLogo: studio ? studio.logoUrl,
    //       whiteLabelDomain: true,
    //       whiteLabelBackgroundStyles: {
    //         backgroundColor: `#${studio.colorCode}`,
    //       },
    //       whiteLabelFontStyles: {
    //         color: `#${studio.colorCode}`,
    //       },
    //       pageTitle: studio.name,
    //       faviconSrc,
    //     })
    //   }
    // }
  }

  getStudio(studioLink, data) {
    return data.filter(({ shortName }) => shortName === studioLink)[0]
  }

  async componentDidMount() {
    HubSpot(window.location.href.includes('/newplatform'))
    AOS.init({
      duration: 1000,
    })
    const { data } = await axios.get('/api/studio/listWhitelabels', {
      headers: {
        accepts: 'application/json',
      },
    })

    const whiteLabelStudios = data.content
    this.setState({
      whiteLabelStudios,
    })
    // TODO: Hardcoded for first iteration. May be able to handle and manage
    const currentHost = window.location.host
    if (
      [
        'dev.streaming.punch-pedal.com',
        'streaming.punch-pedal.com',
        'www.streaming.punch-pedal.com',
        'dev.streaming.boxandflow.com',
        'streaming.boxandflow.com',
        'www.streaming.boxandflow.com',
        'dev.streaming.bodyrok.com',
        'streaming.bodyrok.com',
        'www.streaming.bodyrok.com',
      ].includes(currentHost)
    ) {
      const domainMap = {
        'streaming.punch-pedal.com': 'punchpedalhouse',
        'www.streaming.punch-pedal.com': 'punchpedalhouse',
        'dev.streaming.punch-pedal.com': 'punchpedalhouse',
        'streaming.boxandflow.com': 'boxandflow',
        'www.streaming.boxandflow.com': 'boxandflow',
        'dev.streaming.boxandflow.com': 'boxandflow',
        'streaming.bodyrok.com': 'bodyrok',
        'www.streaming.bodyrok.com': 'bodyrok',
        'dev.streaming.bodyrok.com': 'bodyrok',
      }

      let pageTitle = 'Punch Pedal House'
      let faviconSrc = PunchPedalFavicon

      if (
        ['streaming.boxandflow.com', 'www.streaming.boxandflow.com'].includes(
          currentHost
        )
      ) {
        pageTitle = 'Box And Flow'
        faviconSrc = BoxAndFlowFavicon
      }

      if (
        ['streaming.bodyrok.com', 'www.streaming.bodyrok.com'].includes(
          currentHost
        )
      ) {
        pageTitle = 'Bodyrok'
        faviconSrc = BodyrokFavicon
      }

      const studio = this.getStudio(domainMap[currentHost], whiteLabelStudios)

      const { colorCode } = studio
      this.setState({
        whiteLabel: true,
        whiteLabelDomainStudio: studio,
        navBarLogo: studio.logoUrl,
        whiteLabelDomain: true,
        whiteLabelBackgroundStyles: {
          backgroundColor: `#${colorCode}`,
        },
        whiteLabelFontStyles: {
          color: `#${colorCode}`,
        },
        pageTitle,
        faviconSrc,
      })
    }

    const authToken = this.getToken()
    let currUser = localStorage.getItem('currUser') || ''
    try {
      currUser = JSON.parse(currUser)
    } catch (err) {
      currUser = false
    }
    if (currUser) {
      // Set from localstorage for fast load
      this.setCurrUser(currUser)
    }
    if (authToken) {
      // Then do a proper login from the token
      return this.loginFromToken(authToken).catch(() =>
        this.setState({
          isLoading: false,
        })
      )
    }

    this.setState({
      isLoading: false,
      whiteLabelStudios,
    })
  }

  redirectToHomePage() {
    const { pathname } = this.props.location
    const studioName = pathname.replace('/studios/', '')
    const removeStudios = [
      'skytingyoga',
      'chloekernaghanyoga',
      'trooperfitness',
    ]

    if (removeStudios.includes(studioName)) return this.props.history.push('/')
  }

  getWhiteLabelDomainRoutes() {
    const {
      whiteLabelDomain,
      whiteLabelDomainStudio,
      whiteLabelBackgroundStyles,
      whiteLabelFontStyles,
      pageTitle,
      faviconSrc,
    } = this.state
    if (!whiteLabelDomainStudio) return null
    const { isLoading, currUser } = this.state
    const AuthenticatedRouteCurry = (props) => (
      <AuthenticatedRoute
        {...props}
        isLoading={isLoading}
        currUser={currUser}
      />
    )

    const reload = () => window.location.reload()
    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
          <link rel="shortcut icon" href={faviconSrc} />
        </Helmet>
        <Switch>
          <Route
            path="/.well-known/apple-developer-merchantid-domain-association"
            component={AppleCertificate}
          />
          <AuthenticatedRouteCurry
            subscriberOnly
            path="/newplatform/"
            render={(props) => (
              <Suspense fallback={<Loader color="#9ac8b9" />}>
                <PlatformContainer
                  {...props}
                  studio={this.state.whiteLabelDomainStudio}
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                  whiteLabelFontStyles={whiteLabelFontStyles}
                  isWhiteLabelDomain={this.state.whiteLabelDomain}
                  user={currUser}
                />
              </Suspense>
            )}
          />
          <Route
            path="/contact"
            isLoggedIn={this.state.isLoggedIn}
            component={Contact}
          />
          <Route
            path="/faq"
            isLoggedIn={this.state.isLoggedIn}
            component={FAQ}
          />
          <Route
            path="/studios"
            studioId={whiteLabelDomainStudio.shortName}
            isLoggedIn={this.state.isLoggedIn}
            component={SingleStudioContainer}
          />
          {this.redirectToHomePage()}
          <Route
            path="/"
            render={(props) => (
              <Navbar
                {...props}
                {...this.state}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                whiteLabelFontStyles={whiteLabelFontStyles}
                whiteLabelDomain={this.state.whiteLabelDomain}
                studio={this.state.whiteLabelDomainStudio}
                isLoggedIn={this.state.isLoggedIn}
                setLogo={this.setLogo}
                whiteLabel={this.state.whiteLabel}

                // {...props}
                // {...this.props}
              />
            )}
          />
        </Switch>
        <Route
          exact
          path="/specials/:promoName"
          render={(props) => (
            <PromoSignup
              {...props}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Route
          exact
          path="/newspecials/:promoName"
          render={(props) => (
            <NewPromoSignup
              {...props}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        {/* <AuthenticatedRouteCurry
          redirectSubscriber
          path="/payment"
          render={(props) => (
            <WhiteLabelPayment
              {...props}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
              refreshLoggedInUser={this.refreshLoggedInUser}
              studio={whiteLabelDomainStudio}
            />
          )}
        /> */}
        <Route
          studio={whiteLabelDomainStudio.shortName}
          exact
          path="/daypass"
          render={(props) => (
            <WhiteLabelDaypass
              {...props}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              whiteLabelDomain={this.state.whiteLabelDomain}
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
              refreshLoggedInUser={this.refreshLoggedInUser}
              studio={whiteLabelDomainStudio}
            />
          )}
        />
        <Route
          exact
          path="/signup"
          render={() => (
            <WhiteLabelSignup
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              whiteLabelDomain={this.state.whiteLabelDomain}
              studio={whiteLabelDomainStudio}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Route
          exact
          path="/signup/daypass"
          render={() => (
            <WhiteLabelSignup
              daypass
              whiteLabelDomain={this.state.whiteLabelDomain}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              studio={whiteLabelDomainStudio}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Route
          exact
          path="/signup/gympass"
          render={(props) => (
            <GymPassSignup
              {...props}
              setCurrUser={this.setCurrUser}
              currUser={currUser}
              isLoggedIn={this.state.isLoggedIn}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Route
          exact
          path={['/login', '/']}
          render={(props) => (
            <WhiteLabelLogin
              {...props}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              whiteLabelDomain={this.state.whiteLabelDomain}
              studio={whiteLabelDomainStudio}
              setCurrUser={this.setCurrUser}
              isLoggedIn={this.state.isLoggedIn}
            />
          )}
        />
        <Route
          exact
          path="/login/daypass"
          render={(props) => (
            <WhiteLabelLogin
              {...props}
              daypass
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              studio={whiteLabelDomainStudio}
              whiteLabelDomain={whiteLabelDomain}
              setCurrUser={this.setCurrUser}
              isLoggedIn={this.state.isLoggedIn}
            />
          )}
        />
        <AuthenticatedRouteCurry
          redirectSubscriber
          path="/checkout"
          render={(props) => (
            <WhiteLabelPayment
              {...props}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
              refreshLoggedInUser={this.refreshLoggedInUser}
              whiteLabelDomain={this.whiteLabelDomain}
              isWhiteLabelDomain={this.state.whiteLabelDomain}
              studio={this.state.whiteLabelDomainStudio}
            />
          )}
        />
        <Route
          path="/forgot-password"
          render={(props) => (
            <ForgotPassword
              {...props}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
            />
          )}
        />
        <AuthenticatedRouteCurry
          redirectSubscriber
          path="/subscription"
          render={() => (
            <Subscription
              isLoggedIn={this.state.isLoggedIn}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
            />
          )}
        />
        <Route
          path="/cancel"
          render={() => (
            <Cancel
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
            />
          )}
        />
        <AuthenticatedRouteCurry
          path="/account"
          render={() => (
            <Account
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              isWhiteLabelDomain={this.state.whiteLabelDomain}
              currUser={this.state.currUser}
            />
          )}
        />
        <Route
          exact
          path="/logout"
          render={() => (
            <Logout match={this.props.match} logout={this.logout} />
          )}
        />
        <Route path="/terms" component={Terms} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/how-it-works" component={Howitworks} />
        <Route exact path="/how-to-connect" component={Howtoconnect} />
        <Route path="/live" component={JetsweatLive} />
        <Suspense fallback={<Loader />}>
          <Route exact path="/studios" component={AllStudios} />
        </Suspense>
        <Route exact path="/explorestudio" component={ExploreStudio} />
        <Route exact path="/test" component={CalendarContainer} />
      </div>
    )
  }

  getGeneralRoutes() {
    const {
      isLoading,
      currUser,
      whiteLabelStudios,
      pageTitle,
      faviconSrc,
      whiteLabelDomain,
      whiteLabelDomainStudio,
      whiteLabelBackgroundStyles,
      whiteLabelFontStyles,
    } = this.state

    const reload = () => window.location.reload()

    if (!whiteLabelStudios) return null
    const AuthenticatedRouteCurry = (props) => (
      <AuthenticatedRoute
        {...props}
        isLoading={isLoading}
        currUser={currUser}
      />
    )

    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
          <link rel="shortcut icon" href={faviconSrc} />
        </Helmet>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Home setLogo={this.setLogo} studios={whiteLabelStudios} />
            }}
          />
          <Route
            path="/.well-known/apple-developer-merchantid-domain-association"
            component={AppleCertificate}
          />
          <AuthenticatedRouteCurry
            subscriberOnly
            path="/newplatform/"
            render={(props) => (
              <Suspense fallback={<Loader color="#9ac8b9" />}>
                <PlatformContainer
                  {...props}
                  {...this.props}
                  user={currUser}
                  studio={this.state.whiteLabelDomainStudio}
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                  whiteLabelFontStyles={whiteLabelFontStyles}
                  isWhiteLabelDomain={this.state.whiteLabelDomain}
                />
              </Suspense>
            )}
          />
          <Route
            path="/contact"
            isLoggedIn={this.state.isLoggedIn}
            component={Contact}
          />
          <Route
            path="/faq"
            isLoggedIn={this.state.isLoggedIn}
            component={FAQ}
          />
          <Route
            path="/studios/:studioName"
            isLoggedIn={this.state.isLoggedIn}
            component={SingleStudioContainer}
          />
          {this.redirectToHomePage()}
          <Route
            path="/"
            render={(props) => (
              <Navbar
                studio={this.state.whiteLabelDomainStudio}
                isLoggedIn={this.state.isLoggedIn}
                setLogo={this.setLogo}
                whiteLabel={this.state.whiteLabel}
                {...this.state}
              />
            )}
          />
        </Switch>
        {/*}<Route exact path="/" component={Jumbotron} />*/}
        <Route
          exact
          path="/specials/:promoName"
          render={(props) => (
            <PromoSignup
              {...props}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Route
          exact
          path="/newspecials/:promoName"
          render={(props) => (
            <NewPromoSignup
              {...props}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />

        <Route
          exact
          path="/signup"
          render={() => {
            return this.state.whiteLabel ? (
              <WhiteLabelSignup
                studio={this.state.whiteLabelDomainStudio}
                currUser={currUser}
                loginFromToken={this.loginFromToken}
                whiteLabelDomain={whiteLabelDomain}
                whiteLabelFontStyles={whiteLabelFontStyles}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              />
            ) : (
              <Signup
                currUser={currUser}
                loginFromToken={this.loginFromToken}
              />
            )
          }}
        />

        <Route
          exact
          path="/login"
          render={(props) => {
            return this.state.whiteLabel ? (
              <WhiteLabelLogin
                {...props}
                studio={this.state.whiteLabelDomainStudio}
                currUser={currUser}
                loginFromToken={this.loginFromToken}
                whiteLabelDomain={whiteLabelDomain}
                whiteLabelFontStyles={whiteLabelFontStyles}
                setCurrUser={this.setCurrUser}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              />
            ) : (
              <Login
                {...props}
                setCurrUser={this.setCurrUser}
                isLoggedIn={this.state.isLoggedIn}
              />
            )
          }}
        />

        <AuthenticatedRouteCurry
          redirectSubscriber
          path="/payment"
          render={(props) => (
            <Payment
              {...props}
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
              refreshLoggedInUser={this.refreshLoggedInUser}
            />
          )}
        />

        {whiteLabelStudios.map((studio) => (
          <AuthenticatedRouteCurry
            redirectSubscriber
            path={`/checkout/${studio.shortName}`}
            render={(props) => (
              <WhiteLabelPayment
                {...props}
                isLoggedIn={this.state.isLoggedIn}
                currUser={this.state.currUser}
                subscription={this.state.subscription}
                setSubscription={this.setSubscription}
                refreshLoggedInUser={this.refreshLoggedInUser}
                studio={studio}
              />
            )}
          />
        ))}

        {/* JETSWEAT SIGNUP/LOGIN/PAYMENT ROUTES - DAILY SUBS */}

        {/* For Jetsweat daypass, pass studio as null */}
        {/* <Route
          exact
          path="/daypass"
          render={(props) => (
            <Login
              setCurrUser={this.setCurrUser}
              isLoggedIn={this.state.isLoggedIn}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/login/daypass"
          render={(props) => (
            <Login
              setCurrUser={this.setCurrUser}
              isLoggedIn={this.state.isLoggedIn}
              {...props}
            />
          )}
        />
        {/* WHITE LABEL SIGNUP/LOGIN/PAYMENT ROUTES - MONTHLY/YEARLY SUBS */}
        <Route
          exact
          path="/daypass"
          render={(props) => (
            <Login
              {...props}
              setCurrUser={this.setCurrUser}
              isLoggedIn={this.state.isLoggedIn}
            />
          )}
        />

        <Route
          exact
          path="/login/daypass"
          render={(props) => (
            <Login
              {...props}
              setCurrUser={this.setCurrUser}
              isLoggedIn={this.state.isLoggedIn}
            />
          )}
        />
        <Route
          exact
          path="/signup/daypass"
          render={() => (
            <Signup currUser={currUser} loginFromToken={this.loginFromToken} />
          )}
        />

        {/* WHITE LABEL SIGNUP/LOGIN/PAYMENT ROUTES - MONTHLY/YEARLY SUBS */}
        {/* <Route
          exact
          path="/daypass"
          render={(props) => (
            <Login
              setCurrUser={this.setCurrUser}
              isLoggedIn={this.state.isLoggedIn}
              {...props}
            />
          )}
        /> */}

        <Route
          exact
          path="/login/daypass"
          render={(props) => (
            <Login
              {...props}
              setCurrUser={this.setCurrUser}
              isLoggedIn={this.state.isLoggedIn}
            />
          )}
        />

        <Route
          exact
          path="/signup/daypass"
          render={() => (
            <Signup currUser={currUser} loginFromToken={this.loginFromToken} />
          )}
        />
        {whiteLabelStudios.map((studio) => (
          <Route
            studio={studio.shortName}
            exact
            path={`/daypass/${studio.shortName}`}
            render={(props) => (
              <WhiteLabelDaypass
                {...props}
                isLoggedIn={this.state.isLoggedIn}
                currUser={this.state.currUser}
                subscription={this.state.subscription}
                setSubscription={this.setSubscription}
                refreshLoggedInUser={this.refreshLoggedInUser}
                studio={studio}
              />
            )}
          />
        ))}

        {whiteLabelStudios.map((studio) => (
          <Route
            exact
            path={`/signup/${studio.shortName}`}
            render={() => (
              <WhiteLabelSignup
                studio={studio}
                currUser={currUser}
                loginFromToken={this.loginFromToken}
                whiteLabelDomain={whiteLabelDomain}
                whiteLabelFontStyles={whiteLabelFontStyles}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              />
            )}
          />
        ))}

        {whiteLabelStudios.map((studio) => (
          <Route
            exact
            path={`/signup/daypass/${studio.shortName}`}
            render={() => (
              <WhiteLabelSignup
                daypass
                studio={studio}
                currUser={currUser}
                loginFromToken={this.loginFromToken}
                whiteLabelDomain={whiteLabelDomain}
                whiteLabelFontStyles={whiteLabelFontStyles}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              />
            )}
          />
        ))}

        <Route
          exact
          path="/signup/gympass"
          render={(props) => (
            <GymPassSignup
              {...props}
              setCurrUser={this.setCurrUser}
              currUser={currUser}
              isLoggedIn={this.state.isLoggedIn}
              loginFromToken={this.loginFromToken}
            />
          )}
        />

        {whiteLabelStudios.map((studio) => {
          return (
            <Route
              exact
              path={`/login/${studio.shortName}`}
              render={(props) => (
                <WhiteLabelLogin
                  {...props}
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                  whiteLabelFontStyles={whiteLabelFontStyles}
                  whiteLabelDomain={this.state.whiteLabelDomain}
                  studio={whiteLabelDomainStudio}
                  setCurrUser={this.setCurrUser}
                  isLoggedIn={this.state.isLoggedIn}
                />
              )}
            />
          )
        })}

        {whiteLabelStudios.map((studio) => (
          <Route
            exact
            path={`/login/daypass/${studio.shortName}`}
            render={(props) => (
              <WhiteLabelLogin
                {...props}
                daypass
                setCurrUser={this.setCurrUser}
                isLoggedIn={this.state.isLoggedIn}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                whiteLabelFontStyles={whiteLabelFontStyles}
                whiteLabelDomain={this.state.whiteLabelDomain}
                studio={whiteLabelDomainStudio}
              />
            )}
          />
        ))}

        <Route
          path="/forgot-password"
          render={(props) => <ForgotPassword {...props} />}
        />

        <AuthenticatedRouteCurry
          redirectSubscriber
          path="/subscription"
          render={() => (
            <Subscription
              isLoggedIn={this.state.isLoggedIn}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
            />
          )}
        />
        <Route
          path="/cancel"
          render={() => (
            <Cancel
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
            />
          )}
        />
        <AuthenticatedRouteCurry
          path="/account"
          render={() => <Account currUser={this.state.currUser} />}
        />
        <Route
          exact
          path="/logout"
          render={() => (
            <Logout match={this.props.match} logout={this.logout} />
          )}
        />
        <Route
          path="/logout/:studio"
          render={() => (
            <Logout match={this.props.match} logout={this.logout} />
          )}
        />
        <Route
          path="./well-known/apple-developer-merchantid-domain-association"
          onEnter={reload}
        />
        <Route path="/terms" component={Terms} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/how-it-works" component={Howitworks} />
        <Route exact path="/how-to-connect" component={Howtoconnect} />
        <Route path="/live" component={JetsweatLive} />
        <Suspense fallback={<Loader />}>
          <Route exact path="/studios" component={AllStudios} />
        </Suspense>
        <Route exact path="/explorestudio" component={ExploreStudio} />
        <Route exact path="/test" component={CalendarContainer} />
      </div>
    )
  }

  render() {
    if (this.state.whiteLabel) {
      return this.getWhiteLabelDomainRoutes()
    }
    return this.getGeneralRoutes()
  }
}

export default withRouter(App)
