import React, { Component } from "react";
import "./tile.css";

export default class Tile extends Component {
  render() {
    let {
      studioName,
      studioDescription,
      shortname,
      style,
      onClick,
      thumbnailUrl,
    } = this.props;
    return (
      <div
        onClick={onClick.bind(this)}
        style={style}
        className="js-home-explore-tile-container"
      >
        <div className="js-home-explore-tile-video js-background-black">
          <img
            draggable={false}
            src={thumbnailUrl}
            className="js-home-explore-tile-image"
          />
        </div>
        <div className="js-home-explore-tile-description">
          <div className="js-font-20 js-font-20-mb js-font-3D3D3D js-open-sans js-home-explore-tile-description-name">
            {studioName}
          </div>
          <div className="js-font-12 js-font-12-mb js-font-3D3D3D js-open-sans">
            {studioDescription}
          </div>
        </div>
        <div className="js-home-explore-tile-link">
          <a
            href={`/studios/${shortname}`}
            className="js-open-sans js-font-semibold js-font-14 js-font-14-mb js-font-9AC8B9"
          >
            Learn More
          </a>
        </div>
      </div>
    );
  }
}
