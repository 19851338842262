import React, { Component } from 'react'
import branch from 'branch-sdk'
import { Link } from 'react-router-dom'
import '../account/signup.css'
import Footer from '../footer.js'

class Login extends Component {
  static defaultProps = {
    whiteLabelBackgroundStyles: {},
    whiteLabelFontStyles: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      warning: '',
      error: '',
    }
  }

  handleChange = (event) => {
    const fieldName = event.target.name
    this.setState({ [fieldName]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    fetch(`/api/user/login/${this.props.studio.shortName}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    })
      .then((res) => {
        if (res.status >= 400) {
          console.log("We're in the error")
          return res.json()
        }
        return res.json()
      })
      .then((body) => {
        branch.setIdentity(this.state.email)
        if (body == null) {
          return null
        }
        if (body.status) {
          const currUser = body.content
          this.props.setCurrUser(currUser)
          let pushTo
          console.log(currUser)
          console.log(this.props)
          if (currUser.subscription && currUser.subscription.active) {
            pushTo = '/newplatform/dashboard'
          } else if (!this.props.daypass) {
            if (this.props.whiteLabelDomain) {
              pushTo = '/checkout'
            } else {
              pushTo = `/checkout/${currUser.studioShortName}`
            }
          } else {
            if (this.props.whiteLabelDomain) {
              pushTo = '/daypass'
            } else {
              pushTo = `/daypass/${this.props.studio.shortName}`
            }
          }

          this.props.history.push(pushTo)
          if (currUser.subscription && currUser.subscription.active) {
            // window.location.reload()
          }
        } else {
          this.setState({ error: body.content })
        }
      })
  }

  getSignUpLink(daypass, studio) {
    let { whiteLabelFontStyles, whiteLabelDomain } = this.props

    if (whiteLabelDomain.name) {
      return (
        <Link
          to={daypass ? `/signup/daypass` : `/signup`}
          style={{ textDecoration: 'none' }}
        >
          Sign up Here!
        </Link>
      )
    }

    return (
      <Link
        to={daypass ? `/signup/daypass/${studio.shortName}` : `/signup`}
        style={{
          color: '#9ac8b9',
          textDecoration: 'none',
          ...whiteLabelFontStyles,
        }}
      >
        Sign up Here!
      </Link>
    )
  }

  getResetLink() {
    let { whiteLabelDomain, whiteLabelFontStyles, studio } = this.props

    let styles = {
      color: '#9ac8b9',
      textDecoration: 'none',
      ...whiteLabelFontStyles,
    }

    return (
      <Link to="/forgot-password" style={styles}>
        Reset here!
      </Link>
    )
  }

  render() {
    const {
      whiteLabelFontStyles,
      whiteLabelBackgroundStyles,
      studio,
      daypass,
    } = this.props
    // const { whiteLabelFontStyles, whiteLabelBackgroundStyles } = this.props
    let color = {}

    if (studio) {
      color.color = `#${studio.colorCode}`
    }

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="Background-color-nav" />

          <h1
            style={{ ...whiteLabelFontStyles, ...color }}
            className="Main-text Padding"
          >
            {' '}
            Log In{' '}
          </h1>
          {this.state.error ? (
            <div className="Center Padding">
              <div className="errormessage">{this.state.error}</div>
            </div>
          ) : (
            ''
          )}

          <div className="Center Padding">
            <input
              id="signupemail"
              autoFocus
              type="text"
              name="email"
              onChange={this.handleChange}
              className="Input-style Font No-highlight-border"
              placeholder="Email"
            />
          </div>

          <div className="Center Padding">
            <input
              id="signuppassword"
              type="password"
              name="password"
              onChange={this.handleChange}
              className="Input-style Font No-highlight-border"
              placeholder="Password"
            />
          </div>

          <div className="Center Padding">
            <button
              style={{
                backgroundColor: color.color,
                ...whiteLabelBackgroundStyles,
              }}
              type="submit"
              className="Button-style Font signup-button"
            >
              {' '}
              LOG IN{' '}
            </button>
            <br />
            <div style={{ padding: '10px' }}>
              <span style={{ paddingRight: '5px' }}>
                Don't have an account?
              </span>
              {this.getSignUpLink(daypass, studio)}
            </div>
            <div style={{ padding: '10px' }}>
              <p className="message">
                <span style={{ paddingRight: '5px' }}>
                  Forgot your password?
                </span>
                {this.getResetLink()}
              </p>
            </div>
          </div>
        </form>
        <div className="footer-login">
          <Footer currUser={this.props.currUser} studio={this.props.studio} />
        </div>
      </div>
    )
  }
}

export default Login
