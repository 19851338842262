import React, { Component } from "react";
import StudiosHeading from "./studios/studiosHeading";
import "./jumbotron.css";

const logoStyle = {
  height: "27px",
  marginLeft: "2em"
};

class Contact extends Component {
  render() {
    return (
      <div>
        <StudiosHeading
          isLoggedIn={this.props.isLoggedIn}
          studios={() => (
            <img src={require("../images/blacklogo.png")} style={logoStyle} />
          )}
        />
        <div>
          <h2
            style={{ marginTop: "6em", marginLeft: "80px" }}
            className="aboutheading section-title"
          >
            {" "}
            Contact Us{" "}
          </h2>
          <p style={{ marginTop: "2em", marginLeft: "80px" }}>
            {" "}
            We love to hear from you! Send us your feedback, questions or
            comments to support@jetsweatfitness.com.{" "}
          </p>
        </div>
      </div>
    );
  }
}

export default Contact;
