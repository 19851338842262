import React, { Component } from 'react';
import Button from '../../components/button';
import Tile from './components/tile';
import Dots from 'screens/home/components/dots'
import Swiper from 'screens/home/components/swiper';
import Dropdown from 'images/dropdown@3x.png';
import Modal from "react-modal"
import CloseIcon from "images/icons/ic_popup_close@2x.png";
import './explore.css';

const OFFSET_FACTOR_DK = 33.333424;
const OFFSET_FACTOR_MB = 96.85990;
const ITEM_WIDTH_DK = 28.40409;

const VIEWPORT_WIDTH_DK = 1366;
const VIEWPORT_WIDTH_MB = 414;

const THE_WRIGHT_FIT = {
  name: "The Wright Fit",
  wistia_video_id: "wrightfit",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/eca1b19c762585be9d05e73b49ab2e10.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "The Wright Fit is a lifestyle brand that helps clients develop good form and engage their body correctly to get the most out of every rep and move. By specializing in health and fitness design, and personal services like soft tissue therapy and personal training in their gyms, they help clients achieve their best body and well-being.",
  shortname: "thespacebetween"
}

const YOGA_VIDA = {
  name: "Yoga Vida",
  shortname: "yogavida",
  wistia_video_id: "6jhh8j6612",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/351ba251f50008006a63534fd53f7229fd9d428c.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Yoga Vida strives to make yoga accessible to everyone by combining purposeful movements with conscious breath to help improve body awareness. Their classes aim to help clients easily incorporate yoga moves into their daily lives for a focused mind.",
}

const WOOM_CENTER = {
  name: "Woom Center ",
  shortname: "woomcenter",
  wistia_video_id: "m0o4oxq4yn",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/e3da8e08ca27a9aebfb5c1a33258a2f4.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Woom Center blends ancient practices of yoga and meditation with modern technology. They seek to incite curiosity and help clients find a higher state of consciousness through audio-responsive installations, plant-based elixirs and a custom-designed 3D sound system."
}

const SWITCH_PLAYGROUND = {
  name: "Switch Playground",
  shortname: "switchplayground",
  wistia_video_id: "c4pqgubzf5",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/4c277819f71d3fadac2a0943eb5615f2.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "SWITCH incorporates cutting-edge fitness technology, state-of-the-art facilities, dynamic lighting and sound to offer HIIT classes through a labyrinth of playground activities."
}

const SOLACE = {
  name: "Solace",
  shortname: "solace",
  wistia_video_id: "15x548f4cs",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/7c1802ba7493ce55d2bd6a5643af9409.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Solace offers on-demand HIIT, muscle-sculpting and strength-training workouts to help clients of all fitness levels improve their strength, mind and body."
}

const MODELFIT = {
  name: "ModelFIT",
  shortname: "modelfit",
  wistia_video_id: "wcrspa0ver",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/90852797957dffa54a80d08478ddccac.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "ModelFIT is a customized approach to mindful and dynamic movement that builds strength and confident presence both inside and outside the studio. The modelFIT method breaks down large movements to effective and low-impact muscle burning workouts without straining the joints."
}

const MINDFRESH = {
  name: "Mindfresh",
  shortname: "mindfresh",
  wistia_video_id: "5fqxm9t6cp",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/e048237cc0d61d0556b6e9e391a20ed7.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Mindfresh offers meditation, movement and breathing exercises that aim to help clients practice mindfulness, improve posture, relieve anxiety and promote relaxation."
}

const MASTERSKYA =   {
  name: "Masterskya",
  shortname: "masterskya",
  wistia_video_id: "n7voyrqaeo",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/eb81d3ee557d40b4651bb5ae691a94e0.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Masterskya, created by world ranked #2 Brazilian Jiu-Jitsu master Alex Ecklin, teaches clients how to perfect their self-defense and fighting skills, with a great workout. They offer high-level training to clients of all skill levels."
}

const INTENSATI = {
  name: "Intensati",
  shortname: "intensati",
  wistia_video_id: "327yf4cepo",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/4ff30fc250bdbd0e37dd6731e970a48c.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "The IntenSati method helps clients improve their mind and body through an uplifting workout experience that incorporates breath work, cardio, strength exercises and spoken affirmation for a clearner mind."
}

const BODY_SPACE_FITNESS = {
  name: "Bodyspace Fitness",
  wistia_video_id: "mcdpu0sulp",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/742138a1317b3b00830d00becb511e26.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "At Body Space Fitness, expert NYC coaches offer safe and effective weight training methods for clients of all fitness levels. Learn how to carry, push, pull and stabilize loads in the safest and most effective way possible."
}

const BODYROK = {
  name: "BODYROK",
  shortname: "bodyrok",
  wistia_video_id: "syv6awpclq",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/ab3fb787a8cb0a34fecbd7a0bddc2ba9.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "BODYROK offers pilates-inspired, muscle-sculpting workouts that help improve your body and metabolism. They take traditional workouts up a notch by adding high-energy music and form-focused moves to help promote fast results."
}

const BK_PILATES = {
  name: "BK Pilates",
  shortname: "bkpilates",
  wistia_video_id: "5g3q4i82en",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/d05ae5a4bb2b14324e13500a249ebfb33a2e4049.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "BK Pilates aims to help clients achieve their health and fitness goals by creating customized exercise routines that help develop the mind-body connection, improve core strength, achieve greater flexibility and improve posture."
}

const THE_BARI_STUDIO = {
  name: "The Bari Studio",
  shortname: "baristudio",
  wistia_video_id: "9ay1ukt6sk",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/39da7d68bab4246715bbc5e94c0ce24c.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "The Bari Studio marries on-demand, high-intensity cardio workouts with muscle-sculpting classes to help improve your strength and invigorate your mind. They combine sweat-drenching, high-energy sequences that challenge your body and promote fast results without bulking large muscle groups."
}


const THE_STUDIO = {
  name: "The Studio",
  shortname: "thestudio",
  wistia_studio_id: "3g1tooa2yo",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/c5f27a618ae389cc0ccd83c9e60b4b3c.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "The Studio combines Katonah Yoga, Hatha poses and the principles of Pranayama and Kundalini to help clients prevent injury, and improve stability, competency and breathing."
}

const HIT_HOUSE = {
  name: "Hit House",
  shortname: "hithouse",
  wistia_video_id: "2d4q77kfeu",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/76e2f935fbcd81ff578f5895b40102b3.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Hit House is a Muay Thai kickboxing studio that offers high-intensity fitness classes to clients of all levels. They combine kickboxing, cardio and HIIT-based muscle-toning classes to help improve both strength and mobility."
}

const MNTSTUDIO = {
  name: "MNTSTUDIO",
  wistia_video_id: "gdz9mjm02h",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/2d76da90a7c0397403ecac3d8b04fec2.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "MNTSTUDIO offers barre and pilates classes that help clients improve strength and mobility as well as energize the mind, body and spirit. Their classes boost energy and leave clients of all fitness levels feeling stronger from head-to-toe."
}

const FHITTING_ROOM = {
  name: "Fhitting Room",
  shortname: "fhittingroom",
  wistia_video_id: "u1hzupufoe",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/d66ce3c4ba24856e8684e3469ae4e877.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Fhitting Room incorporates HIIT and strength exercises to create efficient workout routines for all fitness levels that help boost endurance, build strength and increase energy."
}

const SALT_SF = {
  name: "Salt SF",
  shortname: "saltsf",
  wistia_video_id: "jx3q31o0gs",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/e509eb1752f010d6d5527b617497a43b.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0" ,
  summary: "Salt SF incorporates functional strength tools like resistance bands, balance balls and sandbags to offer full-body barre, kickboxing and plyometric based workouts."
}

const MAMA_SOUL = {
  name: "Mama Soul",
  shortname: "mamasoul",
  wistia_video_id: "1ym9x8qrnk",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/bb56f2df98049c2a7601cf096acf1189fe8e98ce.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Mama Soul specializes in guiding moms and moms-to-be through each phase of their pregnancy journey with tailored stretching routines, meditation classes and both prenatal and postpartum yoga."
}

const PUNCH_PEDAL_HOUSE = {
  name: "Punch Pedal House",
  shortname: "punchpedalhouse",
  wistia_video_id: "39n74f29ec",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/7e69b73c57d2d365647d9db96e7c4b39.jpg?image_play_button_size=2x&amp;image_crop_resized=720x480&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Punch Pedal House delivers indoor cycling and boxing-inspired bootcamp classes that combine HIIT, boxing, spin and functional strength movements to help clients of all backgrounds achieve their fitness goals."
}

const PLAYLIST_YOGA = {
  name: "Playlist Yoga",
  shortname: "playlistyoga",
  wistia_video_id: "onn6xbomva",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/fa29b1a65cade5c23c29e6d3658c61ea.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Playlist Yoga offers clients music-fueled meditation and yoga classes that match breath with movement and music to help improve strength, flexibility and endurance."
}

const THE_DAILEY_METHOD = {
  name: "The Dailey Method",
  wistia_video_id: "qif768ht7y",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/c0dc040a3d4e2b0cd4dca5306b594e9f.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "The Dailey Method helps clients achieve a strong, lean and sculpted body by incorporating moves from a range of fitness practices. Their laser focus on alignment and strength, leads to better posture, better movement and a better you.",
  shortname: "daileymethod"
}

const THE_SPACE_BETWEEN = {
  name: "The Space Between",
  wistia_video_id: "ym7h0km57q",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/a4ef12bf56962e226811cb3493caf04d2815edc3.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "The Space Between is a yoga experience designed to get you out of your head and into your soul. Their mission is for you to leave feeling more alive than when you came in. We meet you in the space between where you are and where you want to be, in a place of self-discovery, on a quest for self-love.",
  shortname: "thespacebetween"
}

const STRETCHED = {
  name: "Stretch*d",
  shortname: "stretched",
  wistia_video_id: "w1rh057opr",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/ed8c5852a6c9cde347db409e033be997bcab5442.jpg?image_play_button_size=2x&amp;image_crop_resized=400x224&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Stretch*D helps clients improve flexibility, relieve soreness, reduce injury and accelerate recovery through at-home, in-office and on-demand stretching and meditation classes."
}

const ROWGATTA = {
  name: "Rowgatta",
  shortname: "rowgatta",
  wistia_video_id: "qgaz89il8m",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/ff8603ab583d8122eb8f82d7591bc29bc55f62ee.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Rowgatta combines rowing and resistance training to offer plyometrics-based, high-intensity, low-impact bootcamp classes that help build a stronger and healthier you."
}

const NATURALLY_CUT_FITNESS = {
  name: "Naturally Cut Fitness",
  shortname: "naturallycutfitness",
  wistia_video_id: "oznf2wxkyt",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/c50bdca33d3acb539a2dd65474db481af9cc68e6.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Naturally Cut Fitness offers exclusive access to top-rated NYC personal trainers to help clients achieve fitness goals and optimize results. For all skill levels, they provide a range of one-on-one and small group training."
}

const WILLYB = {
  name: "WillyB Fitness",
  wistia_video_id: "sweyt6jqgq",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/585564110502f765d2caf01e26c94b3a.jpg?image_play_button_size=2x&amp;image_crop_resized=400x224&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "WillyB combines crossHIIT, barbell and mobility routines to help clients of all fitness levels improve their technique, srength and range of motion."
}

const JOYRIDE = {
  name: "JoyRide",
  shortname: "joyride",
  wistia_video_id: "ogarh3gm3l",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/583000eb1197c6ec2cfb99d50ec9e37f.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "JoyRide is a cycling and fitness studio that offers high-intensity circuit, cycling, pilates and barre classes to help clients tone every muscle and invigorate their mind. JoyRide gives their clients the tools to empower themselves in and outside the studio."
}

const BOX_AND_FLOW = {
  name: "Box + Flow",
  shortname: "boxandflow",
  wistia_video_id: "syivhzy95u",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/ebc3723c969a5f3e41f3a55a554e3f4d.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Box + Flow marries HIIT, boxing and yoga with the perfect soundtrack to create a fun, challenging fitness experience that helps promote energy, breath and movement, leaving you feeling energized and empowered."
}

const ROOTS_66_FIT = {
  name: "Roots 66 Fit",
  shortname: "roots66fit",
  wistia_video_id: "sulox0y8q3",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/52cc27d3d98fbd72fbf7251f5ea55a0368a56081.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Roots 66 Fit offers cardio, yoga and pilates classes that incorporate simple, smart, and intelligent science-based methods. They help students of all fitness levels improve posture, balance and flexibility, with a positive mindset."
}

const JET_CETERA = {
  name: "Jet Cetera",
  shortname: "jetcetera",
  wistia_video_id: "sulox0y8q3",
  thumbnail_url: "https://embed-ssl.wistia.com/deliveries/52cc27d3d98fbd72fbf7251f5ea55a0368a56081.jpg?video_still_time=10",
  summary: "Get fit from home or on the go with Jet Cetera from JETSWEAT. You'll get access to live content from top fitness influencers, coveted brands, and some of the world's best boutique fitness studios."
}














const THE_PERFORMANCE_LAB = {
  name: "Performance Lab by The Wright Fit",
  shortname: "wrightfit",
  wistia_video_id: "7qzigv1ray",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/eca1b19c762585be9d05e73b49ab2e10.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "The Performance Lab is a lifestyle brand that helps clients develop good form and engage their body correctly to get the most out of every rep and move. They specialize in health and fitness design and services like soft tissue therapy and personal training."
}



const MPOWER_PILATES_AND_CYCLE = {
  name: "MPower Pilates & Cycle",
  shortname: "mpower",
  wistia_video_id: "v8a1123znq",
  thumbnail_url: "https://embed-fastly.wistia.com/deliveries/8fc709473eb6124a7761a9a9f260fcfbe2e4de89.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "BK Pilates aims to help clients achieve their health and fitness goals by creating customized exercise routines that help develop the mind-body connection, improve core strength, achieve greater flexibility and improve posture."
}

const SARAH_BROOKS_PILATES = {
  name: "Studio House Pilates",
  shortname: "studiohousepilates",
  wistia_video_id: "utr2atg72m",
  thumbnail_url: "https://embedwistia-a.akamaihd.net/deliveries/809c1436cd815f85a48bc2aedcffb646.jpg?image_play_button_size=2x&amp;image_crop_resized=960x540&amp;image_play_button=1&amp;image_play_button_color=89cebbe0",
  summary: "Studio House Pilates is a boutique Pilates studio located in the heart of Chelsea, NYC. Specializing in group reformer classes, private sessions, and duets using the fundamentals of the Pilates method to create signature full-body workouts."
}

const YOGA_STUDIOS = [
  YOGA_VIDA,
  PLAYLIST_YOGA,
  THE_SPACE_BETWEEN,
  WOOM_CENTER,
  THE_STUDIO,
  MAMA_SOUL
];

const PILATES_STUDIOS = [
  BK_PILATES,
  BODYROK,
  MPOWER_PILATES_AND_CYCLE,
  SARAH_BROOKS_PILATES,
  MNTSTUDIO
];

const HIIT_STUDIOS = [
  SWITCH_PLAYGROUND,
  FHITTING_ROOM,
  SOLACE,
  WILLYB,
  SALT_SF,
  BODY_SPACE_FITNESS
];

const STRENGTH_STUDIOS = [
  ROWGATTA,
  BODY_SPACE_FITNESS,
  NATURALLY_CUT_FITNESS,
  WILLYB,
  THE_PERFORMANCE_LAB
];

const CARDIO_STUDIOS = [
  JOYRIDE,
  MODELFIT,
  PUNCH_PEDAL_HOUSE,
  INTENSATI,
  MASTERSKYA
];

const BOXING_STUDIOS = [
  BOX_AND_FLOW,
  HIT_HOUSE,
  MASTERSKYA
];

const SCULPT_STUDIOS = [
  MODELFIT,
  THE_SPACE_BETWEEN,
  MNTSTUDIO,
  SALT_SF,
  THE_BARI_STUDIO,
  BODYROK
]

const MOBILITY_AND_MORE_STUDIOS = [
  STRETCHED,
  MINDFRESH,
  ROOTS_66_FIT
]

const YOGA = 'Yoga';
const PILATES = 'Pilates';
const HIIT = 'HIIT';
const STRENGTH = 'Strength';
const CARDIO = 'Cardio';
const BOXING = 'Boxing';
const SCULPT = 'Sculpt';
const MOBILITY_AND_MORE = 'Mobility & More';

const ROW_1 = [YOGA, PILATES, HIIT, STRENGTH];
const ROW_2 = [CARDIO, BOXING, SCULPT, MOBILITY_AND_MORE];


const STUDIOS = {
  [YOGA]: YOGA_STUDIOS,
  [PILATES]: PILATES_STUDIOS,
  [HIIT]: HIIT_STUDIOS,
  [STRENGTH]: STRENGTH_STUDIOS,
  [CARDIO]: CARDIO_STUDIOS,
  [BOXING]: BOXING_STUDIOS,
  [SCULPT]: SCULPT_STUDIOS,
  [MOBILITY_AND_MORE]: MOBILITY_AND_MORE_STUDIOS
}

export default class Explore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: SCULPT,
      isVideoModalOpen: false,
      wistiaVideoId: null
    }
  }

  loadVideo(wistiaVideoId) {
    let scriptElement = document.createElement("script");
    scriptElement.src = `https://fast.wistia.com/embed/medias/${wistiaVideoId}.jsonp`;
    scriptElement.async = true;
    document.body.appendChild(scriptElement);
  }

  onClickCategory(item) {
    this.setState({
      selected: item
    })
  }

  onSelectCategory(event) {
    let { value } = event.target;
    this.setState({
      selected: value
    })
  }

  closeModal() {
    this.setState({
      isVideoModalOpen: false
    })
  }

  onClickTile(wistiaVideoId, event) {
    console.log("wistiaVideoId, event", wistiaVideoId, event)
    let that = this;
    this.setState({
      isVideoModalOpen: true,
      wistiaVideoId: wistiaVideoId
    }, ()=> {
      that.loadVideo(wistiaVideoId)
    })
  }

  render() {
    let { studios } = this.props;
    if (!studios) return null;

    let { selected, isVideoModalOpen } = this.state;
    let list = STUDIOS[selected];
    return (
      <div>

        <div className="js-home-explore-banner js-background-9AC8B9-50"/>
        <div className="js-home-explore-container d-flex align-items-center flex-column justify-content-center">
          <div className="js-font-semibold js-raleway js-font-black js-font-40 js-font-30-mb js-home-explore-header">Explore our best-in-class studios by category</div>
            <select
              onChange={this.onSelectCategory.bind(this)}
              className="js-background-9AC8B9 js-home-explore-select js-font-white js-avenir-next js-font-18-mb js-text-align-center">
              {
                [...ROW_1, ...ROW_2].map((item)=>{
                  return (
                    <option value={item}>
                      {item}
                    </option>
                  )
                })
              }
            </select>
            <div className="js-home-explore-button-rows">
              <div className="js-home-explore-button-row d-flex flex-row">
                {
                  ROW_1.map((item, index)=> {
                    return (
                      <Button
                        key={index}
                        color={(item === selected ? "green" : "white")}
                        onClick={this.onClickCategory.bind(this, item)}
                        label={item} />
                    )
                  })
                }
              </div>
              <div className="js-home-explore-button-row d-flex flex-row">
              {
                ROW_2.map((item, index)=> {
                  return (
                    <Button
                      key={index}
                      color={(item === selected ? "green" : "white")}
                      onClick={this.onClickCategory.bind(this, item)}
                      label={item} />
                  )
                })
              }
              </div>
            </div>
            <Swiper
              offsetOpacityRule={100}
              dotsClassName="js-home-explore-dots"
              offsetDivider="10"
              containerClassName="d-flex js-home-explore-studios justify-content-between align-items-start"
              viewport_width_dk={VIEWPORT_WIDTH_DK}
              viewport_width_mb={VIEWPORT_WIDTH_MB}
              offset_factor_dk={OFFSET_FACTOR_DK}
              offset_factor_mb={OFFSET_FACTOR_MB}
              item_width_dk={ITEM_WIDTH_DK}
              list={list}>
              {
                list.map((studio, index)=>{
                  let {
                    name,
                    wistia_video_id,
                    summary,
                    thumbnail_url,
                    shortname
                  } = studio;

                  return (
                    <Tile
                      shortname={shortname}
                      thumbnailUrl={thumbnail_url}
                      onClick={this.onClickTile.bind(this, wistia_video_id)}
                      key={index}
                      studioName={name}
                      studioDescription={summary}
                      studioLinkSrc=""
                    />
                  )
                })
              }
            </Swiper>
            {/*}<Dots
              items={["A", "B", "C", "D", "E"]}
              onClick={()=>{}}
              className="js-home-explore-dots" />*/}
            <a
              className="js-explore-all-studios-btn js-font-20 js-font-14-mb js-avenir-next d-flex align-items-center justify-content-center js-background-1F3830 js-font-white js-explore-link"
              href="/studios">See All Studios</a>
        </div>
        <>
          <Modal className="js-explore-modal-background" isOpen={isVideoModalOpen}>
            <img onClick={this.closeModal.bind(this)} className="js-explore-close-icon" alt="Close icon" aria-label="Click to close modal" src={CloseIcon} />
            <div className="wistia_responsive_padding" style={{padding:'56.25% 0 0 0', position: 'relative'}}>
              <div className="js_wistia_responsive_wrapper wistia_responsive_wrapper">
                <div className={`wistia_embed wistia_async_${this.state.wistiaVideoId} videoFoam=true`} style={{height: '100%', position: 'relative', width: '100%'}}>
                  <div className="wistia_swatch" style={{height: '100%', left: '0',opacity: '0', overflow: 'hidden', position: 'absolute', top:'0', transition: 'opacity 200ms', width:'100%'}}>
                    <img src={`https://fast.wistia.com/embed/medias/${this.state.wistiaVideoId}/swatch`} style={{filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt="" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      </div>

    )
  }
}
