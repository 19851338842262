import React from 'react'
import DayContainer from './DayContainer.js'

const ScheduleContainer = (props) => {
  const {
    workouts,
    onScheduleChange,
    user,
    calendarDefaultDate,
    setCalendarDefaultDate,
    whiteLabelBackgroundStyles,
  } = props
  const datesArr = Object.keys(workouts).sort()
  return (
    <div className="schedule-master">
      <div
        className="schedule-wrapper"
        style={
          whiteLabelBackgroundStyles.backgroundColor
            ? { borderColor: whiteLabelBackgroundStyles.backgroundColor }
            : {}
        }
      >
        {datesArr.map((day, idx) => {
          const filteredWorkouts = workouts[day].filter(
            (ele) => ele.video || ele.liveClass
          )
          return (
            <DayContainer
              {...props}
              calendarDefaultDate={calendarDefaultDate}
              setCalendarDefaultDate={setCalendarDefaultDate}
              key={idx}
              workouts={filteredWorkouts}
              date={day}
              onScheduleChange={onScheduleChange}
              user={user}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ScheduleContainer
