import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from 'react-redux';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop'
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import store from './views/store';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
registerServiceWorker();
