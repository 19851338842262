import React, { Component } from 'react';
import Navbar from './sections/navbar/navbar';
import Hero from './sections/hero/hero';
import Why from './sections/why/why';
import Explore from './sections/explore/explore';
import People from './sections/people/people';
import Social from './sections/social/social';
import Press from './sections/press/press';
/*import Footer from './sections/footer/footer';*/
import Footer from 'components/footer';
import './app.css';
import './text.css';

export default class Home extends Component {
  render() {
    let { studios, setLogo } = this.props;
    return (
      <div>
        <Navbar setLogo={setLogo.bind(this)}/>
        <Hero />
        <Why />
        <Explore studios={studios} />
        <People />
        <Social />
        <Press />
        <Footer />
      </div>
    )
  }
}
