/* eslint-disable class-methods-use-this */
import React from 'react'
import { Link } from 'react-router-dom'

import Media from 'react-media'
import NavBarOptions from './hamburgerNavBarOptions'

import MappedStudioList from './MappedStudioList'
import axios from 'axios'
import studios from '../utilities/studiolist'

import './navbar.css'

function LogButton(props) {
  if (props.isLoggedIn) {
    return (
      <Link to="/logout" className="Tabs-button">
        Log Out
      </Link>
    )
  }
  return (
    <Link to="/login" className="Tabs-button">
      Log In
    </Link>
  )
}

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      isToggled: false,
      width: window.outerWidth,
      hamburgerOptionsHidden: true,
      whiteLabel: studios.some((studio) =>
        window.location.pathname.includes(studio.link)
      ),
      studioInfoList: [],
    }
    this.showHamburgerNavBarOptions = this.showHamburgerNavBarOptions.bind(this)
  }

  async componentDidMount() {
    const { data } = await axios.get('/api/basic/webStudiosInfo')

    const pathname = window.location.pathname
    const studio = pathname.split('/')[pathname.split('/').length - 1]
    if (!this.props.whiteLabelDomain) {
      if (this.state.whiteLabel) {
        const { data } = await axios.get(`/api/studio/logos/${studio}`)
        this.props.setLogo(data.content.logoUrl)
      } else {
        this.props.setLogo()
      }
    }
    window.addEventListener('resize', this.handleResize.bind(this))

    this.setState({ ...this.state, studioInfoList: [...data.content] })
  }

  componentWillUnmount() {
    window.addEventListener('resize', null)
  }

  handleResize(WindowSize, event) {
    this.setState({ hamburgerOptionsHidden: true })
  }

  showHamburgerNavBarOptions() {
    if (this.state.hamburgerOptionsHidden) {
      this.setState({ hamburgerOptionsHidden: false })
    } else {
      this.setState({ hamburgerOptionsHidden: true })
    }
  }

  studios() {
    return (
      <li className="dropDown">
        <div className="studios-dropDown-container">
          <Link to="/studios" className="dropDownButton">
            Studios
          </Link>
          <ul className="dropDown-content">
            <MappedStudioList studioInfoList={this.state.studioInfoList} />
          </ul>
        </div>
      </li>
    )
  }

  getNavigation() {
    if (this.props.whiteLabelDomain) return null
    return (
      <>
        {this.studios()}
        <li className="tabs-button">
          <Link to="/how-it-works">How it Works</Link>
        </li>
        <li className="tabs-button">
          <a href="https://www.blog.jetsweatfitness.com"> Jet Cetera </a>
        </li>
        <li className="tabs-button">
          <a href="https://www.blog.jetsweatfitness.com/new-products/">
            {' '}
            Gear{' '}
          </a>
        </li>
      </>
    )
  }

  getLogoLink() {
    if (this.props.whiteLabel) {
      return (
        <Link
          className="Button-hover"
          to="/"
          onClick={() => {
            this.props.setLogo()
            this.setState({
              hamburgerOptionsHidden: true,
              whiteLabel: false,
            })
          }}
        >
          <img
            src={this.props.studio.logoUrl}
            className={'white-label-logo'}
            alt={this.props.studio.name}
          />
        </Link>
      )
    }

    return (
      <Link
        className="Button-hover"
        to="/"
        onClick={() => {
          this.props.setLogo()
          this.setState({
            hamburgerOptionsHidden: true,
            whiteLabel: false,
          })
        }}
      >
        <img
          src={this.props.navBarLogo}
          className={'white-label-logo'}
          alt="JETSWEAT"
        />
      </Link>
    )
  }

  render() {
    let { whiteLabelBackgroundStyles } = this.props
    console.log(
      '-----------------------------------------------------------',
      this.props,
      '-----------------------------------------------------------'
    )
    return (
      <div>
        <header className="Navbar">
          {this.getLogoLink()}

          <ul className="Tabs-container">
            {/* <li className="Tabs"><Link to="studios" className="Tabs-button">Studios</Link></li> */}
            {this.props.isLoggedIn && (
              <li className="tabs-button">
                <Link
                  to="/newplatform/dashboard"
                  className="Tabs-button Tabs-button--outline"
                >
                  Dashboard
                </Link>
              </li>
            )}
            {this.getNavigation()}
            <li className="tabs-button">
              <LogButton isLoggedIn={this.props.isLoggedIn} />
            </li>
            <li>
              <Link
                to={`/signup`}
                className="try-for-free-button"
                style={{ color: 'white', ...whiteLabelBackgroundStyles }}
              >
                Start Your Free Trial
              </Link>
            </li>

            {/* <li className="Tabs"><Link to="subscription" className="Tabs-button">Subscribe</Link></li> */}
            <Media query="(max-width: 800px)">
              {(matches) =>
                matches ? (
                  <span className="open-slide">
                    <a href="#">
                      <svg
                        width="30"
                        height="30"
                        onClick={this.showHamburgerNavBarOptions}
                      >
                        <path d="M0,5 30,5" stroke="#2C2C2C" strokeWidth="5" />
                        <path
                          d="M0,14 30,14"
                          stroke="#2C2C2C"
                          strokeWidth="5"
                        />
                        <path
                          d="M0,23 30,23"
                          stroke="#2C2C2C"
                          strokeWidth="5"
                        />
                      </svg>
                    </a>
                  </span>
                ) : (
                  <p />
                )
              }
            </Media>
          </ul>
        </header>

        <NavBarOptions
          studioInfoList={this.state.studioInfoList}
          hidden={this.state.hamburgerOptionsHidden}
          showHamburgerNavBarOptions={this.showHamburgerNavBarOptions}
        />
      </div>
    )
  }
}
export default Navbar
