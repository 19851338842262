import React, { Component } from 'react';
import NavLink from './components/navlink.js'
import Link from 'screens/home/components/link';
import Logo from './assets/logo@3x.png';
import axios from "axios";
import NavBarOptions from "components/hamburgerNavBarOptions";
import './navbar.css';
import {
  STUDIOS,
  HOW_IT_WORKS,
  BLOG,
  GEAR,
  LOG_IN,
  HOME
} from 'constants/routes';

export default class Navbar extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisibe: false
    }
  }

  onClickToggleHamburger() {
    this.setState({
      isMenuVisibe: !this.state.isMenuVisibe
    })
  }


  getMenu() {
    if (!this.state.isMenuVisibe) return null;
    return (
      <div className="d-flex flex-column js-navbar-menu-mb">
        <a href="/studios">STUDIOS</a>
        <a href="/signup">START YOUR FREE TRIAL</a>
        <a href="/how-it-works">HOW IT WORKS</a>
        <a target="_blank" rel="noopener noreferrer" href="https://blog.jetsweatfitness.com">BLOG</a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.blog.jetsweatfitness.com/new-products/">GEAR</a>
        <a href="/login">LOGIN</a>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className="js-navbar-container d-flex align-items-center justify-content-between">
          <a href={HOME}>
            <img src={Logo} className="js-navbar-logo" />
          </a>
          <div className="d-flex js-navbar-links">
            <NavLink linkHref={STUDIOS} label="Studios" />
            <NavLink linkHref={HOW_IT_WORKS} label="How It Works" />
            <NavLink linkHref={BLOG} label="Jet Cetera" />
            <NavLink linkHref={GEAR} label="Gear" />
            <NavLink linkHref={LOG_IN} label="Log In" />
          </div>
          <Link
            label="Start your free trial"
            href="/signup"
          />
        </div>
        <div className="js-navbar-container-mb js-navbar-mb">
          <div className="d-flex justify-content-between align-items-center">
            <a href={HOME}>
              <img src={Logo} className="js-navbar-logo" />
            </a>
            <div className="d-flex align-items-center">
              <Link
                className="js-navbar-signup-btn-mb"
                label="Start your free trial"
                color="light-green"
                href="/signup"
              />
              <svg
                onClick={this.onClickToggleHamburger.bind(this)}
                width="30"
                height="30"
                className="js-navbar-hamburger"
              >
                <path
                  d="M0,5 30,5"
                  stroke="#2C2C2C"
                  strokeWidth="5"
                />
                <path
                  d="M0,14 30,14"
                  stroke="#2C2C2C"
                  strokeWidth="5"
                />
                <path
                  d="M0,23 30,23"
                  stroke="#2C2C2C"
                  strokeWidth="5"
                />
              </svg>
            </div>
          </div>
          {this.getMenu()}
        </div>

      </>
    )
  }
}
