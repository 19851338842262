const studios = [
  {
    name: 'The Bari Studio',
    link: 'baristudio'
  },
  {
    name: 'BK Pilates',
    link: 'bkpilates'
  },
  {
    name: 'BodyRok',
    link: 'bodyrok'
  },
  {
    name: 'Body Space Fitness',
    link: 'bodyspace'
  },
  {
    name: 'Box + Flow',
    link: 'boxandflow'
  },
  {
    name: 'Chloe Kernaghan Yoga',
    link: 'chloekernaghanyoga'
  },
  {
    name: 'Core Collective',
    link: 'corecollective'
  },
  {
    name: 'The Dailey Method',
    link: 'daileymethod'
  },
  {
    name: 'Fhitting Room',
    link: 'fhittingroom'
  },
  {
    name: 'Hit House NYC',
    link: 'hithouse'
  },
  {
    name: 'Intensati',
    link: 'intensati'
  },
  {
    name: 'JoyRide',
    link: 'joyride'
  },
  {
    name: 'MamaSoul',
    link: 'mamasoul'
  },
  {
    name: 'Masterskya',
    link: 'masterskya'
  },
  {
    name: 'MindFresh',
    link: 'mindfresh'
  },
  {
    name: 'MNT Studio',
    link: 'mntstudio'
  },
  {
    name: 'ModelFIT',
    link: 'modelfit'
  },
  {
    name: 'Naturally Cut Fitness',
    link: 'naturallycutfitness'
  },
  {
    name: 'PLAYLIST Yoga',
    link: 'playlistyoga'
  },
  {
    name: 'Punch Pedal House',
    link: 'punchpedalhouse',
    shortName: 'punchpedalhouse',
    logoUrl: 'https://jetsweat.s3.amazonaws.com/photos/2e097c0c.png',
    isOnWhiteLabel: true,
    photoUrls: ["https://jetsweat.s3.amazonaws.com/photos/66958651.png", "https://jetsweat.s3.amazonaws.com/photos/a6f2e1a4.png", "https://jetsweat.s3.amazonaws.com/photos/27de0ee8.png"]
  },
  {
    name: 'Rowgatta',
    link: 'rowgatta'
  },
  {
    name: 'SALT SF',
    link: 'saltsf'
  },
  {
    name: 'Solace NYC',
    link: 'solace'
  },
  {
    name: 'Stretched',
    link: 'stretched'
  },
  {
    name: 'Switch Playground',
    link: 'switchplayground'
  },
  {
    name: 'The Space Between',
    link: 'thespacebetween'
  },
  {
    name: 'The Studio',
    link: 'thestudio'
  },
  {
    name: 'WillyB CrossFit',
    link: 'willybcrossfit'
  },
  {
    name: 'Woom Center',
    link: 'woomcenter'
  },
  {
    name: 'The Wright Fit',
    link: 'wrightfit'
  },
  {
    name: 'Yoga Vida',
    link: 'yogavida'
  }
];

export default studios;
