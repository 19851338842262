import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Calendar, Tile } from 'react-calendar'
import './Calendar.css'
import Slider from 'react-slick'
import SideBarItemContainer from '../New_Platform/SidebarItemContainer'
import CustomButton from './CustomButton'
import ScheduleContainer from './ScheduleContainer'
import WorkoutSlider from './WorkoutSlider'
import * as CalendarUtil from './CalendarUtil'
import { connect } from 'react-redux'

const moment = require('moment')

class WorkoutCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: this.props.calendarDefaultDate,
      workouts: [],
      workoutsInCurrentMonth: [],
      start: moment().add(1, 'M'),
    }
    this.buttons = []

    this.onChange = this.onChange.bind(this)
    this.onActiveDateChange = this.onActiveDateChange.bind(this)
    this.onScheduleChange = this.onScheduleChange.bind(this)
  }

  onChange(date) {
    this.setState({ date })
  }

  onActiveDateChange({ activeStartDate, view }) {
    const inRange = CalendarUtil.workoutsInRange(
      this.state.workouts,
      activeStartDate
    )
    this.setState({ workoutsInCurrentMonth: inRange })
  }

  componentDidMount() {
    // TO DO: change this function so API request is only for one month of workout data; logic of mapping entire workout history to state will take too long.
    this.fetchWorkouts()
  }

  onScheduleChange() {
    this.fetchWorkouts()
  }

  fetchWorkouts(dateSnapshot = new Date()) {
    fetch('/api/workout/myWorkout', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: this.props.user.authToken,
      },
      body: JSON.stringify({
        fromDate: '2000-01-01',
        toDate: moment().add(1, 'year').format('YYYY-MM-DD'),
        when: new Date(Date.now()),
        authUserInfo: { id: this.props.user.id },
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        CalendarUtil.updateWorkoutStatus(
          res.content.workouts,
          this.props.user.authToken
        )
      )
      .then((workouts) => {
        const dateHash = {}
        workouts.forEach((workout) => {
          const date = moment(workout.when).format('MM-DD-YYYY')
          if (!dateHash[date]) {
            dateHash[date] = [workout]
          } else {
            dateHash[date].push(workout)
          }
        })

        this.setState({ workouts: dateHash, date: dateSnapshot }, () => {
          const inRange = CalendarUtil.workoutsInRange(
            this.state.workouts,
            this.props.calendarDefaultDate
          )
          const sortedInRange = {}
          for (let date in inRange) {
            sortedInRange[date] = inRange[date].sort(
              (a, b) => moment(a.when).unix() - moment(b.when).unix()
            )
            this.buttons.push(React.createRef())
          }
          this.setState({ workoutsInCurrentMonth: sortedInRange })
        })
        return workouts
      })
  }

  render() {
    const currDate = moment(this.state.date).format('MM-DD-YYYY')
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    const { whiteLabelBackgroundStyles } = this.props
    return (
      <SideBarItemContainer
        grid="True"
        defaultClassName="calendar-grid-item"
        className="calendar-grid-container"
      >
        <div className="container-content">
          <div className="calendar-container">
            <Calendar
              {...this.props}
              onChange={this.onChange}
              activeStartDate={this.props.calendarDefaultDate._d}
              value={this.props.calendarDefaultDate._d}
              showNeighboringMonth={false}
              minDetail="month"
              onActiveDateChange={this.onActiveDateChange}
              tileContent={({ date }) => {
                date = moment(date).format('MM-DD-YYYY')
                return (
                  <CustomButton
                    {...this.props}
                    date={date}
                    workouts={
                      this.state.workouts[date]
                        ? this.state.workouts[date]
                        : [{}]
                    }
                  />
                )
              }}
              tileClassName={({ date }) => {
                date = moment(date).format('MM-DD-YYYY')
                if (this.state.workouts[date]) {
                  return this.state.workouts[date][0].status
                }
              }}
            />

            {this.state.workoutsInCurrentMonth[currDate] === undefined ? (
              <div id="new-class-schedule">
                <p>Ready for a new class?</p>
                <Link
                  style={this.props.whiteLabelBackgroundStyles}
                  to="/newplatform/items/classes"
                  className="schedule"
                >
                  Schedule
                </Link>
              </div>
            ) : (
              <Slider
                {...settings}
                // appendDots={() => (
                //   <ul className="slick-dots" style={{ display: 'block' }}>
                //     {this.state.workoutsInCurrentMonth[currDate].map(
                //       (wkt, idx) => (
                //         <li class={!idx && 'slick-active'}>
                //           <button style={whiteLabelBackgroundStyles}>
                //             {idx}
                //           </button>
                //         </li>
                //       )
                //     )}
                //   </ul>
                // )}
              >
                {this.state.workoutsInCurrentMonth[currDate].map((wkt) => (
                  <div>
                    <WorkoutSlider {...this.props} wkt={wkt} />
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <div className="monthly-totals">
            <div>
              <div
                className="status scheduled"
                style={{
                  borderColor: this.props.whiteLabelBackgroundStyles
                    .backgroundColor,
                }}
              />
              Scheduled:{' '}
              {CalendarUtil.getTotalWorkouts(
                this.state.workoutsInCurrentMonth,
                'scheduled'
              )}{' '}
            </div>
            <div>
              <div className="status skipped" />
              Skipped:{' '}
              {CalendarUtil.getTotalWorkouts(
                this.state.workoutsInCurrentMonth,
                'skipped'
              )}
            </div>
            <div>
              <div
                className="status completed"
                style={{
                  ...this.props.whiteLabelBackgroundStyles,
                  borderColor: this.props.whiteLabelBackgroundStyles
                    .backgroundColor,
                }}
              />
              Completed:{' '}
              {CalendarUtil.getTotalWorkouts(
                this.state.workoutsInCurrentMonth,
                'completed'
              )}
            </div>
          </div>
        </div>
        <div>
          {this.state.workoutsInCurrentMonth && (
            <ScheduleContainer
              {...this.props}
              calendarDefaultDate={this.props.calendarDefaultDate}
              setCalendarDefaultDate={this.props.setCalendarDefaultDate}
              workouts={this.state.workoutsInCurrentMonth}
              onScheduleChange={this.onScheduleChange}
              user={this.props.user}
            />
          )}
        </div>
      </SideBarItemContainer>
    )
  }
}

const mapStateToProps = () => {}

export default connect(mapStateToProps)(WorkoutCalendar)
