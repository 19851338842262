import React from 'react'
import { withRouter } from 'react-router-dom'
import '../views/New_Platform/Live/live.css'
import '../components/footer.css'
import JetsweatModal from '../components/JetsweatModal'
import Footer from '../components/footer'
import { EmailWarning } from '../components/account/formHelpers'

class JetsweatLive extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: true,
      validEmail: true,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
  }

  async componentDidMount() {
    const CONTENT_ID = '160160_c_533593'
    const dacastPlayer = await window.dacast(CONTENT_ID, 'dacast-player', {
      width: '100%',
      height: 500,
    })
    // dacastPlayer.onPause(() => console.log("hello"))
  }
  validateEmail(e) {
    e.preventDefault()
    const email = document.getElementById('MERGE0-2').value
    if (!email.includes('@')) {
      this.setState({ validEmail: false }, this.handleSubmit)
    } else {
      this.setState({ validEmail: true }, this.handleSubmit)
    }
  }
  handleSubmit() {
    if (this.state.validEmail) {
      this.setState({ modalOpen: false })
      document.getElementById('mc-embedded-subscribe-form-2').submit()
    }
  }

  render() {
    return (
      <>
        <JetsweatModal
          {...this.props}
          showModal={this.state.modalOpen}
          closeModal={() => this.setState({ modalOpen: false })}
          title={'Access complimentary live classes!'}
          preventCloseModal
        >
          <EmailWarning warn={!this.state.validEmail} />
          <iframe
            name="hiddenFrame"
            width="0"
            height="0"
            border="0"
            style={{ display: 'none' }}
          ></iframe>
          <form
            action="https://jetsweatapp.us15.list-manage.com/subscribe/post?u=e4e72a779acceb6ff0e5299e3&amp;id=5a674adfc1"
            method="post"
            id="mc-embedded-subscribe-form-2"
            name="mc-embedded-subscribe-form"
            target="hiddenFrame"
            className="jetsweat-livestream-email-form"
            noValidate
          >
            <div className="mc-field-group" style={{ marginTop: 10 }}>
              <input
                type="email"
                className="email-input-livestream-field"
                autoCapitalize="off"
                autoCorrect="off"
                name="MERGE0"
                id="MERGE0-2"
                size="25"
                placeholder="Enter email address"
              />
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              />
            </div>
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_e4e72a779acceb6ff0e5299e3_5a674adfc1"
                tabIndex="-1"
              />
            </div>

            <input
              type="submit"
              style={{
                padding: '10px',
                borderRadius: '2px',
                marginTop: '5px',
                color: 'white',
                backgroundColor: '#9AC8B9',
                border: 'none',
              }}
              onClick={this.validateEmail}
              value="Submit"
            />
          </form>
        </JetsweatModal>
        <div style={{ marginTop: 100 }}>
          <div id="dacast-player" />
          <div className="jetsweat-live-channel-container">
            <h1
              style={{
                fontWeight: 'bold',
                fontSize: 40,
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              Jetsweat Live Channel
            </h1>
            <h2 style={{ fontSize: 25, fontWeight: 'bolder' }}>
              Jetsweat Team
            </h2>
            <div className="pure-g">
              <div className="pure-u-1-3">
                <img
                  src="assets/img/icons/time.svg"
                  height="13"
                  className="icon"
                  alt="Duration"
                />
                60 Mins
              </div>
              {/* <div className="pure-u-1-3">
                <img
                  src="/assets/img/icons/fire-2.svg"
                  height="13"
                  className="icon"
                  alt="Calories"
                />
                {5} - {5} Cal
              </div> */}
              {/* <div className="pure-u-1-3">
                <img
                  src="/assets/img/icons/shoe.svg"
                  height="13"
                  className="icon"
                  alt="Equipment"
                />
                {5}
              </div> */}
            </div>
            <p style={{ marginLeft: 0 }}>
              Stay tuned for future live streams soon!
            </p>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default withRouter(JetsweatLive)
