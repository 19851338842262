import React, { Component } from "react"
import MobileSlider from "./mobileSlider"
import "./exploreStudio.css"

export const Studios = [
  <img
    alt="bari studios"
    src={require("../../images/slider_logo/studiologo/stream Bari video classes.svg")}
    // className="explore-studios-bari "
    className="explore-studios-bari2"
  />,
  <img
    alt="bkpilates"
    src={require("../../images/slider_logo/studiologo/stream_bkpilates_video_classes.svg")}
    className="explore-studios-bkpilates2"
  />,
  <img
    alt="intensati"
    src={require("../../images/slider_logo/studiologo/stream_intensati_video_classes.svg")}
    className="explore-studios-intensati2"
  />,
  <img
    alt="mindfresh"
    src={require("../../images/slider_logo/studiologo/stream_Mindfresh_video_classes.svg")}
    className="explore-studios-mindfresh2"
  />,
  <img
    alt="masterskya"
    src={require("../../images/slider_logo/studiologo/stream_masterskya_video_classes.svg")}
    className="explore-studios-masterskya2"
  />,
  <img
    alt="modelfit"
    src={require("../../images/slider_logo/studiologo/stream_modelFit_video_classes.svg")}
    className="explore-studios-modelfit2"
  />,
  // <img
  //   alt="skyting"
  //   src={require("../../images/slider_logo/studiologo/stream Sky Ting video classes.svg")}
  //   className="explore-studios-skyting2"
  // />,
  <img
    alt="solace"
    src={require("../../images/slider_logo/studiologo/stream_SOLACE_video_classes.svg")}
    className="explore-studios-solace2"
  />,
  <img
    alt="switch"
    src={require("../../images/slider_logo/studiologo/stream_switch_video_classes.svg")}
    className="explore-studios-switch2"
  />,
  <img
    alt="trooper"
    src={require("../../images/slider_logo/studiologo/stream_trooper_video_classes.svg")}
    className="explore-studios-trooper2"
  />,
  <img
    alt="woom"
    src={require("../../images/slider_logo/studiologo/stream_woom_video_classes.svg")}
    className="explore-studios-woom2"
  />,
  <img
    alt="xtendbarre"
    src={require("../../images/slider_logo/studiologo/stream_xtendbarre_video_classes.svg")}
    className="explore-studios-xtendbarre2"
  />,
  <img
    alt="twf"
    src={require("../../images/slider_logo/studiologo/stream_twf_video_classes.svg")}
    className="explore-studios-twf2"
  />,
  <img
    alt="bodyrok"
    src={require("../../images/slider_logo/studiologo/stream_bodyrok_video_classes.svg")}
    className="explore-studios-bodyrok2"
  />,
  <img
    alt="body space"
    src={require("../../images/slider_logo/studiologo/stream_body-space-fitness_video_classes.svg")}
    className="explore-studios-body-space2"
  />,
  <img
    alt="the studio"
    src={require("../../images/slider_logo/studiologo/the_studio.svg")}
    className="explore-studios-the-studio2"
  />,
  <img
    alt="hit house"
    src={require("../../images/slider_logo/studiologo/hit_house.svg")}
    className="explore-studios-hit-house2"
  />,
  <img
    alt="mnt"
    src={require("../../images/slider_logo/studiologo/MNT.svg")}
    className="explore-studios-MNT2"
  />,
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/dailey_method.svg")}
    className="explore-studios-dtm2"
  />,
  // mama
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/mama_soul_logo.svg")}
    className="explore-studios-mama"
  />,
  // Playlist
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/playlist_yoga.svg")}
    className="explore-studios-dtm2"
  />,
  // PUnch pedal
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/punch_pedal.svg")}
    className="explore-studios-dtm2"
  />,
  // Rowgatta
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/rowgatta_studio.svg")}
    className="explore-studios-dtm2"
  />,
  // SALT
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/salt_black.svg")}
    className="explore-studios-salt-black"
  />,
  // Stretched
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/stretched_black.svg")}
    className="explore-studios-dtm2"
  />,
  // Space-between
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/space_between.svg")}
    className="explore-studios-dtm2"
  />,
  // WillyB
  <img
    alt="dtm"
    src={require("../../images/slider_logo/studiologo/willy_b.svg")}
    className="explore-studios-dtm2"
  />,
]

export const studiosUrl = {
  0: "9ay1ukt6sk",
  1: "p3onts20es",
  2: "upngmhgt3u",
  3: "5fqxm9t6cp",
  4: "nluskuxb8x",
  5: "wcrspa0ver",
  // 6: "68f3uvq1sf",
  6: "15x548f4cs",
  7: "ubeq6x6a9h",
  8: "eb3124covs",
  9: "nvs3j4j835",
  10: "ziv5ngq5w7",
  11: "hgszjqk5r9",
  12: "3qjy3172o8",
  13: "mcdpu0sulp",
  14: "3g1tooa2yo",
  15: "gbu205fy08",
  16: "e793k1bm4q",
  17: "psrrjcd95e",
  18: "1ym9x8qrnk",
  19: "onn6xbomva",
  20: "39n74f29ec",
  21: "qgaz89il8m",
  22: "jx3q31o0gs",
  23: "w1rh057opr",
  24: "5qvqiyiqb8",
  25: "sweyt6jqgq",
}
class ExploreStudio extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startIndex: 0,
      endIndex: 5,
      activeStudioImage: studiosUrl[0],
      activeIndex: 0,
      activeBackground: "white",
      dropDownVisible: false,
    }
    this.selectStudio = this.selectStudio.bind(this)
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.changeDropDownVisible = this.changeDropDownVisible.bind(this)
    // this.studioCarousel = setInterval(
    //   () => {
    //   let newIndex = 0;
    //   if (this.state.activeIndex < Studios.length - 1) {
    //     newIndex = this.state.activeIndex + 1;
    //   }
    //   this.setState({
    //     activeIndex: newIndex,
    //     activeStudioImage: this.studiosUrl[newIndex],
    //   }, () => {this.video.src = this.studiosUrl[newIndex]}
    // )}
    // , 1000);
  }

  selectStudio(index) {
    this.setState(
      {
        activeStudioImage: studiosUrl[index],
        activeIndex: index,
      },
      () =>
        (this.video.src = `https://fast.wistia.net/embed/iframe/${this.state.activeStudioImage}`)
    )
  }

  next() {
    if (this.state.endIndex < Studios.length - 1) {
      this.setState({
        startIndex: this.state.startIndex + 6,
        endIndex: this.state.endIndex + 6,
      })
    }
  }

  prev() {
    if (this.state.startIndex > 0) {
      this.setState({
        startIndex: this.state.startIndex - 6,
        endIndex: this.state.endIndex - 6,
      })
    }
  }

  changeDropDownVisible() {
    if (this.state.dropDownVisible) {
      this.setState({ dropDownVisible: false })
    } else {
      this.setState({ dropDownVisible: true })
    }
  }

  render() {
    const slide = Studios.map((img, idx) => {
      let id = "inactive-logo"
      let style = {}
      if (idx === this.state.activeIndex) {
        style = {
          backgroundColor: this.state.activeBackground,
          boxShadow: "0px 1px 14px 0px black",
        }
        id = "active-logo"
      }

      return (
        <li
          id={id}
          className="explore-studio-logo"
          style={style}
          onClick={() => this.selectStudio(idx)}
        >
          {img}
        </li>
      )
    })
    const activeSlide = slide.filter(
      (img, idx) => idx >= this.state.startIndex && idx <= this.state.endIndex
    )
    let visible = "hidden"
    if (this.state.dropDownVisible) {
      visible = "visible"
    }
    return (
      <div className="explore-studio-container">
        <div>
          <h1>Explore Our Studios</h1>

          <span />
        </div>

        <div className="activeStudioContainer">
          <div
            className="wistia_responsive_padding"
            style={{
              position: "relative",
              padding: "0 0 0 0",
              width: "130vw",
              height: "58vw",
              maxWidth: "1000px",
              maxHeight: "550px",
            }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                // left: '0',
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <iframe
                src={`https://fast.wistia.net/embed/iframe/${this.state.activeStudioImage}?videoFoam=true`}
                title="Wistia video player"
                allowTransparency="true"
                frameBorder="0"
                scrolling="no"
                className="wistia_embed"
                name="wistia_embed"
                allowFullScreen
                mozallowfullscreen
                webkitallowfullscreen
                oallowfullscreen
                msallowfullscreen
                width="100%"
                height="100%"
                ref={(video) => (this.video = video)}
              />
            </div>
          </div>
          <script src="https://fast.wistia.net/assets/external/E-v1.js" async />
        </div>

        <MobileSlider
          selectStudio={this.selectStudio}
          idx={this.state.activeIndex}
        />
        <div>
          <div className="slider">
            <i className="arrow left" onClick={this.prev} />
            <ul className="explore-studio-slider">{activeSlide}</ul>
            <i className="arrow right" onClick={this.next} />
          </div>
        </div>
      </div>
    )
  }
}

export default ExploreStudio
