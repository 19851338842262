import React, { Component } from "react";
import branch from "branch-sdk";
import { Link, Redirect } from "react-router-dom";
import { EmailWarning, PasswordWarning } from "../account/formHelpers";
import "../account/signup.css";
import Footer from "../footer";

class GymPassSignup extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password_confirm: "",
      email_warning: false,
      password_warning: false,
      isSubmitting: false,
      error: "",
    };
  }

  handlePasswordChange = (event) => {
    this.handleChange(event);
    if (event.target.value === this.state.password) {
      this.setState({ password_warning: false });
    } else {
      this.setState({ password_warning: true });
    }
  };

  handleEmailChange = (event) => {
    this.handleChange(event);
    if (this.state.email_warning && event.target.value.includes("@")) {
      this.setState({ email_warning: false });
    }
  };

  handleChange = (event) => {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  };

  componentDidMount = () => {
    const firstName = new URLSearchParams(window.location.search).get(
      "firstname"
    );
    const lastName = new URLSearchParams(window.location.search).get(
      "lastname"
    );
    let name;
    if (firstName && lastName) {
      name =
        firstName[0].toUpperCase() +
        firstName.slice(1).toLowerCase() +
        " " +
        lastName[0].toUpperCase() +
        lastName.slice(1).toLowerCase();
    }
    this.setState({
      email: new URLSearchParams(window.location.search).get("email"),
      name,
    });
  };

  validateEmail = (event) => {
    const email = event.target.value;
    if (!email.includes("@")) {
      this.setState({ email_warning: true });
    } else {
      this.setState({ email_warning: false });
    }

    // Track user on branch by email id
    branch.setIdentity(email);
  };

  passwordMatch = (event) => {
    if (event.target.value !== this.state.password) {
      this.setState({ password_warning: true });
    } else {
      this.setState({ password_warning: false });
    }
  };

  registerUser = (event) => {
    event.preventDefault();
    const jwt = new URLSearchParams(window.location.search).get("token");
    this.setState({ isSubmitting: true }, () => {
      if (this.state.password !== this.state.password_confirm) {
        return this.setState({ password_warning: true, isSubmitting: false });
      }
      if (this.state.email_warning) {
        return this.setState({ isSubmitting: false });
      }
      return fetch("/api/user/register/gympass", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userInfo: {
            email: this.state.email,
            password: this.state.password,
            firstName: this.state.name.split(" ")[0],
            lastName: this.state.name.split(" ")[1],
          },
          token: jwt,
        }),
      })
        .then((res) => res.json())
        .then((body) => {
          if (!body.status) {
            return this.setState({ error: body.content, isSubmitting: false });
          }
          branch.track("user-signup", {});
          return this.props.loginFromToken(body.content.authToken);
        })
        .catch((err) => {
          console.error(err);
          return this.setState({
            error: "Unknown error. Please try later.",
            isSubmitting: false,
          });
        });
    });
  };

  render() {
    let { currUser } = this.props;
    const { isSubmitting } = this.state;
    // currUser = currUser ? currUser : {};
    // currUser.subscription = {};
    // currUser.subscription.active = true;

    if (
      !(
        new URLSearchParams(window.location.search).get("firstname") ||
        new URLSearchParams(window.location.search).get("lastname") ||
        new URLSearchParams(window.location.search).get("email") ||
        new URLSearchParams(window.location.search).get("token")
      )
    ) {
      return <Redirect push to="/" />;
    }

    if (currUser) {
      return <Redirect to="/account" />; // check to make sure this redirect works when account is made
    }

    const errDiv = this.state.error ? (
      <div className="Center Padding">
        <div className="errormessage">{this.state.error}</div>
      </div>
    ) : (
      ""
    );

    return (
      <div>
        <div className="Background-color-nav" />
        <div className="Center">
          <h1 className="Main-text"> Gympass Sign Up </h1>
          {errDiv}
        </div>
        <form onSubmit={this.registerUser}>
          <div className="Center">
            <p>Email: {this.state.email}</p>
          </div>
          <div className="Center">
            <p>Name: {this.state.name}</p>
          </div>
          <div className="Center Padding">
            <input
              id="signuppassword"
              type="password"
              required
              name="password"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Create Password"
            />
          </div>

          <div className="Center Padding">
            <input
              id="signuppasswordretype"
              type="password"
              required
              name="password_confirm"
              onFocus={this.passwordMatch}
              onChange={this.handlePasswordChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Confirm Password"
            />
            <PasswordWarning warn={this.state.password_warning} />
          </div>

          <div className="Center Padding">
            <button
              disabled={
                isSubmitting ||
                this.state.password_warning ||
                this.state.email_warning
              }
              type="submit"
              className="Button-style Font9 signup-button"
            >
              {" "}
              {isSubmitting ? "Loading..." : "SIGN UP"}{" "}
            </button>
          </div>
        </form>
        <p className="Terms-font Center Padding">
          By creating your account, you agree to Jetsweat's
          <Link className="Terms-font Terms-link" to="terms">
            {" "}
            Terms of Service and EULA{" "}
          </Link>
          and
          <Link to="privacypolicy" className="Terms-font Terms-link">
            {" "}
            Privacy Policy.
          </Link>
        </p>
        <p className="Center Padding Font">
          Already have an account?
          <Link className="Font9 Login-now" to="/login">
            Login here!{" "}
          </Link>
        </p>
        <div
          className="footer-signup"
          style={{ position: "fixed", bottom: "0" }}
        >
          <Footer />
        </div>
      </div>
    );
  }
}

export default GymPassSignup;
