import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function AuthenticatedRoute(props) {
  const { isLoading, subscriberOnly, redirectSubscriber, loader, location, currUser, path } = props;
  if (isLoading) {
    // A "loader" component can be displayed while loading user info.
    return <Route path={path} render={() => loader || <div />} />;
  } else if (!currUser) {
    return (
      <Route
        path={path}
        render={() => (
          <Redirect to={{
            pathname: '/login',
            state: { redirectFrom: location },
          }}
          />
        )}
      />
    );
  }
  const isSubscribed = currUser.subscription && currUser.subscription.active;
  if (subscriberOnly && !isSubscribed) {
    console.log('is NotSubbed')
    // For logged in users, redirect to payment if its a subscriber only route.
    return <Route path={path} render={() => <Redirect to="/payment" />} />;
  }
  if (redirectSubscriber && isSubscribed) {
    return <Route path={path} render={() => <Redirect to="/newplatform/dashboard" />} />;
  }
  return <Route {...props} />;
}
