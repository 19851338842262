import authFetch from "authFetch/authFetch"

/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */

const SAVE_FILTERED = "SAVE_FILTERED"
const RESET_FILTERED = "RESET_FILTERED"
const INITIAL_VIDEOSAVE = "INITIAL_VIDEOSAVE"
const DISPLAY_TIME_FILTERED = "DISPLAY_TIME_FILTERED"
const DISPLAY_BODY_SORTED = "DISPLAY_BODY_SORTED"
const VIDEO_REQUEST = "VIDEO_REQUEST"
const FAVORITE_VIDEO_SUCCESS = "FAVORITE_VIDEO_SUCCESS"
const VIDEO_FAILURE = "VIDEO_FAILURE"
const FAVORITE_AMOUNT = "FAVORITE_AMOUNT"
const SHOW_ALL = "SHOW_ALL"
const SHOW_BUTTONFILTERED = "SHOW_BUTTONFILTERED"

const GET_ALL_PROFILE_DATA = "GET_ALL_PROFILE_DATA"

const GET_VIDEO_INFO = "GET_VIDEO_INFO"
// getting MHR (could be for all video info) rate from individual video

const GET_DAILY_WORKOUT = "GET_DAILY_WORKOUT"

const GET_WEEKLY_DATA = "GET_WEEKLY_DATA"

const GET_WEEKLY_STATUS = "GET_WEEKLY_STATUS"

const GET_PROGRAMS = "GET_PROGRAMS"

const GET_CLASESS = "GET_CLASSES"

const GET_ALL_FAVORITES = "GET_ALL_FAVORITES"

const ADD_FAVORITE = "ADD_FAVORITE"

const GET_FAVORITE_CLASSES = "GET_FAVORITE_CLASSES"

const GET_HISTORY_CLASSES = "GET_HISTORY_CLASSES"

const GET_NO_EQUIPMENT_CLASSES = "GET_NO_EQUIPMENT_CLASSES"

export function fetchClasses(classes) {
  return {
    type: GET_CLASESS,
    classes,
  }
}

export function fetchNoEquipmentClasses(classes) {
  return {
    type: GET_NO_EQUIPMENT_CLASSES,
    classes,
  }
}
export function fetchFavoriteClasses(classes) {
  return {
    type: GET_FAVORITE_CLASSES,
    classes,
  }
}

export function fetchHistoryClasses(classes) {
  return {
    type: GET_HISTORY_CLASSES,
    classes,
  }
}

export function fetchPrograms(programs) {
  return {
    type: GET_PROGRAMS,
    programs,
  }
}

export function getVideoInfo(videoMHR) {
  return {
    type: GET_VIDEO_INFO,
    videoMHR,
  }
}

export const fetchVideoInfo = function (userData, videoId) {
  return async (dispatch) => {
    const response = await fetch(`/api/video/${videoId}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: userData.authToken,
      },
    })
    const data = await response.json()

    const videoInfo = data.content

    const action = getVideoInfo(videoInfo.mhr)
    dispatch(action)
  }
}

// Signals that a request has begun
export function videoRequest() {
  return {
    type: VIDEO_REQUEST,
  }
}

// Sends successful data response to reducer
export function favoriteVideoSuccess(data) {
  return {
    type: FAVORITE_VIDEO_SUCCESS,
    data,
  }
}

// Sends the response error to the reducer
export function videoFailure(error) {
  return {
    type: VIDEO_FAILURE,
    error,
  }
}

const GET_ALL_PROGRESS_DATA = "GET_ALL_PROGRESS_DATA"
const GET_PREVIOUS_MONTH_DATA = "GET_PREVIOUS_MONTH_DATA"

export const saveFiltered = (data) => {
  return {
    type: SAVE_FILTERED,
    data,
  }
}

export const resetFiltered = () => {
  return {
    type: RESET_FILTERED,
  }
}

export const saveInitialVideos = (data) => {
  return {
    type: INITIAL_VIDEOSAVE,
    data,
  }
}

export const displayTimeFiltered = (data) => {
  return {
    type: DISPLAY_TIME_FILTERED,
    data,
  }
}

export const displayBodySorted = (data) => {
  return {
    type: DISPLAY_BODY_SORTED,
    data,
  }
}

export const displayFavorites = () => {
  return (dispatch) => {
    authFetch("api/favorites/myFavorites", {
      method: "post",
    })
      .then((res) => {
        return res.content
      })
      .then((res) => {
        dispatch(favoriteVideoSuccess(res))
      })
      .catch((error) => {
        // Dispatch failure which sets the error in state
        dispatch(videoFailure(error))
      })
  }
}

export const favoriteVideoAmountSuccess = (data) => {
  return {
    type: FAVORITE_AMOUNT,
    data,
  }
}

export const getFavoriteAmount = () => {
  return (dispatch) => {
    authFetch("api/favorites/myFavorites", {
      method: "post",
    })
      .then((res) => {
        return res.content
      })
      .then((res) => {
        dispatch(favoriteVideoAmountSuccess(res))
      })
      .catch((error) => {
        // Dispatch failure which sets the error in state
        dispatch(videoFailure(error))
      })
  }
}

export const recordVideoAnalytics = (userData, videoId) => async (dispatch) => {
  return await fetch(`/api/video/logevent`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: userData.authToken,
    },
    body: JSON.stringify({
      email: userData.email,
      device: "web platform",
      event_type: "video",
      videoId,
      userId: userData.id,
    }),
  })
}

export const showAll = () => {
  return {
    type: SHOW_ALL,
  }
}

export const getAllProgressData = (dailyProgress, totals) => {
  return {
    type: GET_ALL_PROGRESS_DATA,
    dailyProgress,
    totals,
  }
}

export const getPreviousMonthData = (previousMonth, previousMonthTotals) => {
  return {
    type: GET_PREVIOUS_MONTH_DATA,
    previousMonth,
    previousMonthTotals,
  }
}

export const showButtonFilters = () => {
  return {
    type: SHOW_BUTTONFILTERED,
  }
}

// profile information

export const getAllProfileData = (profileData, userAge) => {
  return {
    type: GET_ALL_PROFILE_DATA,
    profileData,
    userAge,
  }
}

export const getDailyWorkout = (dailyWork) => {
  return {
    type: GET_DAILY_WORKOUT,
    dailyWorkout: dailyWork,
  }
}

export const getWeeklyData = (totals) => {
  return {
    type: GET_WEEKLY_DATA,
    totals,
  }
}

export const getWeeklyStatus = (week) => {
  return {
    type: GET_WEEKLY_STATUS,
    week,
  }
}

// get favorites information

export const getAllFavorites = (favorites) => {
  return {
    type: GET_ALL_FAVORITES,
    favorites,
  }
}

export const addFavorite = (favorite) => {
  return {
    type: ADD_FAVORITE,
    newFavorite: favorite,
  }
}

// Livestream

const GET_ALL_LIVE_VIDEOS_JETSWEAT = "GET_ALL_LIVE_VIDEOS_JETSWEAT"
const GET_LIVE_CONTENT_ID = "GET_LIVE_CONTENT_ID"
const RECEIVE_SCHEDULED_LIVE = "RECEIVE_SCHEDULED_LIVE"

export const getLiveJetsweat = (videos) => {
  return {
    type: GET_ALL_LIVE_VIDEOS_JETSWEAT,
    liveVideos: videos,
  }
}

export const getContentId = (id) => {
  return {
    type: GET_LIVE_CONTENT_ID,
    id: id,
  }
}

export const createScheduledLive = (classes) => {
  return {
    type: RECEIVE_SCHEDULED_LIVE,
    liveClasses: classes,
  }
}
