import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import "./hamburgerNavBarOptions.css";

import MappedStudioList from "./MappedStudioList";

class NavBarOptions extends Component {
	constructor(props) {
		super(props);
		this.state = { studiosHidden: true };
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {
		e.stopPropagation();
		if (this.state.studiosHidden) {
			this.setState({ studiosHidden: false });
		} else {
			this.setState({ studiosHidden: true });
		}
	}

	render() {
		let visibility = "hide";
		if (!this.props.hidden) {
			visibility = "show";
		}

		const renderArrow =
			this.state.studiosHidden === true ? "" : <span>&#9662;</span>;

		return (
			<div id="flyoutMenu" className={visibility}>
				<ul className="menu-options">
					<li>
						<div className="flyoutMenu-header" />
					</li>
					<li onClick={this.handleClick}>
						<a>Studios {renderArrow}</a>
					</li>
					{this.state.studiosHidden ? (
						<div />
					) : (
						<div className="menu-studio-options">
							<ul>
								<Link
									to="/studios"
									onClick={this.props.showHamburgerNavBarOptions}>
									Check out All Studios
								</Link>

								<ul>
									<MappedStudioList
										studioInfoList={this.props.studioInfoList}
									/>
								</ul>
							</ul>
						</div>
					)}
					<li>
						<Link to="/signup" onClick={this.props.showHamburgerNavBarOptions}>
							Start Your Free Trial
						</Link>
					</li>
					<li>
						<Link
							to="/how-it-works"
							onClick={this.props.showHamburgerNavBarOptions}>
							How It Works
						</Link>
					</li>
					<li>
						<a href="https://www.blog.jetsweatfitness.com">Blog</a>
					</li>
					<li>
						<a href="https://www.blog.jetsweatfitness.com/new-products/">
							Gear
						</a>
					</li>
					<li>
						<Link to="/login" onClick={this.props.showHamburgerNavBarOptions}>
							Log in
						</Link>
					</li>
				</ul>
			</div>
		);
	}
}

export default NavBarOptions;
