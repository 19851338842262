import React, { Component } from 'react';
import './navlink.css';

export default class NavLink extends Component {
  render() {
    let { linkHref, label } = this.props;
    return (
      <div>
        <a className="js-font-333333 js-open-sans js-font-16" href={linkHref}>{label}</a>
      </div>
    )
  }
}
