import React, { Component } from 'react'
import branch from 'branch-sdk'
import { Link, Redirect } from 'react-router-dom'
import { EmailWarning, PasswordWarning } from './formHelpers'
import './signup.css'
import Footer from '../footer'

const signUpImg = require('../../images/signUp-featureImg.jpg')
const studioLogosGrid = require('../../images/StudioLogosGrid.svg')

class Signup extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      password_confirm: '',
      email_warning: false,
      password_warning: false,
      isSubmitting: false,
      error: '',
    }
  }

  handlePasswordChange = (event) => {
    this.handleChange(event)
    if (event.target.value === this.state.password) {
      this.setState({ password_warning: false })
    } else {
      this.setState({ password_warning: true })
    }
  }

  handleEmailChange = (event) => {
    this.handleChange(event)
    if (this.state.email_warning && event.target.value.includes('@')) {
      this.setState({ email_warning: false })
    }
  }

  handleChange = (event) => {
    const fieldName = event.target.name
    this.setState({ [fieldName]: event.target.value })
  }

  validateEmail = (event) => {
    const email = event.target.value
    if (!email.includes('@')) {
      this.setState({ email_warning: true })
    } else {
      this.setState({ email_warning: false })
    }

    // Track user on branch by email id
    branch.setIdentity(email)
  }

  passwordMatch = (event) => {
    if (event.target.value !== this.state.password) {
      this.setState({ password_warning: true })
    } else {
      this.setState({ password_warning: false })
    }
  }

  registerUser = (event) => {
    event.preventDefault()
    this.setState({ isSubmitting: true }, () => {
      if (this.state.password !== this.state.password_confirm) {
        return this.setState({ password_warning: true, isSubmitting: false })
      }
      if (this.state.email_warning) {
        return this.setState({ isSubmitting: false })
      }
      return fetch('/api/user/register', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          userInfo: {
            email: this.state.email,
            password: this.state.password,
            firstName: this.state.firstname,
            lastName: this.state.lastname,
          },
        }),
      })
        .then((res) => res.json())
        .then((body) => {
          if (!body.status) {
            return this.setState({ error: body.content, isSubmitting: false })
          }
          branch.track('user-signup', {})
          return this.props.loginFromToken(body.content.authToken)
        })
        .catch((err) => {
          console.error(err)
          return this.setState({
            error: 'Unknown error. Please try later.',
            isSubmitting: false,
          })
        })
    })
  }

  render() {
    const { currUser } = this.props
    const { isSubmitting } = this.state

    if (currUser) {
      if (currUser.subscription && currUser.subscription.active) {
        return <Redirect to="/newplatform/dashboard" />
      }
      return <Redirect to="/payment" />
    }

    const errDiv = this.state.error ? (
      <div className="Center Padding">
        <div className="errormessage">{this.state.error}</div>
      </div>
    ) : (
      ''
    )

    return (
      <div className="signup">
        <div className="signup-left">
          <div className="signup-header">
            <h1 className="Main-text"> Create your free account </h1>
            {errDiv}
          </div>
          <form onSubmit={this.registerUser}>
            <label>Email address</label>
            <input
              autoFocus
              id="signupemail"
              type="text"
              name="email"
              onChange={this.handleEmailChange}
              onBlur={this.validateEmail}
              className="Input-style Font9 No-highlight-border"
              placeholder="Email"
            />
            <EmailWarning warn={this.state.email_warning} />
            <label>First name</label>
            <input
              id="signupfirstname"
              type="text"
              required
              name="firstname"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="First Name"
            />
            <label>Last name</label>
            <input
              id="signuplastname"
              type="text"
              required
              name="lastname"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Last Name"
            />
            <label>Password</label>
            <input
              id="signuppassword"
              type="password"
              required
              name="password"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Password"
            />
            <label>Re-enter password</label>
            <input
              id="signuppasswordretype"
              type="password"
              required
              name="password_confirm"
              onFocus={this.passwordMatch}
              onChange={this.handlePasswordChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Confirm Password"
            />
            <PasswordWarning warn={this.state.password_warning} />

            {/* <div className="promotion-checkbox">
                <input type="checkbox" name="promotion" value="yes"/>
                <label>I want to receive updates about JETSWEAT products and services</label>
              </div> */}

            <button
              disabled={
                isSubmitting ||
                this.state.password_warning ||
                this.state.email_warning
              }
              type="submit"
              className="Button-style Font9 signup-btn"
            >
              {isSubmitting ? 'Loading...' : 'Create My Account'}
            </button>
          </form>
          <div className="signup-subtext">
            <p>
              By creating your account, you agree to Jetsweat's
              <Link className="Terms-font Terms-link" to="terms">
                {' '}
                Terms of Service and EULA{' '}
              </Link>
              and
              <Link to="privacypolicy" className="Terms-font Terms-link">
                {' '}
                Privacy Policy.
              </Link>
            </p>
            <p>
              Already have an account?
              <Link className="Font9 Login-now" to="login">
                Sign in
              </Link>
            </p>
          </div>
        </div>
        <div className="signup-right">
          <div className="signup-overlay">
            <div className="signup-scheduleDemo">
              <p>
                The best boutique fitness classes in the world, crushable from
                anywhere
              </p>
            </div>
            <div className="signup-partners">
              <p>Our Partners</p>
              <div className="signup-studioLogos-container">
                <img src={studioLogosGrid} className="signup-studioLogos" />
              </div>
            </div>
          </div>
          <img src={signUpImg} className="signup-featureImg" />
        </div>
        <div className="footer-signup">
          <Footer />
        </div>
      </div>
    )
  }
}

export default Signup
