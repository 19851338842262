import React, { Component } from 'react';
import './textitemcombo.css';

export default class TextItemCombo extends Component {
  static defaultProps = {
    orientation: "standard"
  }

  getClassName() {
    let { orientation, containerClassName } = this.props;
    if (orientation === "reverse") return `d-flex flex-md-row-reverse flex-column-reverse align-items-md-start align-items-center ${containerClassName}`;
    return `d-flex flex-md-row flex-column-reverse align-items-md-start align-items-center ${containerClassName}`
  }

  render() {
    let {
      imgSrc,
      imgClassName,
      textContainerClassName,
      textClassName,
      headerClassName,
      header,
      description,
      alt,
      style
    } = this.props;
    return (
      <div style={style} className={`${this.getClassName()} js-why-textitemcombo-container`}>
        <div className={textContainerClassName}>
          <div className={`js-raleway js-font-30 js-font-20-mb js-font-3D3D3D js-font-semibold ${headerClassName}`}>{header}</div>
          <div className={`js-open-sans js-font-20 js-font-15-mb ${textClassName}`}>{description}</div>
        </div>
        <div>
          <img draggable="false" alt={alt} className={imgClassName} src={imgSrc}/>
        </div>
      </div>
    )
  }
}
