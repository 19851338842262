import React from 'react'
import ReactModal from 'react-modal'
import { withRouter } from 'react-router-dom'
import './VideoPlayer/modalexample.css'

// Required props:
// showModal --> whether the modal is visible
// closeModal --> function to close the modal
// title --> value string
// Optional props:
// subTitle --> expects a video with title subproperty
// preventCloseModal --> type = boolean, whether the modal should close on outside click
const JetsweatModal = (props) => {
  const customStyles = {
    content: {
      borderRadius: '10px',
    },
  }
  const titleStyle = {
    color: 'white',
    fontSize: 40,
    textAlign: 'center',
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const subTitleStyle = {
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
  }

  const titleContent = {
    background:
      ( props.whiteLabelBackgroundStyles && props.whiteLabelBackgroundStyles.backgroundColor ?
        props.whiteLabelBackgroundStyles.backgroundColor :
        `linear-gradient(180deg, #9ac8b9 0%, rgba(154, 200, 185, 0) 181.94%)`
      ),
    width: '100%',
    padding: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  }
  const { subTitle, preventCloseModal } = props

  return (
    <ReactModal
      isOpen={props.showModal}
      onRequestClose={props.closeModal}
      shouldCloseOnOverlayClick={preventCloseModal ? false : true}
      style={customStyles}
      className="global-jetsweat-modal"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={titleContent} className="jetsweat-global-modal-title">
          <div style={titleStyle}>{props.title}</div>
          <div style={subTitleStyle}>
            {subTitle ? subTitle.title : undefined}
          </div>
        </div>
        <div style={{ padding: 30 }}>{props.children}</div>
      </div>
    </ReactModal>
  )
}

export default withRouter(JetsweatModal)
