import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import axios from 'axios';
import './singlestudioview.css';

import RenderClassTypes from './RenderClassTypes';
import RenderEquipment from './RenderEquipment';
import RenderAbout from './RenderAbout';
import RenderInstructors from './RenderInstructors';
import RenderTestimonials from './RenderTestimonials';

import StudiosHeading from '../studiosHeading';
import HowWorks from './SingleStudioHowWorks';
import Ending from './SingleStudioEnding';
import Footer from '../../footer';

export default withRouter(class SingleStudioView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStudio: {
        classTypes: [],
        equipment: [],
        about: '',
        instructors: [],
        levels: '',
        text: {
          title: '',
          about: [],
          testimonials: []
        }
      },
      compatibleDevices: {},
      icons: {},
      restrictView: true
    };
    this.checkURL = this.checkURL.bind(this);
    this.generateStudio = this.generateStudio.bind(this);
  }

  async componentDidMount() {
    let currentStudio;
    if (!this.props.currentStudio && !this.props.location.state) {
      const res = await axios.get('/api/basic/webStudiosInfo');
      const studios = res.data.content;
      const { studioId } = this.props;
      let singleStudio;

      if (typeof studioId === 'string') {
        singleStudio = studios.filter(studio => {
          if (studioId === 'chloekernaghanyoga' || studioId === 'skytingyoga') return studio.name === 'Chloe Kernaghan Yoga';
          if (studioId === 'playlistyoga') return studio.name === 'PLAYLIST. Yoga';
          if (studioId === 'thespacebetween') return studio.name === 'The Space Between';
          return studio.shortName === studioId;
        })[0];
      } else {
        singleStudio = studios.filter(studio => studio.id === studioId)[0];
      }
      currentStudio = this.generateStudio(singleStudio);
    } else {
      currentStudio = this.generateStudio(this.props.currentStudio || this.props.location.state.studio);
    }
    this.setState({
      currentStudio: {...currentStudio},
      compatibleDevices: this.props.compatibleDevices,
      icons: this.props.icons,
      restrictView: this.props.restrictView === undefined ? false : this.props.restrictView,
    });
  }

  generateStudio(studioData) {
    const {
      classTypes,
      introH1,
      introH2,
      closingH1,
      closingH2,
      combinedLogo,
      img2,
      logoUrl,
      howItWorks,
      equipment,
      about,
      summary,
      instructors,
      levels,
      hero,
      name,
      feedback,
      webStudioImgUrl
    } = studioData;

    const currentStudio = {
      classTypes,
      introH1,
      introH2,
      closingH1,
      closingH2,
      equipment,
      about: !about ? webStudioImgUrl[0].about : about,
      instructors,
      levels,
      hero: !hero ? webStudioImgUrl[0].hero : hero,
      logo: logoUrl,
      combinedLogo: !combinedLogo ? webStudioImgUrl[0].combinedLogo : combinedLogo,
      img2: !img2 ? webStudioImgUrl[0].img2 : img2,
      howItWorks: !howItWorks ? webStudioImgUrl[0].howItWorks : howItWorks,
      text: {
        title: name,
        about: summary,
        testimonials: feedback,
      },
    };
    return currentStudio;
  }

  checkURL(url) {
    return url === ''
      ? 'https://jetsweat-website-frontend.s3.us-east-2.amazonaws.com/dev/src/images/allStudios/00-defaults/Slice+1.png'
      : url;
  }

  render() {
    const { currentStudio, compatibleDevices, icons } = this.state;

    const validFeedback = (testimonials) => {
      let count = 0;
      testimonials.forEach(({ feedback }) => {
        count += feedback.length
      });
      return count > 0;
    }

    const heroStyle = {
      backgroundImage: `url("${currentStudio.hero}")`,
    };

    // error-handling for empty strings
    currentStudio.levels =
      !currentStudio.levels || currentStudio.levels === '' ? 'allLevels' : currentStudio.levels;

    currentStudio.classTypes =
      !currentStudio.classTypes || currentStudio.classTypes.length === 0
        ? ['Strength']
        : currentStudio.classTypes;

    currentStudio.equipment =
      !currentStudio.equipment || currentStudio.equipment.length === 0
        ? ['yogaMat']
        : currentStudio.equipment;
    return (
      <>
      {!this.state.currentStudio.text.title ? null : (<div className="parent">
        {this.state.restrictView ? null : (
          <StudiosHeading
            studioInfo={this.props.studioInfo}
            isLoggedIn={this.props.isLoggedIn}
            studios={() => (
              <img
                src={currentStudio.combinedLogo}
                alt={` JetSweat X ${currentStudio.text.title}`}
                className="singlestudio-logo"
              />
            )}
          />
        )}
        {this.state.restrictView ? null : (
          <div style={heroStyle} className="singlestudio-hero">
            <div className="hero-wrapper">
              <div className="main_logo">
                <img
                  src={currentStudio.logo}
                  alt={currentStudio.text.title}
                  className="singlestudio-main_logo"
                />
              </div>
              <div>
                <div className="singlestudio-slogan-message">
                  {`Experience ${currentStudio.text.title} on demand with JETSWEAT`}
                </div>
                <div className="freeTrial">
                  <Link to="/signup">Exclusive Free Trial</Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="intro-container">
          {this.state.restrictView ? null : (
            <div className="studio-headers">
            <h1 className="tools-for-you">{currentStudio.introH1}</h1>
            <p className="tools-for-you-subtext">{currentStudio.introH2}</p>
          </div>
          )}
        <div className={this.state.restrictView ? "restrict-view intro" : "intro"}>
          {this.state.restrictView ? (
            <div className="aboutSection">
              <h2 className="section-title">
                {`About ${currentStudio.text.title}`}
              </h2>
              <div>
                <RenderAbout about={currentStudio.text.about} />
              </div>
            </div>
          ) : (
            <img
              src={this.checkURL(currentStudio.img2)}
              alt={currentStudio.text.name}
              className="singlestudio-stud"
            />
          )}
          <div className="singlestudio-introcolumn">
            <div className="singlestudio-classgrid">
              <div>
                <RenderClassTypes
                  classTypes={currentStudio.classTypes}
                  icons={icons}
                />
              </div>
              <div>
                <h2> Levels </h2>
                <div>
                  <img
                    src={icons[currentStudio.levels]}
                    alt="level"
                    className="levelsicon"
                  />
                </div>
              </div>
            </div>
            <div className="singlestudio-classgrid">
              <RenderEquipment
                equipment={currentStudio.equipment}
                icons={icons}
              />
            </div>
          </div>
        </div>
        </div>

        {this.state.restrictView ? null : (<div className="aboutSection">
          <h2 className="section-title">
            {`About ${currentStudio.text.title}`}
          </h2>
          <div className="aboutgrid">
            <div>
              <RenderAbout about={currentStudio.text.about} />
            </div>

            <div className="aboutSubtext2">
              <img
                src={currentStudio.about}
                className="singlestudio-about_image"
                alt={`about ${currentStudio.text.name}`}
              />
            </div>
          </div>
        </div>)}

        <RenderInstructors
          checkURL={this.checkURL}
          instructors={currentStudio.instructors}
        />


          <div className="whatpeoplearesaying">
          {validFeedback(currentStudio.text.testimonials) === true ? (
          <RenderTestimonials
            testimonials={currentStudio.text.testimonials}
            testimonialIcon={icons.tesimonialSml}
          />
          ) : null}

          {this.state.restrictView ? null : (
            <div className="studio-headers">
              <h1 className="tools-for-you">{currentStudio.closingH1}</h1>
              <p className="tools-for-you-subtext">{currentStudio.closingH2}</p>
          </div>
          )}

          {this.state.restrictView ? null : (
            <HowWorks howItWorks={currentStudio.howItWorks} />
          )}
        </div>

        {this.state.restrictView ? null : (
          <Ending compatibleDevices={compatibleDevices} />
        )}
        {this.state.restrictView ? null : <Footer />}
      </div>)}
      </>
    );
  }
})
