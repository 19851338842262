
export const checkHttpStatus = (response) => {
  if (!response.ok) {
    const error = { json: response.json(), response };
    throw error;
  }
  return response;
};

export const checkHttpStatusPromise = (response) => {
  const nonJsonError = { json: '', response };

  if (!response.ok) {
    if ('json' in response) {
      return response.json()
        .then((errorResponse) => {
          const error = { json: errorResponse, response };
          throw error;
        })
        .catch(() => { throw nonJsonError; });
    }
    throw nonJsonError;
  }
  return response;
};


export const parseJSON = (response) => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  }
  return response.text();
};


export function getToken() {
  let currUser = localStorage.getItem('currUser') || '';
  try {
    currUser = JSON.parse(currUser);
  } catch (err) {
    return null;
  }
  return currUser.authToken || null;
}
