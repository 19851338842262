import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import './payment.css';
import PromoBanner from './PromoBanner';
import Footer from './footer.js';
import './account/signup.css';
import './checkoutform.css';

import CheckoutForm from './checkoutform.js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: '',
      firstName: '',
      lastName: '',
      promo: '',
      stripeApiKey: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.redirectToAccount = this.redirectToAccount.bind(this);
  }

  componentDidMount() {
    fetch('/api/basic/stripe', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    })
      .then((res) => {
        if (res.status >= 400) {
          console.log('error!');
        } else {
          return res.json();
        }
      })
      .then((rslt) => {
        this.setState({
          stripeApiKey: (rslt.content || {}).publishable || ""
        });

        return rslt;
      });
  }

  handleChange(event) {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  }

  redirectToAccount() {
    return this.props
      .refreshLoggedInUser()
      .then(() => this.props.history.push('/account'));
  }

  render() {
    let stripePromise = null;
    if (!this.props.isLoggedIn) {
      return <Redirect to="/" />;
    }

    if (this.state.stripeApiKey !== "")  {
      stripePromise = loadStripe(this.state.stripeApiKey);
    }

    return (
      <div className="payment-container">
        <div className="Background-color-nav" />
        {/*}<PromoBanner promoCode="BEWELL30" />*/}
        <div className="Center">
          <p className="Main-text"> Billing Information </p>
          <p className="Main-text"> Choose Your Subscription:</p>
        </div>

        <div className="clearfix" style={{ width: "100%" }}>
          <div className="Responsive-plan">
            <section
              className="Gallery"
              onClick={() => {
                this.props.setSubscription('yearly');
              }}
            >
              <fieldset
                className={`Monthly-annualbox ${
                  this.props.subscription === 'yearly' ? 'Active' : ''
                  }`}
              >
                <legend className="Price-font">Annual Plan</legend>
                <div className="Payment-yearly">
                  <p>
                    <span className="Price-font">$239.99/year</span>
                    <br />
                    billed annually
                    <br />
                    <span className="Price-font">Unlimited Workouts</span>
                    <br />
                    <br />
                    <span className="Price-font">First 7 Days Free</span>
                    <br />
                    Cancel at any time
                  </p>
                </div>
              </fieldset>
            </section>
          </div>
          <div className="Responsive-plan">
            <section
              className="Gallery"
              onClick={() => {
                this.props.setSubscription('monthly');
              }}
            >
              <fieldset
                className={`Monthly-annualbox ${
                  this.props.subscription !== 'yearly' ? 'Active' : ''
                  }`}
              >
                <legend className="Price-font">Monthly Plan</legend>
                <div className="Payment-monthly">
                  <p>
                    <span className="Price-font">$19.99/month</span>
                    <br />
                    billed monthly
                    <br />
                    <span className="Price-font">Unlimited Workouts</span>
                    <br />
                    <br />
                    <span className="Price-font">First 7 Days Free</span>
                    <br />
                    Cancel at any time
                  </p>
                </div>
              </fieldset>
            </section>
          </div>
        </div>
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({stripe, elements}) => (
                <CheckoutForm
                  apiKey={this.state.stripeApiKey}
                  redirectToAccount={this.redirectToAccount}
                  subscription={this.props.subscription}
                  authToken={this.props.currUser.authToken}
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                  stripe={stripe}
                  elements={elements}
                />
              )}
            </ElementsConsumer>
          </Elements>
        )}
        <div className="Payment-description">
          <p>
            Your subscription includes unlimited access to all JETSWEAT classes.
            Promo codes cannot be combined with standard 7-day trial offer. Your
            payment will be charged to your specified card at the expiration of
            any free trial period unless cancelled at least 24 hours in advance.
            The subscription automatically renews unless cancelled at least 24
            hours before the end of the current period. You can cancel your
            subscription at anytime by emailing support@jetsweatfitness.com or
            by visiting your Apple settings dashboard for iTunes subscribers
            only.
            {' '}
            <br />
            <br />
            By signing up, you agree to the
            {' '}
            <Link to="terms">Terms of Service</Link>
            {' '}
            and
            {' '}
            <Link to="privacypolicy">Privacy Policy.</Link>
          </p>
        </div>
        <div className="footer-payment">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Payment;
