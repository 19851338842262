const initialState = {
  filteredVideos: [],
  filteredView: false,
  seperatedByDuration: {},
  timeView: false,
  durationSortedVideos: [],
  bodyFocusVideos: {},
  bodySortedVideos: [],
  bodyFocusView: false,
  showFavorites: false,
  videosById: [],
  favoritedVideos: [],
  favoriteView: false,
  favoritedVideosLength: 0,
  isFetching: false,
  error: null,
  showAllView: false,
  buttonFiltered: false,
  videoMHR: 0.1,
  favoriteVids: {},
  favoriteIds: [],
  favoriteIdToId: {},
};

export default function programReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_VIDEO_INFO':
      return {
        ...state,
        videoMHR: action.videoMHR || 0.6,
      };
    case 'SAVE_FILTERED':
      return {
        ...state,
        filteredVideos: action.data || state.filteredVideos,
        filteredView: true,
        timeView: false,
        bodyFocusView: false,
        showAllView: false,
        buttonFiltered: false,
        isFetching: false,
      };
    case 'REMOVE_FILTERED':
      return {
        ...state,
        filteredVideos: [],
        filteredView: false,
        timeView: false,
        bodyFocusView: false,
        favoriteView: false,
        showAllView: false,
        buttonFiltered: false,
      };
    case 'RESET_FILTERED':
      return {
        ...state,
        filteredVideos: [],
        filteredView: false,
        timeView: false,
        bodyFocusView: false,
        favoriteView: false,
        showAllView: false,
        buttonFiltered: false,
      };

    case 'INITIAL_VIDEOSAVE':
      const videos = action.data;

      const videoIdSort = [];
      const bodySortedVideos = {
        'Upper Body': [],
        'Lower Body': [],
        Core: [],
        'Total Body': [],
      };
      const sortedVideos = { 15: [], 30: [], 45: [], 60: [], 61: [] };
      videos.forEach((video) => {
        if (video.duration / 60 <= 15) {
          sortedVideos[15].push(video);
        } else if (video.duration / 60 >= 15 && video.duration / 60 <= 30) {
          sortedVideos[30].push(video);
        } else if (video.duration / 60 >= 30 && video.duration / 60 <= 45) {
          sortedVideos[45].push(video);
        } else if (video.duration / 60 >= 45 && video.duration / 60 <= 60) {
          sortedVideos[60].push(video);
        } else if (video.duration / 60 >= 60) {
          sortedVideos[61].push(video);
        }
        if (video.bodyFocus.length > 0) {
          video.bodyFocus.forEach((focus) => {
            bodySortedVideos[focus].push(video);
          });
        }
        videoIdSort.push(video);
      });
      videoIdSort.reverse();

      return {
        ...state,
        seperatedByDuration: sortedVideos,
        bodyFocusVideos: bodySortedVideos,
        videosById: videoIdSort,
      };
    case 'DISPLAY_TIME_FILTERED':
      const result = state.seperatedByDuration[parseInt(action.data)];
      return {
        ...state,
        durationSortedVideos: result,
        timeView: true,
        filteredView: false,
        bodyFocusView: false,
        favoriteView: false,
        showAllView: false,
        buttonFiltered: false,
      };
    case 'DISPLAY_BODY_SORTED':
      const body = action.data;
      const bodyVideos = state.bodyFocusVideos[body];
      return {
        ...state,
        bodyFocusView: true,
        bodySortedVideos: bodyVideos,
        filteredView: false,
        timeView: false,
        favoriteView: false,
        showAllView: false,
        buttonFiltered: false,
      };
    case 'VIDEO_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case 'VIDEO_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case 'FAVORITE_VIDEO_SUCCESS':
      const favorites = action.data;
      const favoriteIds = [];
      const favoriteVideos = [];
      let idFav;
      const idFavoriteId = {};
      favorites.forEach((video) => {
        if (video.video) {
          idFavoriteId[video.video.id] = video.id;
          idFav = video.video;
          idFav.favoriteId = video.id;
          favoriteIds.push(video.id);
          favoriteVideos.push(idFav);
        }
      });

      return {
        ...state,
        favoritedVideos: favoriteVideos,
        favoriteView: true,
        filteredView: false,
        timeView: false,
        bodyFocusView: false,
        isFetching: false,
        showAllView: false,
        buttonFiltered: false,
        favoriteIds,
        favoriteIdtoId: idFavoriteId,
      };

    case 'FAVORITE_AMOUNT':
      const favorites1 = [];
      let clip;
      const favs = {};
      const favoriteIdPair = {};

      const favoritePull = action.data;

      favoritePull.forEach((video) => {
        if (video.video) {
          favoriteIdPair[video.video.id] = video.id;
          clip = video.video;
          clip.favoriteId = video.id;
          favorites1.push(clip);
        }
      });

      favorites1.forEach((vid) => {
        favs[vid.id] = 1;
      });

      return {
        ...state,
        favoritedVideosLength: favorites1.length,
        favoritedVideos: favorites1,
        favoriteVids: favs,
        favoriteIdToId: favoriteIdPair,
      };

    case 'SHOW_BUTTONFILTERED':
      return {
        ...state,
        showAllView: false,
        buttonFiltered: true,
      };

    case 'SHOW_ALL':
      return {
        ...state,
        showAllView: true,
        timeView: false,
        filteredView: false,
        bodyFocusView: false,
        favoriteView: false,
        buttonFiltered: false,
      };
    default:
      return state;
  }
}
