import React, { Component } from 'react';
import './dot.css';

export default class Dot extends Component {
  getClassName() {
    let classNames =  ["js-dot"]
    let { selected } = this.props;

    if (selected) classNames.push("js-background-1F3830")
    else classNames.push("js-background-gray");
    return classNames.join(" ");
  }

  render() {
    let { onClick } = this.props;
    return (
      <button
        className={this.getClassName()}
        onClick={onClick.bind(this)} />
    )
  }
}
