import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import "./homepagevideoplayer.css"

export default class HomePageVideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false
    }
  }

  onPlaying() {
    this.setState({
      isPlaying: true
    })
  }

  getOverlay() {
    if (this.state.isPlaying) return <div className="overlay" />
    return null;
  }

  render() {
    let { videoUrl } = this.props;
    return (
      <div className="homepage-video-container">
        {this.getOverlay()}
        <video onPlaying={this.onPlaying.bind(this)} autoPlay loop muted playsInline className="video-background">
          <source src={videoUrl} type="video/mp4" />
          <div className="Background-image" />
        </video>
        {/*}<div className="Box">

          <p className="Boutique-fitnesseverywhere">
            {' '}
            Boutique Fitness,
              {' '}
            <br />
            {' '}
            Everywhere.
            </p>
          <div className="Heading-underline" />
          <p className="Boutique-subtext">
            Exclusive access to stream unlimited workout videos
            from leading boutique studios, wherever, whenever.
            </p>
          <Link
            to="signup"
            className="Video-text"
            data-aos="fade-in"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1300"
          >
            Start Your Free Trial
            </Link>
        </div>*/}
      </div>
    );
  }
}
