import React, { Component } from 'react';
import TextField from 'components/textfield.component';
import Link from 'screens/home/components/link';
import { isFilled, isEmailValid } from 'helpers/validators';
import './signup.css';

export default class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: "",
        error: false
      },
      firstName: {
        value: "",
        error: false
      },
      lastName: {
        value: "",
        error: false
      },
      password: {
        value: "",
        error: false
      },
      confirmPassword: {
        value: "",
        error: false
      }
    }
  }

  isFieldValid(validator, key) {
    let isValid = true;

    isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid
      }
    });

    return isValid;
  }


  validate() {
    let fields = new Set();
    //fields.add(this.isFieldValid(isEmailValid, "email"));

    return !fields.has(false);
  }


  onClickSubmit() {
    let { submit } = this.props;
  }

  render() {
    let {
      email,
      firstName,
      lastName,
      password,
      confirmPassword
    } = this.state;

    return (
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-column align-items-center js-form">
          <TextField
            onChange={()=>{}}
            placeholder="Email"
            name="email"
            value={email.value}
            error={email.error}
          />
          <TextField
            onChange={()=>{}}
            placeholder="First Name"
            name="firstName"
            value={firstName.value}
            error={firstName.error}
          />
          <TextField
            onChange={()=>{}}
            placeholder="Last Name"
            name="lastName"
            value={lastName.value}
            error={lastName.error}
          />
          <TextField
            onChange={()=>{}}
            placeholder="Password"
            name="password"
            value={password.value}
            error={password.error}
          />
          <TextField
            onChange={()=>{}}
            placeholder="Confirm Password"
            name="confirmPassword"
            value={confirmPassword.value}
            error={confirmPassword.error}
          />
        </div>
        <Link
          className="js-signup-button"
          href="/signup"
          label="Start your free trial" />
      </div>
    )
  }
}
