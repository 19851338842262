import React, { Component } from 'react';
import branch from 'branch-sdk';
import { Redirect } from 'react-router-dom';
import './promoSignup.css';
import { EmailWarning, PasswordWarning } from './formHelpers';
import Footer from '../footer';

class PromoSignup extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      email_warning: false,
      password_warning: false,
      isSubmitting: false,
      promoImage: '',
      promoImage2: '',
      promoImage3: '',
      promoImage4: '',
      promoCode: null,
      error: ''
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      const { search } = this.props.location;
      const searchParams = new URLSearchParams(search);
  
      const { currUser } = this.props;
  
      if (currUser) {
        if (currUser.subscription && currUser.subscription.active) {
          return <Redirect to="/newplatform/dashboard" />;
        }
        return this.props.history.push({
          pathname: '/payment',
          state: {
            promo: this.state.promoCode
          }
        })
      }
      return this.setState({ 
        promoImage: searchParams.get('promoImage'), 
        promoImage2: searchParams.get('promoImage2'), 
        promoImage3: searchParams.get('promoImage3'), 
        promoImage4: searchParams.get('promoImage4'), 
        promoCode: searchParams.get('code'),
        isLoading: false
      });
    });
  }

  componentDidUpdate (prevProps, prevState) {
    const { currUser } = this.props;
    if (prevProps.currUser !== currUser) {
      return this.handleRedirect();
    }
  }

  handleRedirect = () => {
    const { currUser } = this.props;
    if (currUser) {
      if (currUser.subscription && currUser.subscription.active) {
        return <Redirect to="/newplatform/dashboard" />;
      }
      return this.props.history.push({
        pathname: '/payment',
        state: {
          promo: this.state.promoCode
        }
      })
    }
  }

  validateEmail = event => {
    const { value: email } = event.target;
    if (email.includes('@')) {
      branch.setIdentity(email);
      return this.setState({ email_warning: false });
    }
    return this.setState({ email_warning: true });
  }

  validatePasswordMatch = () => {
    const { password, passwordConfirm } = this.state;
    if (password === passwordConfirm) {
      return this.setState({ password_warning: false });
    }
    return this.setState({ password_warning: true });
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });

    if (name === 'email') {
      if (this.state.email_warning && value.includes('@')) {
        return this.setState({ email_warning: false });
      }
    }

    if (name === 'password' || name === 'passwordConfirm') {
      const { password, passwordConfirm } = this.state;
      if (value === password || value === passwordConfirm) {
        return this.setState({ password_warning: false });
      }
      return this.setState({ password_warning: true });
    }
    return;
  }


  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isSubmitting: true }, () => {
      if (this.state.password !== this.state.passwordConfirm) {
        return this.setState({ password_warning: true, isSubmitting: false });
      }

      if (this.state.email_warning) return this.setState({ isSubmitting: false });

      return fetch('/api/user/register', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          userInfo: {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password
          }
        })
      })
        .then((res) => res.json())
        .then((body) => {
          if (!body.status) {
            return this.setState({ error: body.content, isSubmitting: false });
          }
          branch.track('user-signup', {});
          return this.props.loginFromToken(body.content.authToken);
        })
        .catch((err) => {
          console.error(err);
          return this.setState({ error: 'Unknown error. Please try later.', isSubmitting: false });
        })
    })
  }

  disableSubmit = () => {
    const { firstName, lastName, email, password, passwordConfirm, isSubmitting } = this.state;
    return [firstName, lastName, email, password, passwordConfirm].some((value) => !value) || isSubmitting;
  };

  displayError = () => {
    return (
      <div className="Center Padding">
        <div className="error-message">{this.state.error}</div>
      </div>
    )
  }

  renderForm = () => {
    const { email, firstName, lastName, password, passwordConfirm, isSubmitting } = this.state;
    
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="Center Padding">
          <input 
            id="email" 
            type="text" 
            name="email" 
            value={email}
            onChange={this.handleChange} 
            className="Input-style Font9 No-highlight-border"
            onBlur={this.validateEmail}
            placeholder="Email"
          />
          <EmailWarning warn={this.state.email_warning} />
        </div>

        <div className="Center Padding">
          <input 
            id="signupfirstname" 
            type="text" 
            required 
            name="firstName" 
            value={firstName}
            onChange={this.handleChange} 
            className="Input-style Font9 No-highlight-border" 
            placeholder="First Name" 
          />
        </div>

        <div className="Center Padding">
          <input 
            id="signuplastname" 
            type="text" 
            required 
            name="lastName" 
            value={lastName}
            onChange={this.handleChange} 
            className="Input-style Font9 No-highlight-border" 
            placeholder="Last Name" 
          />
        </div>

        <div className="Center Padding">
          <input 
            id="signuppassword" 
            type="password" 
            required 
            name="password" 
            value={password}
            onChange={this.handleChange} 
            onBlur={this.validatePasswordMatch}
            className="Input-style Font9 No-highlight-border" 
            placeholder="Password" 
          />
        </div>

        <div className="Center Padding">
          <input 
            id="signuppasswordretype" 
            type="password" 
            required 
            name="passwordConfirm" 
            value={passwordConfirm}
            onChange={this.handleChange} 
            onBlur={this.validatePasswordMatch}
            className="Input-style Font9 No-highlight-border" 
            placeholder="Confirm Password" 
          />
          <PasswordWarning warn={this.state.password_warning} />
        </div>

        <div className="Center Padding">
          <button 
            disabled={this.disableSubmit() || this.state.password_warning || this.state.email_warning} 
            type="submit" 
            className="Button-style Font9 signup-button">
            {' '}
            { isSubmitting ? 'Loading...' : 'LET\'S WORK OUT!' }
            {' '}
          </button>
        </div>
      </form>
    )
  }

  renderImage = (image, id) => {
    return (
      !image 
        ? <div className="Center">Loading...</div>
        : (
          <div className="promo-image-container">
            <img className="promo-image" id={id} src={image} alt="JETSWEAT promo" />
          </div>
        )
    )
  }

  render() {
    const { isLoading, promoImage, promoImage2, promoImage3, promoImage4, error } = this.state;

    return (
      isLoading ?
        <div className="Center">Loading..</div>
        : (
          <div className="promo-landing-page-container">
            <div className="promo-landing-page-form">
              <div className="Background-color-nav" />
              <div className="promo-signup-container">
                {promoImage && this.renderImage(promoImage, 'promoImage')}
                {error && this.displayError()}
                <div className="promo-form-container">
                  <h1 className="Main-text">Start with a free trial</h1>
                  <sub className="Center subtext">Access the world&apos;s top studio classes from yoga, sculpt, cardio, Pilates and much more. Redefine your fitness routine with workouts that fit your lifestyle. </sub> 
                  <div className="form-container">
                    {this.renderForm()}
                  </div>
                </div>
              </div>
              {promoImage2 && this.renderImage(promoImage2, 'promoImage2')}
              {promoImage3 && this.renderImage(promoImage3, 'promoImage3')}
              {promoImage4 && this.renderImage(promoImage4, 'promoImage4')}
            </div>
            <Footer />
          </div>
        )
    )
  }
}

export default PromoSignup;