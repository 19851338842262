import React, { Component } from 'react';
import Dots from 'screens/home/components/dots';

const STARTING_INDEX = 0;

export default class Swiper extends Component {
  static defaultProps = {
    swiperDesktop: true,
    offsetDivider: 4,
    offsetOpacityRule: null
  }
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    let { list } = props;
    let isSwipeable = this.isSwipeable();
    this.state = {
      activeIndex: STARTING_INDEX,
      positions: this.getPositions(STARTING_INDEX, list, isSwipeable),
      offsetFactor: this.getOffsetFactor(),
      isScrolling: false,
      currentX: 0,
      viewportWidth: this.getViewportWidth(),
      startX: 0,
      scrollPanelStyle: {},
      offsetDivider: props.offsetDivider,
      list,
      isSwipeable
    }
    this.onResize = this.onResize.bind(this);
  }

  isSwipeable(list = null) {
    let { innerWidth } = window;
    if (innerWidth < 768) {
      return true
    }

    let { item_width_dk } = this.props;
    list = (list === null? this.props.list : list)
    if (item_width_dk*list.length < 100) {
      return false
    }
    return true
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.list) !== JSON.stringify(this.props.list)) {
      let isSwipeable = this.isSwipeable(this.props.list)
      this.setState({
        positions: this.getPositions(STARTING_INDEX, this.props.list, isSwipeable),
        list: this.props.list,
        isSwipeable
      })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize, false);
  }

  getOffsetFactor() {
    let {
      offset_factor_dk,
      offset_factor_mb
    } = this.props;

    let { innerWidth } = window;
    let offsetFactor = offset_factor_dk;
    if (innerWidth < 768) {
      offsetFactor = offset_factor_mb;
    }

    return offsetFactor;
  }

  getViewportWidth() {
    let {
      viewport_width_dk,
      viewport_width_mb
    } = this.props;
    let { innerWidth } = window;
    let viewportWidth = viewport_width_dk;
    if (innerWidth < 768) {
      viewportWidth = viewport_width_mb;
    }

    return viewportWidth;
  }


  getPositions(activeIndex, list = null, isSwipeable = true) {
    let positions = {};
    list = (list === null ? this.state.list : list);
    let skip = false;
    if (this.state) {
      if (JSON.stringify(this.state.list) !== JSON.stringify(this.props.list)) {
        skip = true
      }
    }

    let offsetFactor = (this.state ? this.state.offsetFactor : this.getOffsetFactor());
    positions[activeIndex] = {
      translateX: 0,
      //opacity: 1
    }
    //prev
    //let opacity; //initial state
    let current = 0;

    if (!isSwipeable) {
      for (let i = 0; i < list.length ; i++) {
        //opacity = 1; //inital state
        let current = activeIndex + i;
        if (current > list.length - 1) {
          current = current - list.length
        }

        let newTranslateX = i * offsetFactor;

        positions[current] = {
          translateX: newTranslateX,
          //opacity
        }
      }
      return positions;
    }
    let opacity = 1;
    let endAtForward = list.length/2;
    if (list.length > 3) endAtForward = list.length/2 - 1
    for (let i = 1; i < list.length - 1; i++) {
      //opacity = 1;
      current = activeIndex - i;
      if (current < 0 && activeIndex !== 0) {
        current = list.length + current
      } else if (activeIndex === 0) {
        current = list.length + current;
      }

      let newTranslateX = -i * offsetFactor;

      opacity = 1;

      if (this.state && this.state.positions && Object.keys(this.state.positions).length === list.length) {
        if ((newTranslateX > 100 || newTranslateX < 0) && (this.state.positions[current].translateX > 100 || this.state.positions[current].translateX < 0)) {
          opacity = 0;
          console.log("cough cough", newTranslateX, this.state.positions[current].translateX)
        }
      }
      /*if (this.state && this.state.position && Object.keys(this.state.positions).length === this.state.list.length) {
        //if (this.state.positions[current].translateX > 0 && newTranslateX < 0 && !skip) {
        console.log(this.state.position, this.state.list, 'Hee')
        let factor = (this.props.offsetOpacityRule === null ? this.state.offsetFactor : this.props.offsetOpacityRule)
        if ((newTranslateX >= factor  && this.state.positions[current].translateX < 0) || (newTranslateX < 0 && this.state.positions[current].translateX >= factor)) {
          opacity = 0;
        }
        //}
      }*/
      /*if (this.state && this.state.positions.length === this.state.list.length) {
        //if (this.state.positions[current].translateX > 0 && newTranslateX < 0 && !skip) {
        console.log(current, this.state.positions[current].translateX, newTranslateX)
        if (newTranslateX >= 0 && newTranslateX < 100 ) {
          opacity = 1;
        } else {
          console.log(current, this.state.positions[current].translateX, newTranslateX)
          if (this.state.positions[current].translateX < 0 && newTranslateX > 100) {
            opacity = 0;
          }
        }
        //}
      }*/

      positions[current] = {
        translateX: newTranslateX,
        opacity
      }
    }
    //next
    let endAtBackward = list.length/2;
    if (list.length > 3) endAtBackward = list.length/2 + 1
    for (let i = 1; i <= endAtBackward; i++) {
      //opacity = 1; //inital state
      let current = activeIndex + i;
      if (current > list.length - 1) {
        current = current - list.length
      }

      let newTranslateX = i * offsetFactor;
      opacity = 1;

      if (this.state && this.state.positions && Object.keys(this.state.positions).length === list.length) {
        if ((newTranslateX > 100 || newTranslateX < 0) && (this.state.positions[current].translateX > 100 || this.state.positions[current].translateX < 0)) {
          opacity = 0;
          console.log("cough cough", newTranslateX, this.state.positions[current].translateX)
        }
      }
      /*f (this.state && this.state.position && Object.keys(this.state.positions).length === this.state.list.length) {
        let factor = this.props.offsetOpacityRule || this.state.offsetFactor
        if ((newTranslateX >= factor && this.state.positions[current].translateX < 0) || (newTranslateX < 0 && this.state.positions[current].translateX >= factor)) {
          opacity = 0;
        }
      }*/

      positions[current] = {
        translateX: newTranslateX,
        opacity
      }
    }
    return positions
  }

  onResize(event) {
    let that = this;
    let offsetFactor = this.getOffsetFactor();
    let viewportWidth = this.getViewportWidth();
    let isSwipeable = (window.viewportWidth < 768 ? true : this.state.isSwipeable)
    this.setState({
      offsetFactor,
      viewportWidth,
      isSwipeable
    }, () => {
      let { activeIndex } = that.state;
      this.setState({
        positions: this.getPositions(activeIndex, null, isSwipeable)
      });
    });
  }

  handleNavigate(forward) {
    let { activeIndex, list, isSwipeable } = this.state;

    if (!forward) {
      activeIndex++;
    } else {
      activeIndex--;
    }

    if (activeIndex === list.length) {
      activeIndex = 0;
    }

    if (activeIndex < 0) {
      activeIndex = list.length-1;
    }

    this.setState({
      activeIndex,
      positions: this.getPositions(activeIndex, list, isSwipeable)
    });
  }

  getStyle(index) {
    let { swiperDesktop } = this.props;
    let { innerWidth } = window;

    if (!swiperDesktop && innerWidth > 767) return {};

    let { positions, offsetFactor } = this.state;

    let { translateX, opacity } = positions[index];


    return {
      transform: `translateX(${translateX}vw)`,
      opacity
    }
  }

  onMouseDown(event) {
    //event.stopPropagation();
    let { isSwipeable } = this.state;
    if (!isSwipeable) return null;
    let { swiperDesktop } = this.props;
    let { innerWidth } = window;
    if (!swiperDesktop && innerWidth > 767) return null;
    this.setState({
      isScrolling: true,
      currentX: event.clientX,
      startX: event.clientX
    });
  }

  onMouseUp(event) {
    let { offsetDivider, isSwipeable } = this.state;
    if (!isSwipeable) return null;
    let { swiperDesktop } = this.props;
    let { innerWidth } = window;
    if (!swiperDesktop && innerWidth > 767) return null;
    let { startX, activeIndex, offsetFactor } = this.state;
    let finalXdifference = (event.clientX - startX)/1920 * 100;
    let indexDiff = Math.round(finalXdifference / (offsetFactor/offsetDivider));

    let forward = (indexDiff >= 0 ? true : false)
    for (let i = 0; i < Math.abs(indexDiff); i++) {
      this.handleNavigate(forward)
    }

    this.setState({
     isScrolling: false,
     scrollPanelStyle: {}
    });
  }

  onTouchMove(event) {
    const { startX, isSwipeable } = this.state;
    if (!isSwipeable) return null;
    let { swiperDesktop } = this.props;
    let { innerWidth } = window;
    if (!swiperDesktop && innerWidth > 767) return null;

    if (this.state.isScrolling) {
      let touchedObject = event.changedTouches[0];
      let { clientX } = touchedObject;
      let scrollPanelStyle = {
        transform: `translateX(${clientX} - startX)/1920 * 100}vw)`
      }
      this.setState({
        scrollPanelStyle,
        currentX: clientX
      })
    }
  }

  onTouchStart(event) {
    //event.stopPropagation();
    let { isSwipeable } = this.state;
    if (!isSwipeable) return null;
    let { swiperDesktop } = this.props;
    let { innerWidth } = window;
    if (!swiperDesktop && innerWidth > 767) return null;
    let touchedObject = event.changedTouches[0];
    let { clientX } = touchedObject;

    this.setState({
      isScrolling: true,
      currentX: clientX,
      startX: clientX
    });
  }

  onTouchEnd(event) {
    let { isSwipeable } = this.state;
    if (!isSwipeable) return null;
    let { swiperDesktop } = this.props;
    let { innerWidth } = window;
    if (!swiperDesktop && innerWidth > 767) return null;
    let { startX, activeIndex, offsetFactor, viewportWidth } = this.state;
    let touchedObject = event.changedTouches[0];
    let { clientX } = touchedObject;
    let finalXdifference = (clientX - startX)/viewportWidth * 100;
    let indexDiff = Math.round(finalXdifference / (offsetFactor/4));

    let forward = (indexDiff >= 0 ? true : false)
    for (let i = 0; i < Math.abs(indexDiff); i++) {
      this.handleNavigate(forward)
    }

    this.setState({
     isScrolling: false,
     scrollPanelStyle: {}
    });
  }

  onMouseMove(event) {
    let { isSwipeable } = this.state;
    if (!isSwipeable) return null;
    let { swiperDesktop } = this.props;
    let { innerWidth } = window;
    if (!swiperDesktop && innerWidth > 767) return null;
    const { startX } = this.state;
    if (this.state.isScrolling) {
      let scrollPanelStyle = {
        transform: `translateX(${(event.clientX - startX)/1920 * 100}vw)`
      }
      this.setState({
        scrollPanelStyle,
        currentX: event.clientX
      })
    }
  }

  handleOnChangeDots(index) {
    let { isSwipeable, list } = this.state;
    this.setState({
      activeIndex: index,
      positions: this.getPositions(index, list, isSwipeable)
    })
  }

  getDots() {
    let {
      activeIndex, list, isSwipeable
    } = this.state;

    if (!isSwipeable) return null;
    let { swiperDesktop } = this.props;
    let { innerWidth } = window;
    let {
      dotsClassName
    } = this.props;

    if (!swiperDesktop && innerWidth > 767) return null;

    return (
      <Dots
        activeIndex={activeIndex}
        onChange={this.handleOnChangeDots.bind(this)}
        className={dotsClassName}
        items={Array.from(Array(list.length).keys())} />
    )
  }

  render(){
    if (JSON.stringify(this.props.list) !== JSON.stringify(this.state.list)) return null;
    let {
      scrollPanelStyle,
      list
    } = this.state;

    let {
      className,
      containerClassName
    } = this.props;

    let that = this;
    let buffer = React.Children.map(this.props.children, (child, index)=> {return React.cloneElement(child, { style: that.getStyle(index) })});

    return (
      <>
        <div
          onTouchStart={this.onTouchStart.bind(this)}
          onTouchEnd={this.onTouchEnd.bind(this)}
          onTouchMove={this.onTouchMove.bind(this)}
          onMouseUp={this.onMouseUp.bind(this)}
          onMouseDown={this.onMouseDown.bind(this)}
          onMouseMove={this.onMouseMove.bind(this)}
          style={scrollPanelStyle}
          ref={this.ref}
          className={`${containerClassName} d-flex justify-content-center`}>
          {buffer}
        </div>
        {this.getDots()}
      </>
    )
  }
}
