import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlickSlider = (props) => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    adaptiveHeight: true,
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="testimonial_background">
            <img
              alt="amy k"
              src={
                require('../../images/testimonial_folder/drop-1.c0d472d2.png')
              }
              className="slickphotoSize"
            />
            <div className="testimonial">
              "With JETSWEAT, I can enjoy my favorite Modelfit classes on the road
                and in the comfort of my own home. Total game changer."
              {' '}
              <br />
              <span>- Amy K</span>
            </div>
          </div>
        </div>

        <div>
          <div className="testimonial_background">
            <img
              alt="Juliet F"
              src={
                require('../../images/testimonial_folder/drop-2.d8bd9279.png')
              }
              className="slickphoto"
            />
            <div className="testimonial">
              "JETSWEAT helped me lose weight by making it easier to stick to a routine on my own schedule. No more missed classes.
                  I can exercise when it works for me and keep track of all my workouts in the app."
              {' '}
              <br />
              <span>- Juliet F</span>
            </div>
          </div>
        </div>

        <div>
          <div className="testimonial_background">
            <img
              alt="Jennifer E"
              src={
                require('../../images/testimonial_folder/drop-3.1a3d3b2e.png')
              }
              className="slickphoto"
            />
            <div className="testimonial">
              "I've never felt healthier. Since I joined JETSWEAT,
              I workout twice as much because now I can take my fave modelFIT and Yoga Vida classes on the road and from home.
               Total game changer."
              {' '}
              <br />
              <span>- Jennifer E</span>
            </div>
          </div>
        </div>
        <div>
          <div className="testimonial_background">
            <img
              alt="Justin M"
              src={
                require('../../images/testimonial_folder/drop-4.a7182ef7.png')
              }
              className="slickphoto"
            />
            <div className="testimonial">
              "JETSWEAT's unique selection of only top studio classes is exactly what I've been looking for.
               I'm saving so much money and can't wait to see which new classes get added each month."
              <br />
              <span>- Justin M</span>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};


export default SlickSlider;
