import React, { Component } from "react";
// import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';
import branch from "branch-sdk";
import CardSection from "../cardsection.js";
import Promo from "../promo.js";
import moment from 'moment-timezone';
import "../account/signup.css";
import "../checkoutform.css";
import axios from "axios";
import { CardNumberElement } from "@stripe/react-stripe-js";

class WhitelabelCheckoutForm extends Component {
  constructor() {
    super();
    this.state = {
      promo: "",
      error: "",
      stall: false,
      isLoading: false,
      isPurchasePending: false,
    };
  }

  componentDidMount() {
    // TODO GA and FB
    // ReactGA.initialize(process.env.WHITELABEL_ANALYTICS_ID);
    // ReactPixel.init(
    //   process.env.WHITELABEL_FACEBOOK_PIXEL_ID,
    //   {},
    //   { autoConfig: true, debug: false }
    // );
    branch.init("key_live_birB93AdBoSzWQwgtkp9vmdlryn2vOgO");
  }

  handleChange = (event) => {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  };

  setPromo = (promo) => {
    return this.setState({ promo });
  };

  handleSubmit = async (ev) => {
    const { stripe, elements } = this.props;
    let that = this;
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (this.state.stall || !stripe || !elements) {
      return null;
    }
    this.setState(
      {
        isPurchasePending: true,
      },
      async () => {
        const {
          subscription,
          studio,
          redirectToAccount,
          date,
          firstName,
          lastName
        } = this.props;

        const fullName = (firstName || "-") + " " + (lastName || "-");
        const studioName = studio;
        let packageSub;
        if (subscription === "yearly") {
          packageSub = `${studioName}_yearly`;
        } else if (subscription === "monthly") {
          packageSub = `${studioName}_monthly`;
        } else {
          console.log(`Didnt match anything: ${subscription}`);
        }
        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        const cardElement = elements.getElement(CardNumberElement);
        const tokenRes = await stripe.createToken(cardElement);
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: fullName,
          },
        });
        this.setState({ isLoading: false });
        if (!tokenRes.token) {
          return this.setState({
            stall: false,
            error: (
              <p>
                <span>
                  An error occurred and payment information could not be
                  verified.
                </span>
                <br />
                <span>
                  Please try again in a few minutes or contact your bank to
                  approve the transaction.
                </span>
                <br />
                <span>
                  If the problem persists, contact support@jetsweatfitness.com.
                </span>
                <br />
              </p>
            ),
          });
        } else {
          if (subscription !== "daypass") {
            fetch(`/api/user/subscribe/${studioName}`, {
              method: "POST",
              headers: {
                "Content-type": "application/json",
                Authorization: this.props.authToken,
              },
              body: JSON.stringify({
                source: "website",
                packageId: packageSub,
                token: tokenRes.token.id,
                promo: this.state.promo,
              }),
            }).then((body) => {
              that.setState({
                isPurchasePending: false,
              });
              let subscriptionId = null;
              if (body.content) {
                subscriptionId = body.content.stripeSubscriptionId;
              }
              const isYearly = this.props.subscription === "yearly";
              const commerceData = {
                transaction_id: subscriptionId,
                currency: "USD",
                revenue: isYearly ? 149.99 : 14.99,
                shipping: 0,
                tax: 0,
              };
              const metadata = [
                {
                  $content_schema: "COMMERCE_PRODUCT",
                  $og_title: `Whitelabel Subscription -- ${studioName}`,
                  $og_description: `Whitelabel Web Subscriber -- ${studioName}`,
                  $canonical_identifier: `whitelabel/subscriber/${studioName}`,
                  $price: isYearly ? 149.99 : 14.99,
                  $quantity: 1,
                  $product_name: "Subscription",
                  $keywords: ["subscription"],
                  $condition: "NEW",
                },
              ];
              // Track yearly/monthly subscriber
              branch.track(isYearly ? "yearly-trial" : "monthly-trial", {
                price: isYearly ? 149.99 : 14.99,
                stripeId: subscriptionId,
              });
              // Track purchase event;
              branch.trackCommerceEvent(
                "purchase",
                commerceData,
                metadata,
                console.log
              );

              /// TODO add back in w/ whitelabel
              // Google Tag to track yearly vs monthly
              // const yearlyGtagId = 'AW-809931747/AKoGCPHu3IgBEOOnmoID';
              // const monthlyGtagId = 'AW-809931747/pfWECLqn64gBEOOnmoID';
              // // Google Track Subscription
              // window.gtag('event', 'conversion', {
              //   send_to: isYearly ? yearlyGtagId : monthlyGtagId,
              //   value: isYearly ? 149.99 : 19.99,
              //   currency: 'USD',
              //   transaction_id: subscriptionId
              // });

              if (body.status === 200) {
                console.log("Success!");
                this.props.redirectToAccount();
              } else {
                if (body.content.includes("No such coupon")) {
                  this.setState({
                    error: body.content,
                    stall: false,
                  });
                }
              }
            });
          } else {
            console.log(this.props.date)
            fetch(`/api/user/daypass/purchase`, {
              method: "POST",
              headers: {
                "Content-type": "application/json",
                Authorization: this.props.authToken,
              },
              body: JSON.stringify({
                source: "website",
                token: tokenRes.token.id,
                studioId: this.props.studioId,
                passDate: this.props.date,
                paymentMethodId: paymentMethod.id/*,
                timezone*/
              }),
            }).then(function (response) {
              that.setState({
                isPurchasePending: false,
              });
              if (!response.ok) {
                throw Error(response.statusText);
              } else {
                redirectToAccount(date);
              }
              return response;
            });
          }
        }
      }
    );
  };

  render() {
    let { isPurchasePending } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.error ? (
          <div className="errormessage Center">{this.state.error}</div>
        ) : (
          ""
        )}
        <CardSection />
        <Promo setPromo={this.setPromo} authToken={this.props.authToken} />
        <div className="Center Padding">
          <button
            style={this.props.whiteLabelBackgroundStyles}
            className="Button-style Font signup-button"
            type="submit"
            disabled={isPurchasePending}
          >
            {this.state.isLoading ? "Processing... " : "Confirm Order"}
          </button>
        </div>
      </form>
    );
  }
}

export default WhitelabelCheckoutForm;
