export const STUDIOS = "/studios";
export const HOW_IT_WORKS = "/how-it-works";
export const BLOG = "https://www.blog.jetsweatfitness.com/";
export const GEAR = "https://www.blog.jetsweatfitness.com/new-products/";
export const LOG_IN = "/login";

export const HOME = "/";
export const PRIVACY_POLICY = "https://www.jetsweatfitness.com/privacypolicy";
export const TERMS_OF_SERVICE = "https://www.jetsweatfitness.com/terms";
export const FACEBOOK = "https://www.facebook.com/jetsweatfitness";
export const INSTAGRAM = "https://www.instagram.com/jetsweatfitness/";

export const APP_STORE = "https://apps.apple.com/us/app/jetsweat/id1262223853";
