import { getAllFavorites } from '../../actions';

const initialState = {
  favorites: [],
};

export const fetchFavorites = function (userData) {
  return async (dispatch) => {
    const response = await fetch('/api/favorites/myFavorites', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        Authorization: userData.authToken,
      },
    });

    const data = await response.json();

    const favoritesInfo = data.content;

    const favIds = favoritesInfo.map((favorite) => {
      console.log('favVid', favorite.video)
      return favorite.video.id
    });

    const action = getAllFavorites(favIds);
    dispatch(action);
  };
};

export default function favoritesReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_ALL_FAVORITES':
      return {
        ...state,
        favorites: action.favorites,
      };
    default:
      return state;
  }
}
