import React, { Component } from 'react';
import Link from './components/link';
import LATLogo from './assets/los-angeles-times.svg';
import BeachLogo from './assets/beach.png';
import SytleOfSportLogo from './assets/sos.png';
import WSJLogo from './assets/wsj-logo.png';
import WellAndGoodLogo from './assets/wellandgood.svg';
import UrbanDaddyLogo from './assets/urbandaddy.svg';
import RhoneLogo from './assets/rhone.svg';
import NewYorkPostLogo from './assets/NewYorkPost.svg';
import ShapeLogo from './assets/shape.svg';
import GreatestLogo from './assets/greatest.png';
import SporteluxeLogo from './assets/sporteluxe.svg';
import TrendhunterLogo from './assets/trendhunter.svg';
import ABCNewsLogo from './assets/abc-news.svg';
import MensJournalLogo from './assets/MensJournal.svg';
import './press.css'

const LAT_LINK = "https://www.latimes.com/health/la-he-summer-fitness-apps-20180623-story.html";
const WSJ_LINK = "https://www.wsj.com/articles/forget-living-room-jumping-jacks-try-supermodel-yoga-11584880390";

const WAG_LINK = "https://www.wellandgood.com/jetsweat-digital-fitness-app-launch/";
const UD_LINK = "https://www.urbandaddy.com/articles/42059/new-yorks-best-fitness-studios-are-coming-to-you";
const RHONE_LINK = "https://www.rhone.com/blogs/pursuit/a-q-a-with-jetsweat";
const NYP_LINK = "https://nypost.com/2018/11/19/work-off-your-thanksgiving-dinner-with-these-exercise-apps/";
const SHAPE_LINK = "https://www.shape.com/fitness/trends/best-cyber-monday-gym-deals";
const TRENDHUNTER_LINK = "https://www.trendhunter.com/trends/jetsweat";

const SPORTELUXE_LINK = "https://sporteluxe.com/this-app-lets-you-stream-your-fave-nyc-boutique-fitness-classes-from-anywhere-in-the-world/";
const STYLE_OF_SPORT_LINK = "https://www.styleofsport.com/31-days-of-digital-fitness/";
const GREATEST_LINK = "https://greatist.com/fitness/best-streaming-service-for-fitness";
const ABC_LINK = "https://www.abcactionnews.com/morning-blend/let-your-senses-be-the-guide";
const MJ_LINK = "https://www.mensjournal.com/health-fitness/the-10-best-apps-for-men-to-stream-a-workout-in-2019/8-jetsweat/";

export default class Press extends Component {
  render() {
    return (
      <div className="js-home-press-container">
        <div className="js-raleway js-font-40 js-font-30-mb js-font-semibold js-font-black js-home-press-header js-text-align-center">Featured in...</div>
        <div className="d-flex justify-content-between align-items-center flex-wrap js-home-press-logos">
          <Link logoSrc={LATLogo} linkHref={LAT_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={BeachLogo} linkHref={LAT_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={WSJLogo} linkHref={WSJ_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={TrendhunterLogo} linkHref={TRENDHUNTER_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={WellAndGoodLogo} linkHref={WAG_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={UrbanDaddyLogo} linkHref={UD_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={RhoneLogo} linkHref={RHONE_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={NewYorkPostLogo} linkHref={NYP_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={ShapeLogo} linkHref={SHAPE_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={GreatestLogo} linkHref={GREATEST_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={SytleOfSportLogo} linkHref={STYLE_OF_SPORT_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={SporteluxeLogo} linkHref={SPORTELUXE_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={ABCNewsLogo} linkHref={ABC_LINK} imgClassName="js-lat-logo" />
          <Link logoSrc={MensJournalLogo} linkHref={MJ_LINK} imgClassName="js-lat-logo" />
        </div>
      </div>
    )
  }
}
