/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
import { createStore, combineReducers, applyMiddleware } from "redux"
import { createLogger } from "redux-logger"
import thunkMiddleware from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

import programReducer from "./programReducer"
import progressReducer from "./progressReducer"
import profileReducer from "./profileReducer"
import dashboardReducer from "./dashboardReducer"
import favoritesReducer from "./favoritesReducer"
import liveReducer from "./liveReducer"

const reducer = combineReducers({
  programReducer,
  progressReducer,
  profileReducer,
  dashboardReducer,
  favoritesReducer,
  liveReducer,
})

const middleware = (process.env.NODE_ENV === 'production' ?  applyMiddleware(thunkMiddleware) : composeWithDevTools(applyMiddleware(thunkMiddleware, createLogger({ collapsed: true }))))

const store = createStore(reducer, middleware)

export default store
