import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlickSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="carousel-center" style={{ padding: '0 5rem' }}>
            <div
              className="carousel-center-bigtext"
              style={{
                color: 'rgb(163, 210, 195)',
                textTransform: 'uppercase',
                fontSize: '1.7rem',
              }}
            >
              Immerse yourself in authentic studio classes wherever you are
              {' '}
            </div>
            <div
              className="carousel-center-subtext"
              style={{ fontSize: '1.7rem' }}
            >
              Experience an exclusive, curated selection of only the leading
              studios in the world. We travel to all the top studios, so you
              don’t have to.
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-center" style={{ padding: '0 5rem' }}>
            <div
              className="carousel-center-bigtext"
              style={{
                color: 'rgb(163, 210, 195)',
                textTransform: 'uppercase',
                fontSize: '1.7rem',
              }}
            >
              Access sought after secrets to the best workout methodologies
              {' '}
            </div>
            <div
              className="carousel-center-subtext"
              style={{ fontSize: '1.7rem' }}
            >
              With motivation from expert trainers and new content added
              weekly, you’ll never get bored.
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-center" style={{ padding: '0 5rem' }}>
            <div
              className="carousel-center-bigtext"
              style={{
                color: 'rgb(163, 210, 195)',
                textTransform: 'uppercase',
                fontSize: '1.7rem',
              }}
            >
              Something perfect for beginners to sweat junkies
              {' '}
            </div>
            <div
              className="carousel-center-subtext"
              style={{ fontSize: '1.7rem' }}
            >
              Whether you’re after a heart-pumping workout or want to discover
              the latest in mind and body, we’ve got something for all skill
              levels and time lengths.
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SlickSlider;
