import React from "react";
import { Link } from "react-router-dom"; // Link
import "./promoBanner.css";


const PromoBanner = ({ promoCode }) => {
  return (
    <div className="promo-banner">
      <div className="promo-message">
        {`Now you can access JETSWEAT IN THE ROKU STORE. For a limited time, get a free Roku streaming TV device with annual membership purchase.`}
        <div className="promo-message">
          {`Redeem code `}
          <div className="promo-code">ROKU</div>
          {` at checkout  `}
          <Link to="/payment" className="promo-link">
            here
          </Link>
          {"."}
        </div>
      </div>
    </div>
  )
}

export default PromoBanner;