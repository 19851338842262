import React, { Component } from 'react';
import branch from 'branch-sdk';
import { Redirect } from 'react-router-dom';

import { EmailWarning, PasswordWarning } from './formHelpers';
import HomepageVideoPlayer from '../VideoPlayer/HomePageVideoPlayer';
import Footer from '../footer';

import './promoSignup.css';
import './newPromoSignup.css';

import { baseUrl, IMAGES } from '../../src'

const features = [
  {
    imageUrl: `${baseUrl}/images/KeyIcon.png`,
    text1: 'Exclusive access to',
    text2: 'Top Buitique Studios'
  },
  {
    imageUrl: `${baseUrl}/images/calendardark1.png`,
    text1: 'Personalized Programming',
    text2: 'calendar integration'
  },
  {
    imageUrl: `${baseUrl}/images/GraphIcon.png`,
    text1: 'Real-time Performance',
    text2: 'Tracking + Motivation'
  }
]

class NewPromoSignup extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      email_warning: false,
      password_warning: false,
      isSubmitting: false,
      promoImage: '',
      promoImage2: '',
      promoImage3: '',
      promoImage4: '',
      promoCode: null,
      error: ''
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true }, (prevState) => {
      const { search } = this.props.location;
      const searchParams = new URLSearchParams(search);

      const { currUser } = this.props;

      if (currUser) {
        if (currUser.subscription && currUser.subscription.active) {
          return <Redirect to="/newplatform/dashboard" />;
        }
        return this.props.history.push({
          pathname: '/payment',
          state: {
            promo: this.state.promoCode
          }
        })
      }
      return this.setState({
        promoImage: searchParams.get('promoImage'),
        promoImage2: searchParams.get('promoImage2'),
        promoImage3: searchParams.get('promoImage3'),
        promoImage4: searchParams.get('promoImage4'),
        promoCode: searchParams.get('code'),
        isLoading: false
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { currUser } = this.props;
    if (prevProps.currUser !== currUser) {
      return this.handleRedirect();
    }
  }

  handleRedirect = () => {
    const { currUser } = this.props;
    if (currUser) {
      if (currUser.subscription && currUser.subscription.active) {
        return <Redirect to="/newplatform/dashboard" />;
      }
      return this.props.history.push({
        pathname: '/payment',
        state: {
          promo: this.state.promoCode
        }
      })
    }
  }

  validateEmail = event => {
    const { value: email } = event.target;
    if (email.includes('@')) {
      branch.setIdentity(email);
      return this.setState({ email_warning: false });
    }
    return this.setState({ email_warning: true });
  }

  validatePasswordMatch = () => {
    const { password, passwordConfirm } = this.state;
    if (password === passwordConfirm) {
      return this.setState({ password_warning: false });
    }
    return this.setState({ password_warning: true });
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });

    if (name === 'email') {
      if (this.state.email_warning && value.includes('@')) {
        return this.setState({ email_warning: false });
      }
    }

    if (name === 'password' || name === 'passwordConfirm') {
      const { password, passwordConfirm } = this.state;
      if (value === password || value === passwordConfirm) {
        return this.setState({ password_warning: false });
      }
      return this.setState({ password_warning: true });
    }
    return;
  }


  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isSubmitting: true }, () => {
      if (this.state.password !== this.state.passwordConfirm) {
        return this.setState({ password_warning: true, isSubmitting: false });
      }

      if (this.state.email_warning) return this.setState({ isSubmitting: false });

      return fetch('/api/user/register', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          userInfo: {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password
          }
        })
      })
        .then((res) => res.json())
        .then((body) => {
          if (!body.status) {
            return this.setState({ error: body.content, isSubmitting: false });
          }
          branch.track('user-signup', {});
          return this.props.loginFromToken(body.content.authToken);
        })
        .catch((err) => {
          console.error(err);
          return this.setState({ error: 'Unknown error. Please try later.', isSubmitting: false });
        })
    })
  }

  disableSubmit = () => {
    const { firstName, lastName, email, password, passwordConfirm, isSubmitting } = this.state;
    return [firstName, lastName, email, password, passwordConfirm].some((value) => !value) || isSubmitting;
  };

  displayError = () => {
    return (
      <div className="Center Padding">
        <div className="error-message">{this.state.error}</div>
      </div>
    )
  }

  renderForm = () => {
    const { email, firstName, lastName, password, passwordConfirm, isSubmitting } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="Center Padding">
          <input
            id="email"
            type="text"
            name="email"
            value={email}
            onChange={this.handleChange}
            className="Input-style Font9 No-highlight-border"
            onBlur={this.validateEmail}
            placeholder="Email"
          />
          <EmailWarning warn={this.state.email_warning} />
        </div>

        <div className="Center Padding">
          <input
            id="signupfirstname"
            type="text"
            required
            name="firstName"
            value={firstName}
            onChange={this.handleChange}
            className="Input-style Font9 No-highlight-border"
            placeholder="First Name"
          />
        </div>

        <div className="Center Padding">
          <input
            id="signuplastname"
            type="text"
            required
            name="lastName"
            value={lastName}
            onChange={this.handleChange}
            className="Input-style Font9 No-highlight-border"
            placeholder="Last Name"
          />
        </div>

        <div className="Center Padding">
          <input
            id="signuppassword"
            type="password"
            required
            name="password"
            value={password}
            onChange={this.handleChange}
            onBlur={this.validatePasswordMatch}
            className="Input-style Font9 No-highlight-border"
            placeholder="Password"
          />
        </div>

        <div className="Center Padding">
          <input
            id="signuppasswordretype"
            type="password"
            required
            name="passwordConfirm"
            value={passwordConfirm}
            onChange={this.handleChange}
            onBlur={this.validatePasswordMatch}
            className="Input-style Font9 No-highlight-border"
            placeholder="Confirm Password"
          />
          <PasswordWarning warn={this.state.password_warning} />
        </div>

        <div className="Center Padding">
          <button
            disabled={this.disableSubmit() || this.state.password_warning || this.state.email_warning}
            type="submit"
            className="Button-style Font9 signup-button">
            {' '}
            {isSubmitting ? 'Loading...' : 'LET\'S WORK OUT!'}
            {' '}
          </button>
        </div>
      </form>
    )
  }

  renderImage = (image, id) => {
    return (
      !image
        ? <div className="Center">Loading...</div>
        : (
          <div className="promo-image-container">
            <img className="promo-image" id={id} src={image} alt="JETSWEAT promo" />
          </div>
        )
    )
  }

  render() {
    const { isLoading, promoImage, promoImage2, promoImage3, promoImage4, error } = this.state;

    const mappedLogos = Object.keys(IMAGES.studios).map((x, i) =>
      <div className="studio-logo" key={`studio-logo-${i}`}>
        <a href={`/studios/${x}`}>
          <img className="studio-logo-image fade-hover" alt={IMAGES.studios[x].text.title} src={IMAGES.studios[x].logo} />
        </a>
      </div>)
    const videoUrl = `${baseUrl}/videos/Compressed-JetSweat-Sizzle-Reel.mp4`
    return (
      isLoading ?
        <div className="Center">Loading..</div>
        : (
          <div className="promo-landing-page-container">
            <HomepageVideoPlayer videoUrl={videoUrl} />
            <div className="oneBlock">
              <h2>
                ACCESS THE TOP BOUTIQUE FITNESS STUDIOS <br />
                <span className="text-bold">&nbsp;ANYTIME, ANYWHERE.</span>
              </h2>
              <div className="flexContainer">
                <div className="oneBlock">
                  <p>
                    Refine your fitness routine with <br />
                    workouts that fit your lifestyle.
                </p>
                  <p>
                    From Yoga and pilates, <br />
                    to cardio and boxing,<br />
                    choose what works for you.
                </p>
                  <div className="oneBlock">
                    <a className="fade-hover promo-button text-bold text-white text-bold" href="/singup">
                      TRY IT FREE
                  </a>
                  </div>
                </div>
                <div className='header-img'>
                  <img src="https://d1db7fp1f6g9xk.cloudfront.net/images/allStudios/baristudio/baristudioabout.png" alt="jetsweat" />
                </div>
              </div>
            </div>

            <div className="header-container bg-theme">
              <h2 className="text-white text-bold">
                STREAM 100+ CLASSES FROM YOUR FAVORITE STUDIOS
              </h2>
            </div>
            <div className="oneBlock">
              <div className="all-classes-container__studio-logos">
                {mappedLogos}
              </div>
            </div>
            <div className="oneBlock">
              <div className="oneBlock">
                <a className="fade-hover promo-button text-bold text-white text-bold" href="/studios">
                  ALL STUDIOS
                </a>
              </div>
            </div>
            <div className="oneBlock">
              <div className='header-img'>
                <img src="https://d1db7fp1f6g9xk.cloudfront.net/images/allStudios/baristudio/baristudioabout.png" alt="jetsweat" />
              </div>
            </div>
            <div className="oneBlock">
              <h2 className="text-bold">TAKE CONTROL OF YOUR WORKOUT ROUTINE</h2>
              <div className="flexContainer">

                <div className="flex-wrap">
                  {features.map((elem, i) => (
                    <div key={`feat${i}`} className="flex-container flex-row">
                      <img
                        alt="key"
                        src={elem.imageUrl}
                        className="promo-icon"
                      />
                      <span>{elem.text1}<br /> {elem.text2}</span>
                    </div>
                  ))}
                </div>

                <div className='header-img'>
                  <img src="https://d1db7fp1f6g9xk.cloudfront.net/images/allStudios/baristudio/baristudioabout.png" alt="jetsweat" />
                </div>
              </div>
            </div>

            <div className="promo-landing-page-form">
              <div className="promo-signup-container">
                {promoImage && this.renderImage(promoImage, 'promoImage')}
                {error && this.displayError()}
                <div className="promo-form-container">
                  <h2 className="Main-text text-bold">SIGN UP NOW</h2>
                  <div className="form-container">
                    {this.renderForm()}
                  </div>
                </div>
              </div>
              {promoImage2 && this.renderImage(promoImage2, 'promoImage2')}
              {promoImage3 && this.renderImage(promoImage3, 'promoImage3')}
              {promoImage4 && this.renderImage(promoImage4, 'promoImage4')}
            </div>

            {/* <div className="oneBlock">
            --- circle "featured in {various logos}...</div> */}

            <div className="oneBlock">
              <h2>
                <span className="text-bold">SIGN UP FOR YOUR JETSWEAT FREE TRIAL</span><br />
                AND ACCESS EXCLUSIVE CONTENT FROM THE BEST FITNESS STUDIOS IN THE WORLD!
              </h2>
            </div>
            <div className="oneBlock">
              <a className="fade-hover promo-button text-bold text-white" href="/singup">
                SIGN UP NOW
              </a>
            </div>
            <Footer />
          </div>
        )
    )
  }
}

export default NewPromoSignup;
