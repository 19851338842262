import React, { Component } from "react";
import { Redirect, useParams } from "react-router-dom";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.logout = this.props.logout;
  }
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const studio = window.location.pathname.split("/")[2];
    return studio ? <Redirect to={`/login/${studio}`} /> : <Redirect to="/" />;
  }
}

export default Logout;
