import React, { Component } from 'react'
import Devices from './assets/devices@3x.png'
import Button from '../../components/button'
import Link from 'screens/home/components/link'
import SignupForm from 'screens/home/components/signup.form'
import HomepageVideoPlayer from 'components/VideoPlayer/HomePageVideoPlayer'
import './hero.css'

export default class Hero extends Component {
  onClickShowSignUp() {}

  render() {
    const videoUrl =
      'https://ia801502.us.archive.org/25/items/jsmarketing9.13720p/JS%20Marketing%209.13%20720p.mp4'
    return (
      <div>
        <div className="js-home-hero-container">
          {/*}<div className="js-background-9AC8B9 js-home-hero-video"></div>*/}
          <HomepageVideoPlayer videoUrl={videoUrl} />
          <div className="js-raleway js-font-white js-font-65 js-font-30-mb js-font-medium js-home-hero-text d-flex align-items-end">
            The best boutique fitness classes in the world, anytime, anywhere
          </div>
        </div>
        <div className="d-flex flex-md-row-reverse flex-column js-home-hero-row align-items-center">
          <div>
            <img className="js-home-hero-devices-img" src={Devices} />
          </div>
          <div className="d-flex flex-column align-items-md-start align-items-center">
            <div className="js-open-sans js-font-25 js-font-18-mb js-font-3D3D3D js-home-hero-cta-description">
              Whether you're at home or on-the-go —crush your work-out goals
              with hundreds of on-demand classes from the best boutique fitness
              studios in every category.
            </div>
            {/*}<Button
              className="js-home-hero-cta-button"
              color="orange"
              label="Start your free trial"
              onClick={this.onClickShowSignUp.bind(this)} />*/}
            <Link
              className="js-home-hero-cta-button"
              color="light-green"
              label="Start your free trial"
              href="/signup"
            />
          </div>
          {/*<SignupForm />*/}
        </div>
      </div>
    )
  }
}
