import React from 'react'
import { Popup, Grid } from 'semantic-ui-react'
import moment from 'moment'
import dots from '../../images/three-dots-button.png'
import deleteIcon from '../../images/delete.png'
import editIcon from '../../images/edit.png'
import { Link, withRouter } from 'react-router-dom'

const Workout = (props) => {
  const {
    wkt,
    openModal,
    whiteLabelBackgroundStyles,
    whiteLabelFontStyles,
  } = props
  const workout = wkt.liveClass ? wkt.liveClass : wkt.video
  let inlineStyle
  if (wkt.status === 'completed') {
    inlineStyle = { ...whiteLabelBackgroundStyles }
  } else if (wkt.status === 'scheduled') {
    inlineStyle = {
      border: `3px solid ${whiteLabelFontStyles.color}`,
    }
  } else {
    inlineStyle = {}
  }
  return (
    <div className="workout">
      <Link
        to={{
          pathname: wkt.liveClass
            ? `/newplatform/live/upcoming/${wkt.liveClass.studioId}`
            : `/newplatform/items/classes/${wkt.video.id}`,
          state: {
            video: wkt.liveClass ? wkt.liveClass : null,
          },
        }}
        className="workout-new-grid"
      >
        <div id="workout-time">
          {moment(wkt.when, 'YYYY-MM-DD HH-mm').format('hh:mm A')}
        </div>
        <div id="workout-status">
          <div className={`status ${wkt.status}`} style={inlineStyle} />
        </div>
        <div className="title-wrapper">{workout.title}</div>
      </Link>
      <div className="dots">
        <Popup trigger={<img src={dots} />} flowing hoverable>
          <div id="popup-bk">
            <Grid centered divided columns={3}>
              {!wkt.liveClass ? (
                <Grid.Column textAlign="center">
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal(wkt)
                    }}
                  >
                    <span>
                      <pre>
                        <img src={editIcon} /> Edit
                      </pre>
                    </span>
                  </div>
                </Grid.Column>
              ) : (
                <Grid.Column />
              )}
              <Grid.Column textAlign="center">
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    openModal(wkt, true)
                  }}
                >
                  <span>
                    <pre>
                      <img src={deleteIcon} /> Delete
                    </pre>
                  </span>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Popup>
      </div>
    </div>
  )
}

export default withRouter(Workout)
