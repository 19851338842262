import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Footer from './footer';
import ExploreStudio from './sliders/exploreStudio';
import TestimonialCarousel from './carousels/testimonialCarousel';
import ClassesCarousel from './carousels/ClassesCarousel';
import CenterCarousel from './carousels/centerCarousel';
import PromoBanner from './PromoBanner'
import './jumbotron.css';
import 'aos/dist/aos.css';
import HomepageVideoPlayer from './VideoPlayer/HomePageVideoPlayer';

class Jumbotron extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      placeholder: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    fetch('/api/email', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email
      })
    }).then((res) => {
      if (res.status >= 400) {
        return false;
      }
      this.setState({ email: '', placeholder: true });
      return true;
    });
  }

  render() {
    const videoUrl = "https://ia801502.us.archive.org/25/items/jsmarketing9.13720p/JS%20Marketing%209.13%20720p.mp4"

    return (
      <div className="ContentContainer">
        <Link to="/signup" style={{ textDecoration: 'none' }}><PromoBanner /></Link>
        <HomepageVideoPlayer videoUrl={videoUrl} />

        <div className="text-container" style={{ textAlign: 'center' }}>
          <p className="text-below-background-img">
            {' '}
            "New York's best fitness studios are coming to you." -
            <img
              alt="urban daddy logo"
              src={require('../images/urbandaddy_logo.jpg')}
              className="urbandaddyLogo"
            />
            {' '}
          </p>
        </div>

        <div className="Center">
          <CenterCarousel />
        </div>
        <div className="Background-image-2-container">
          <img
            alt="home workout"
            src="https://jetsweat.s3.us-east-2.amazonaws.com/photos/homepageImages/homepage-1-video-streaming-home-workout.jpg"
            className="Background-image-2"
          />
        </div>
        <div style={{ padding: '50px' }}>
          <h1 className="tools-for-you">
            We’ve got the tools for you to get the results you want
          </h1>
          <p className="tools-for-you-subtext">
            Whether its sculpting and toning, transforming weight loss,
            elongating your shape,
            {' '}
            <br />
            {' '}
            mind-body health, or targeting
            specific muscle groups, JETSWEAT will help you crush your goals.
            {' '}
          </p>
        </div>
        <div className="displayWrapper">
          <div className="display-interfaces-1" data-aos="fade-up">
            <img
              alt="jetsweat homepage"
              src="https://jetsweat.s3.us-east-2.amazonaws.com/photos/homepageImages/homepage-2-best-boutique-fitness-studios.jpg"
              className="interface-1"
            />

            <div className="interface-section-1">
              <img
                alt="key"
                src={require('../images/KeyIcon.png')}
                className="key-icon"
              />
              <h2>Find the classes perfectly suited for you</h2>
              <p className="interface-1-subtext">
                Follow the fresh daily workout or filter workouts by studio,
                class type, body focus, difficulty level, time, equipment and
                more.
              </p>
            </div>
          </div>
          <div
            className="display-interfaces-2"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img
              alt="jetsweat calendar"
              src={require("../images/Personalized_workout_programs.png")}
              className="interface-1"
            />
            <div className="interface-section-1">
              <img
                alt="graph"
                src={require('../images/GraphIcon.png')}
                className="key-icon"
              />
              <h2> Start a personalized program</h2>
              <p className="interface-2-subtext">
                Schedule a program recommended to your health and wellness
                goals, or tailor a routine based on what you know you want.
              </p>
            </div>
          </div>
          <br />
          <div
            className="display-interfaces-1"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <img
              alt="interface"
              src={require("../images/track_fitness_progress_and_calories_burned.png")}
              className="interface-1"
            />
            <div className="interface-section-1">
              <img
                alt="filter icon"
                src={require('../images/FilterIcon.png')}
                className="key-icon"
              />
              <h2>Track real-time and historical performance</h2>
              <p className="interface-1-subtext">
                Receive custom calorie count and keep track of completed (or
                skipped) workouts and monthly stats to stay motivated and
                accountable.
              </p>
            </div>
          </div>
        </div>
        <ExploreStudio />
        <div classes-img-link>
          <div className="classes-container">
            <p className="classes-img-text">
              {' '}
              Endless variety in 12 class categories designed to adapt to you
            </p>

            <div className="img-container">
              <a href="" className=" image-1-1">
                <p className="class-icons-subtext">PILATES</p>
              </a>
              <a href="" className=" image-1-2">
                <p className="class-icons-subtext">SCULPT</p>
              </a>
              <a href="" className=" image-1-3">
                <p className="class-icons-subtext">BARRE</p>
              </a>
              <a href="" className=" image-1-4">
                <p className="class-icons-subtext">STRETCHING</p>
              </a>

              <a href="" className=" image-2-1">
                <p className="class-icons-subtext">CARDIO</p>
              </a>
              <a href="" className=" image-2-2">
                <p className="class-icons-subtext">MOBILITY</p>
              </a>
              <a href="" className="image-2-3">
                <p className="class-icons-subtext">MARTIAL ARTS</p>
              </a>
              <a href="" className=" image-2-4">
                <p className="class-icons-subtext">MEDITATION</p>
              </a>
              <a href="" className=" image-3-1">
                <p className="class-icons-subtext">YOGA</p>
              </a>
              <a href="" className="image-3-2">
                <p className="class-icons-subtext">HIIT</p>
              </a>
              <a href="" className=" image-3-3">
                <p className="class-icons-subtext">STRENGTH</p>
              </a>
              <a href="" className="image-3-4">
                <p className="class-icons-subtext">DANCE</p>
              </a>
            </div>
          </div>
        </div>
        <div className="carousel">
          <ClassesCarousel />
        </div>
        <div className="Center">
          <p className="membersquotesfeatured">Featured By:</p>
          <div className="publicity pub-r1 pure-g">
            <div className="pure-u-6-24 pure-u-sm-6-24 pub">
              <a
                href="http://www.latimes.com/health/la-he-summer-fitness-apps-20180623-story.html"
                target="_blank"
              >
                <img
                  alt="LA times logo"
                  src={require('../images/press/Los-angeles-times.svg')}
                  className="pubphoto"
                />
              </a>
            </div>
            <div className="pure-u-6-24 pure-u-sm-6-24 pub">
              <a
                href="https://www.wsj.com/articles/forget-living-room-jumping-jacks-try-supermodel-yoga-11584880390"
                target="_blank"
              >
                <img
                  alt="Wall Street Journal logo"
                  src={require('../images/press/WallStJournal.png')}
                  className="pubphotoWallst"
                />
              </a>
            </div>
            <div className="pure-u-6-24 pure-u-sm-6-24 pub">
              <a
                href="https://video.foxnews.com/v/6144995839001#sp=show-clips"
                target="_blank"
              >
                <img
                  alt="fox news logo"
                  src={require('../images/press/FoxNews.png')}
                  className="pubphotoFoxnews"
                />
              </a>
            </div>
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://www.wellandgood.com/good-sweat/jetsweat-digital-fitness-app-launch/"
                target="_blank"
              >
                <img
                  alt="well and good logo"
                  src={require('../images/press/wellandgood.svg')}
                  className="pubphotoWellGood"
                />
              </a>
            </div>
          </div>
          <div className="publicity pure-g">
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://www.urbandaddy.com/articles/42059/new-yorks-best-fitness-studios-are-coming-to-you"
                target="_blank"
              >
                <img
                  alt="urban daddy logo"
                  src={require('../images/press/urbandaddy.svg')}
                  className="pubphoto"
                />
              </a>
            </div>
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://www.womenshealthmag.com/fitness/a31197353/fit-tech-awards-2020/"
                target="_blank"
              >
                <img
                  alt="womens health logo"
                  src={require('../images/press/WomensHealth.png')}
                  className="pubphoto"
                />
              </a>
            </div>
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://nypost.com/2018/11/19/work-off-your-thanksgiving-dinner-with-these-exercise-apps/"
                target="_blank"
              >
                <img
                  alt="NY Post logo"
                  src={require('../images/press/NewYorkPost.svg')}
                  className="pubphotoNYPost"
                />
              </a>
            </div>
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://www.shape.com/fitness/trends/best-cyber-monday-gym-deals"
                target="_blank"
              >
                <img
                  alt="SHAPE logo"
                  src={require('../images/press/SHAPE.svg')}
                  className="pubphotoShape"
                />
              </a>
            </div>
          </div>
          <div className="publicity pure-g">
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://greatist.com/fitness/best-streaming-service-for-fitness"
                target="_blank"
              >
                <img
                  alt="greatist logo"
                  src={require('../images/press/greatist-logo.svg')}
                  className="pubphoto"
                />
              </a>
            </div>
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://sporteluxe.com/this-app-lets-you-stream-your-fave-nyc-boutique-fitness-classes-from-anywhere-in-the-world/"
                target="_blank"
              >
                <img
                  alt="sporteluxe"
                  src={require('../images/press/sporteluxe.svg')}
                  className="pubphotoSporteLuxe"
                />
              </a>
            </div>
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://www.abcactionnews.com/morning-blend/let-your-senses-be-the-guide"
                target="_blank"
              >
                <img
                  alt="abc news logo"
                  src={require('../images/press/abc-news.svg')}
                  className="pubphotoABCNews"
                />
              </a>
            </div>
            <div className="pure-u-1-5 pure-u-sm-1-5 pub">
              <a
                href="https://www.mensjournal.com/health-fitness/the-10-best-apps-for-men-to-stream-a-workout-in-2019/8-jetsweat/"
                target="_blank"
              >
                <img
                  alt="mens journal"
                  src={require('../images/press/MensJournal.svg')}
                  className="pubphotoMensJournal"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="testimonialContainer">
          <TestimonialCarousel />
        </div>

        <Footer />
      </div>
    );
  }
}

export default Jumbotron;
