import React, { Component } from 'react'
import branch from 'branch-sdk'
import { Link } from 'react-router-dom'
import './signup.css'
import Footer from '../footer.js'

class Login extends Component {
  constructor() {
    super()

    this.state = {
      email: '',
      password: '',
      warning: '',
      error: '',
    }
  }

  handleChange = (event) => {
    const fieldName = event.target.name
    this.setState({ [fieldName]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    fetch('/api/user/login', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    })
      .then((res) => {
        if (res.status >= 400) {
          console.log("We're in the error")
          return res.json()
        }
        return res.json()
      })
      .then((body) => {
        branch.setIdentity(this.state.email)
        if (body == null) {
          return null
        }
        if (body.status) {
          const currUser = body.content
          this.props.setCurrUser(currUser)
          const pushTo =
            currUser.subscription && currUser.subscription.active
              ? '/newplatform/dashboard'
              : '/payment'
          this.props.history.push(pushTo)
          if (currUser.subscription && currUser.subscription.active) {
            // window.location.reload()
          }
        } else {
          console.log("We're in the error")
          this.setState({ error: body.content })
        }
      })
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="Background-color-nav" />

          <h1 className="Main-text Padding"> Log In </h1>
          {this.state.error ? (
            <div className="Center Padding">
              <div className="errormessage">{this.state.error}</div>
            </div>
          ) : (
            ''
          )}
          <div className="Center Padding">
            <input
              id="signupemail"
              autoFocus
              type="text"
              name="email"
              onChange={this.handleChange}
              className="Input-style Font No-highlight-border"
              placeholder="Email"
            />
          </div>

          <div className="Center Padding">
            <input
              id="signuppassword"
              type="password"
              name="password"
              onChange={this.handleChange}
              className="Input-style Font No-highlight-border"
              placeholder="Password"
            />
          </div>

          <div className="Center Padding">
            <button type="submit" className="Button-style Font signup-button">
              {' '}
              LOG IN{' '}
            </button>
            <br />
            <div style={{ padding: '10px' }}>
              <span style={{ paddingRight: '5px' }}>
                Don't have an account?
              </span>
              <Link
                to="/signup"
                style={{ color: '#9ac8b9', textDecoration: 'none' }}
              >
                Sign up Here!
              </Link>
            </div>
            <div style={{ padding: '10px' }}>
              <p className="message">
                <span style={{ paddingRight: '5px' }}>
                  Forgot your password?
                </span>
                <Link
                  to="/forgot-password"
                  style={{ color: '#9ac8b9', textDecoration: 'none' }}
                >
                  Reset here!
                </Link>
              </p>
            </div>
          </div>
        </form>
        <div className="footer-login-original">
          <Footer />
        </div>
      </div>
    )
  }
}

export default Login
