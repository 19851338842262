import React, { Component } from 'react';
import './link.css';

export default class Link extends Component {
  static defaultProps = {
    imgClassName: ""
  }

  render() {
    let {
      logoSrc,
      imgClassName,
      linkHref
    } = this.props;

    return (
      <a className="js-home-press-link" href={linkHref} target="_blank" rel="noopener noreferrer">
        <img className={imgClassName} src={logoSrc} />
      </a>
    )
  }
}
