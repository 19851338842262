import React, { Component } from 'react';
import './person.css';
export default class Person extends Component {
  render() {
    let {
      imgSrc,
      quote,
      name,
      igHandle,
      style
    } = this.props;
    return (
      <div style={style} className="js-home-people-person-container d-flex flex-md-row flex-column">
        <div className="d-flex justify-content-md-start justify-content-center">
          <div><img src={imgSrc} alt={name} className="js-home-people-person-img" draggable="false" /></div>
        </div>
        <div className="js-home-people-quote-container js-text-align-center">
          <div className="js-open-sans js-font-20 js-font-18-mb js-font-black js-home-people-quote">"{quote}"</div>
          <div>
            <div className="js-open-sans js-font-24 js-font-24-mb js-font-black js-font-semibold">{name}</div>
            <div className="js-open-sans js-font-20 js-font-20-mb js-font-black js-font-semibold js-font-italic">@{igHandle}</div>
          </div>
        </div>
      </div>
    )
  }
}
