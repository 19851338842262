import React, { Component } from 'react'
import branch from 'branch-sdk'
import { Link, Redirect } from 'react-router-dom'
import Footer from '../footer'
import { EmailWarning, PasswordWarning } from '../account/formHelpers'
import '../account/signup.css'

class WhiteLabelSignup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      password_confirm: '',
      email_warning: false,
      password_warning: false,
      isSubmitting: false,
      error: '',
    }
  }

  handlePasswordChange = (event) => {
    this.handleChange(event)
    if (event.target.value === this.state.password) {
      this.setState({ password_warning: false })
    } else {
      this.setState({ password_warning: true })
    }
  }

  handleEmailChange = (event) => {
    this.handleChange(event)
    if (this.state.email_warning && event.target.value.includes('@')) {
      this.setState({ email_warning: false })
    }
  }

  handleChange = (event) => {
    const fieldName = event.target.name
    this.setState({ [fieldName]: event.target.value })
  }

  validateEmail = (event) => {
    const email = event.target.value
    if (!email.includes('@')) {
      this.setState({ email_warning: true })
    } else {
      this.setState({ email_warning: false })
    }

    // Track user on branch by email id
    branch.setIdentity(email)
  }

  passwordMatch = (event) => {
    if (event.target.value !== this.state.password) {
      this.setState({ password_warning: true })
    } else {
      this.setState({ password_warning: false })
    }
  }

  registerUser = (event) => {
    event.preventDefault()
    this.setState({ isSubmitting: true }, () => {
      if (this.state.password !== this.state.password_confirm) {
        return this.setState({ password_warning: true, isSubmitting: false })
      }
      if (this.state.email_warning) {
        return this.setState({ isSubmitting: false })
      }
      return fetch(`/api/user/register/${this.props.studio.shortName}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          userInfo: {
            email: this.state.email,
            password: this.state.password,
            firstName: this.state.firstname,
            lastName: this.state.lastname,
          },
        }),
      })
        .then((res) => {
          console.log(res)
          return res.json()
        })
        .then((body) => {
          if (!body.status) {
            return this.setState({ error: body.content, isSubmitting: false })
          }
          branch.track('user-signup', {})
          return this.props.loginFromToken(body.content.authToken)
        })
        .catch((err) => {
          console.error(err)
          return this.setState({
            error: 'Unknown error. Please try later.',
            isSubmitting: false,
          })
        })
    })
  }

  getLoginLink(daypass, studio) {
    let { whiteLabelFontStyles, whiteLabelBackgroundStyles } = this.props
    if (this.props.whiteLabelDomain.name) {
      return (
        <Link
          className="Font9 Login-now"
          to={daypass ? `/login/daypass` : `/login`}
        >
          Login here!
        </Link>
      )
    }

    return (
      <Link
        className="Font9 Login-now"
        to={daypass ? `/login/daypass/${studio.shortName}` : `/login`}
        style={whiteLabelFontStyles}
      >
        Login here!
      </Link>
    )
  }

  render() {
    const { currUser, studio, daypass, whiteLabelDomain } = this.props
    const { isSubmitting } = this.state
    const { whiteLabelFontStyles, whiteLabelBackgroundStyles } = this.props

    if (currUser) {
      if (currUser.subscription && currUser.subscription.active) {
        return <Redirect to="/newplatform/dashboard" />
      } else if (daypass) {
        if (whiteLabelDomain) return <Redirect to="/daypass" />
        return <Redirect to={`/daypass/${studio.shortName}`} />
      } else {
        if (whiteLabelDomain) return <Redirect to="/checkout" />
        return <Redirect to={`/checkout/${studio.shortName}`} />
      }
    }

    const errDiv = this.state.error ? (
      <div className="Center Padding">
        <div className="errormessage">{this.state.error}</div>
      </div>
    ) : (
      ''
    )

    return (
      <div>
        <div className="Background-color-nav" />
        <div className="Center">
          <h1 style={whiteLabelFontStyles} className="Main-text">
            {' '}
            Sign Up{' '}
          </h1>
          <h1 style={whiteLabelFontStyles} className="Main-text">
            {' '}
            Start Your Free Trial{' '}
          </h1>
          {errDiv}
        </div>
        <form onSubmit={this.registerUser}>
          <div className="Center Padding">
            <input
              autoFocus
              id="signupemail"
              type="text"
              name="email"
              onChange={this.handleEmailChange}
              onBlur={this.validateEmail}
              className="Input-style Font9 No-highlight-border"
              placeholder="Email"
            />
            <EmailWarning warn={this.state.email_warning} />
          </div>
          <div className="Center Padding">
            <input
              id="signupfirstname"
              type="text"
              required
              name="firstname"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="First Name"
            />
          </div>

          <div className="Center Padding">
            <input
              id="signuplastname"
              type="text"
              required
              name="lastname"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Last Name"
            />
          </div>
          <div className="Center Padding">
            <input
              id="signuppassword"
              type="password"
              required
              name="password"
              onChange={this.handleChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Password"
            />
          </div>

          <div className="Center Padding">
            <input
              id="signuppasswordretype"
              type="password"
              required
              name="password_confirm"
              onFocus={this.passwordMatch}
              onChange={this.handlePasswordChange}
              className="Input-style Font9 No-highlight-border"
              placeholder="Confirm Password"
            />
            <PasswordWarning warn={this.state.password_warning} />
          </div>

          <div className="Center Padding">
            <button
              disabled={
                isSubmitting ||
                this.state.password_warning ||
                this.state.email_warning
              }
              type="submit"
              className="Button-style Font9 signup-button"
              style={{ ...whiteLabelBackgroundStyles }}
            >
              {' '}
              {isSubmitting ? 'Loading...' : 'SIGN UP'}{' '}
            </button>
          </div>
        </form>
        <p className="Terms-font Center Padding">
          By creating your account, you agree to our
          <Link
            style={whiteLabelFontStyles}
            className="Terms-font Terms-link"
            to="terms"
          >
            {' '}
            Terms of Service and EULA{' '}
          </Link>
          and
          <Link
            style={whiteLabelFontStyles}
            to="privacypolicy"
            className="Terms-font Terms-link"
          >
            {' '}
            Privacy Policy.
          </Link>
        </p>
        <p className="Center Padding Font">
          Already have an account?
          {this.getLoginLink(daypass, studio)}
        </p>
        <div className="footer-signup">
          <Footer studio={this.props.studio} />
        </div>
      </div>
    )
  }
}

export default WhiteLabelSignup
