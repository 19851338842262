import React, { Component } from 'react';
import Person from './components/person'
import Dots from 'screens/home/components/dots';
import Swiper from 'screens/home/components/swiper';
import DaleBorchiver from './assets/dale-borchiver@3x.png';
import AlexCurtis from './assets/alex-curtis@3x.png';
import AnthonyCrouchelli from './assets/anthony-crouchelli@3x.png';
import KiraWest from './assets/kira-west@3x.png';
import DianaMatuszak from './assets/diana-matuszak@3x.png';
import './people.css';

const OFFSET_FACTOR_DK = 88.6458332667;
const OFFSET_FACTOR_MB = 94.20138;

const VIEWPORT_WIDTH_DK = 1366;
const VIEWPORT_WIDTH_MB = 414;

const QUOTES = [
  {
    imgSrc: DaleBorchiver,
    quote: "JETSWEAT is such an incredible platform and has seriously upped my at-home fitness game. I find that the app to be extremely high-quality and the classes are amazing (really feels like you're bringing the studio home!). I love the variety of studios offered as well, so you can always find the kind of workout you're looking for.",
    name: "Dale Borchvier",
    igHandle: "sweatsandthecity"
  },
  {
    imgSrc: AlexCurtis,
    quote: "I love JETSWEAT because it has something for any workout mood I may be in. Sometimes I want to punch it out with boxing or calm it down with a yoga flow. They truly have something for everyone. I also love the calendar feature because I’m much more likely to do something if I’ve made the plan to do it!",
    name: "Alex Curtis",
    igHandle: "alexcurtiswellness"
  },
  {
    imgSrc: AnthonyCrouchelli,
    quote: 'Jetsweat has been an incredible addition to my everyday "on the go" routine! Whether I am sprinting from one client, to the next, or in the park in between meetings, Jetsweat is always holding me accountable for my workouts! Yoga, Boxing, HIIT, and more. Jetsweat has allowed me to transform my body and mind, one rep at a time!',
    name: "Anthony Crouchelli",
    igHandle: "anthonycrouchelli"
  },
  {
    imgSrc: KiraWest,
    quote: "I've been loving Jetsweat Fitness because I've got boutique fitness studios right at home. I love the variety of class options and that the app easily connects to my TV.",
    name: "Kira West",
    igHandle: "bykwest"
  },
  {
    imgSrc: DianaMatuszak,
    quote: "It's like all of my favorite boutique studios in one place, at the convenience of my phone! I travel a lot, so it's perfect to squeeze in a good workout (of whatever type I'm feeling) even if no gyms are available!",
    name: "Diana Matuszak",
    igHandle: "to.kale.and.back"
  }
]

export default class People extends Component {
  render(){
    return (
      <div className="js-home-people-container js-background-9AC8B9-50 d-flex flex-column align-items-center">
        <div className="js-raleway js-font-40 js-font-30-mb js-font-semibold js-font-black js-home-people-title">People everywhere are pumped about JETSWEAT</div>
        <Swiper
          dotsClassName="js-home-people-dots"
          containerClassName="js-people-quotes"
          viewport_width_dk={VIEWPORT_WIDTH_DK}
          viewport_width_mb={VIEWPORT_WIDTH_MB}
          offset_factor_dk={OFFSET_FACTOR_DK}
          offset_factor_mb={OFFSET_FACTOR_MB}
          list={QUOTES}>
          {
            QUOTES.map((item, index)=> {
              let {
                imgSrc,
                quote,
                name,
                igHandle
              } = item;
              return (
                <Person
                  key={index}
                  imgSrc={imgSrc}
                  quote={quote}
                  name={name}
                  igHandle={igHandle}
                />
              )
            })
          }
        </Swiper>
      </div>
    )
  }
}
