import React, { Component } from 'react';
import Modal from 'react-modal';
import './promo.css';

class Promo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      promo: '',
      goodResponse: false,
      response: '',
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.verifyPromo = this.verifyPromo.bind(this);
  }

  handleOpenModal(e) {
    e.preventDefault();
    this.setState({ open: true });
  }

  handleCloseModal() {
    this.setState({ open: false });
  }

  handleChange(event) {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  }

  verifyPromo(event) {
    fetch('/api/basic/verifyPromo', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: this.props.authToken,
      },
      body: JSON.stringify({
        promo: this.state.promo,
      }),
    })
      .then((res) => res.json())
      .then((body) => {
        if (body.status == true) {
          this.props.setPromo(this.state.promo);
          this.handleCloseModal();
          this.setState({ goodResponse: true, response: body.content });
        } else {
          this.setState({ response: body.content });
        }
      });
  }

  render() {
    return (
      <div>
        {this.state.goodResponse ? (
          <p className="Center Numbers">{this.state.response}</p>
        ) : (
          <p className="Center Font prompt">
            <a href="#" onClick={this.handleOpenModal}>
              Have a promo code? Enter it here!
            </a>
          </p>
        )}

        <Modal isOpen={this.state.open} className="Modal No-highlight-border">
          <div className="parent Padding">
            <label className="title">Promo Code</label>
            <input
              className="IO No-highlight-border field"
              type="text"
              name="promo"
              placeholder="Promo Code"
              onChange={this.handleChange}
            />
            <button
              className="Button-style No-highlight-border Font verify"
              type="text"
              onClick={this.verifyPromo}
            >
              Verify
            </button>
            <button
              className="Button-style No-highlight-border Font cancel"
              onClick={this.handleCloseModal}
            >
              Cancel
            </button>
            <p className="IO response">{this.state.response}</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Promo;
