import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlickSlider = (props) => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="carousel-pilates-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                PILATES
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-sculpt-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                SCULPT
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-barre-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                BARRE
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-stretching-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                STRETCHING
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-cardio-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                CARDIO
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-mobility-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                MOBILITY
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-martial_arts-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                MARTIAL ARTS
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-meditation-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                MEDITATION
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-yoga-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                YOGA
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-hit-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                HIIT
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-strength-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                STRENGTH
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="carousel-dance-background">
            <div className="testimonial-classes">
              <div className="carousel-classes">
                DANCE
              </div>
            </div>
          </div>
        </div>

      </Slider>
    </div>
  );
};

export default SlickSlider;
