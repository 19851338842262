import React, { Component } from 'react';
import './button.css';

export default class Button extends Component {
  static defaultProps = {
    color: "orange",
    className: ""
  }
  getColorClassNames(color) {
    if (color === "orange") return `js-background-EE8373 js-font-white js-font-semibold`
    if (color === "green") return `js-background-1F3830 js-font-white js-font-semibold`
    return "js-background-white js-font-1F3830 js-font-semibold"
  }
  getClassName() {
    let { color, className } = this.props;
    return `${className} ${this.getColorClassNames(color)}`
  }

  render() {
    let { label, onClick } = this.props;
    return (
      <button
        className={`js-font-20 js-font-14-mb js-avenir-next d-flex align-items-center justify-content-center js-home-button ${this.getClassName()}`}
        onClick={onClick.bind(this)}>
        {label}
      </button>
    )
  }
}
