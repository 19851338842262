import React from 'react';

export default function RenderEquipment({ equipment, icons }) {
  const mapEquipment = equipment.map(tool => (
    <div key={`tool-${tool}`}>
      <img alt={tool} src={icons[tool]} className="equipmenticons" />
    </div>
  ));

  return equipment.length === 0
    ? <>no equipment</>
    : (
      <>
        <h2>Equipment</h2>
        <h2> </h2>
        {mapEquipment}
      </>
    )
}
